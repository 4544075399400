import { faFileExcel, faPencilAlt, faPlus, faPlusCircle, faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MembersDownload from 'modules/admin/components/MembersDownload'
import { Button, InLineForm, Input, Loading, NotificationMessage, PopupMenu, useFormManagement } from 'modules/common/components'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import t from 'modules/common/styles/sho-table.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { getDisplayDateTime } from 'modules/common/utils/dateFormats'
import { useAddMemberToUserGroup, useRemoveUserFromUserGroup, useUpdateUserInfo, useUserGroups } from 'api'
import React, { useMemo, useState } from 'react'
import s from './userGroupMembers.module.scss'
import AddMemberForm from '../AddMemberForm'
import BasicInfoEntry from 'modules/user/container/BasicUserInfoEntry'
const ss = styleSynthesizer(s, 'UserGroupMembers')

export default function UserGroupsMembers({ userGroupIds }) {
  const { isLoading, userGroups } = useUserGroups(userGroupIds)
  const [ showAddMember, setShowAddMember ] = useState(false)
  const [ showEditForm, setShowEditForm ] = useState(false)

  const members = useMemo(() => {
    const flatMembers = []
    userGroups?.forEach(userGroup => userGroup.memberRoles?.forEach(memberRoles => flatMembers.push({
      ...memberRoles.user,
      userGroupId: userGroup._id,
      userGroupName: userGroup.name
    })))

    return flatMembers
  }, [ userGroups ])

  if (isLoading || !userGroups) return <Loading />

  return (
    <React.Fragment>
      <div className={ss('tools')}>
        <PopupMenu icon={faTools} iconLabel='Members'>
          <li data-type='title'>Members Management</li>
          <li data-disabled={userGroups?.length !== 1} onClick={() => setShowAddMember(true)}>
            <FontAwesomeIcon icon={faPlusCircle} /><span>Add</span>
          </li>

          {/* //TODO: Hacked this, need to create my own downloader to prevent this */}
          {members.length <= 0
            ?
            <li data-disabled={true} >
              <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span>
            </li>
            :
            <MembersDownload members={members} >
              <li > <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span> </li>
            </MembersDownload>
          }
        </PopupMenu>
      </div>

      <section className={ss('addMemberContainer')}>
        <InLineForm isEnabled={showAddMember} onClose={() => setShowAddMember(false)}>
          <AddMemberFormWrapper userGroups={userGroups} />
        </InLineForm>

      </section>

      <table className={t.table}>
        <thead>
          <tr>
            <th><UserGroupsTerm /></th>
            <th>Email</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Last Logged In</th>
            <th>Registration Date</th>
            <th className={ss('editIcon')}>
              <FontAwesomeIcon className={s.edit} icon={faPencilAlt} />
            </th>
          </tr>
        </thead>
        <tbody className={ss('tableBody')}>
          {members.map(member => <MemberRow key={`${member._id}=${member.userGroupId}`} member={member} />)}
        </tbody>
      </table>
    </React.Fragment>
  )
};

function MemberRow({ member }) {
  const removeUser = useRemoveUserFromUserGroup()

  const [ showEditForm, setShowEditForm ] = useState(false)
  return (
    <>
      {removeUser.error &&
        <tr>
          <td colSpan={6}>
            <NotificationMessage error={removeUser.error} />
          </td>
        </tr>
      }
      <tr >
        <td className={s.userGroup}><label><UserGroupsTerm />:</label>{member.userGroupName}</td>
        <td className={s.email}><label>Email:</label>{member.email}</td>
        <td className={s.first}><label>First Name:</label>{member.firstName}</td>
        <td className={s.last}><label>Last Name:</label>{member.lastName}</td>
        <td className={s.loggedIn}><label>Last Logged In:</label>{getDisplayDateTime(member.lastLoggedInTimestamp)}</td>
        <td className={s.registration}><label>Registration Date:</label>{getDisplayDateTime(member.registeredDate)}</td>
        <td className={t.editIcon}>
          <label> <FontAwesomeIcon className={t.edit} icon={faPencilAlt} /> </label>
          <PopupMenu >
            <li className={s.edit} onClick={() => setShowEditForm(true)}>
              <FontAwesomeIcon className={s.icon} icon={faPencilAlt} />
              Edit
            </li>
            <li className={s.remove} onClick={() => removeUser.mutate({ userGroupId: member.userGroupId, userId: member._id })}>
              <FontAwesomeIcon className={s.icon} icon='trash-alt' />
              Remove
            </li>
          </PopupMenu>
        </td>
      </tr>
      {showEditForm &&
        <tr>
          <td colSpan={7}>
            <InLineForm isEnabled={showEditForm} onClose={setShowEditForm}>
              <BasicInfoEntry user={member} onSuccess={() => setShowEditForm(false)} />
            </InLineForm>
          </td>
        </tr>
      }
    </>
  )
}

function AddMemberFormWrapper({ userGroups }) {
  if (userGroups.length > 1) return null
  return <AddMemberForm userGroupId={userGroups[ 0 ]._id} />
}
