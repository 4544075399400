import React, { createContext, useState } from 'react'

/**
 * @typedef {object} ConfigContext
 * @property {function(Org):void} updateOrgConfig
 * @property {boolean} showPerformedBy - identifies if the performed by for services should be displayed
 * @property {string} userGroupSingularName
 * @property {string} userGroupPluralName
 */
const ConfigContext = createContext({})

function ConfigProvider({ children }) {
  const [ orgConfig, setOrgConfig ] = useState({ userGroupSingularName: '', userGroupPluralName: '', userGroupsType: '' })
  const [ showPerformedBy, setShowPerformedBy ] = useState(true)

  function updateOrgConfig(org) {
    const config = {
      userGroupsType: org?.userGroupsType ?? '',
      userGroupSingularName: org?.userGroupSingleName ?? '',
      userGroupPluralName: org?.userGroupPluralName ?? ''
    }
    setOrgConfig(config)
    setShowPerformedBy(config.userGroupsType !== 'SINGLE')
  }

  const value = {
    userGroupSingularName: orgConfig?.userGroupSingularName,
    userGroupPluralName: orgConfig?.userGroupPluralName,
    showPerformedBy,
    updateOrgConfig
  }

  return <ConfigContext.Provider value={value}> {children} </ConfigContext.Provider>
}

/**
 * @returns { ConfigContext }
 */
function useConfigContext() {
  const context = React.useContext(ConfigContext)
  if (context === undefined) {
    throw new Error('useConfigContext must be used within a provider')
  }
  return /**@type{ConfigContext} */(context)
}

export {
  ConfigProvider,
  useConfigContext,
}
