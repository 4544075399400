import OrgDetailPageWrapper from 'modules/admin/components/OrgDetailPageWrapper'
import UserGroupsMembers from 'modules/admin/containers/UserGroupsMembers'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import s from './orgMembersPage.module.scss'
const ss = styleSynthesizer(s, 'OrgMembersPage')

export default function OrgMembersPage({ }) {
  return (
    <main className={ss('container')}>
      <OrgDetailPageWrapper title="Members" Page={UserGroupsMembers} />
    </main>
  )
}