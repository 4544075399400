/**
 * @param {UpsertTargetRuleInput} input
 */
export const upsertTargetRule = (input) => ({
  key: 'upsertTargetRule',
  variables: { input },
  query: `
    mutation UpsertTargetRule($input: UpsertTargetRuleInput!) {
      response: upsertTargetRule(input: $input) {
        targetRule {
          _id
          name
          description
          maxHours
          activitiesClusivity
          activities {
            _id
            description
            name
          }
        }
      }
    }
  `
})

/**
 * @param {RemoveTargetRuleInput} input
 */
export const removeTargetRule = (input) => ({
  key: 'removeTargetRule',
  variables: { input },
  query: `
    mutation RemoveTargetRule($input: RemoveTargetRuleInput!) {
      response: removeTargetRule(input: $input) {
        orgId, targetRuleId
      }
    }
  `
})