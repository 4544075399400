import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useListSelector, { SelectButton } from 'modules/common/hooks/useListSelector'
import styleSynthesizer from 'modules/common/styles/styles'
import { serviceWorker } from 'modules/common/workers'
import React, { useEffect, useState } from 'react'
import { Service } from 'types/query'
import { Button, Input } from '../Forms'
import Loading from '../LoadingIndicator'
import s from './serviceFilters.module.scss'
const ss = styleSynthesizer(s, 'ServiceFilters')

export default function ServiceFilters({ showFilter, services, onClose, onApply }:
  { showFilter: boolean, services: Service[], onClose: () => void, onApply: (FilterContent: ServiceFilters) => void }) {
  const [isProcessing, setIsProcessing] = useState(true)
  const [descriptionFilter, setDescriptionFilter] = useState('')
  const performedBySelector = useListSelector([], 'id', 'MULTI')
  const activitySelector = useListSelector([], 'id', 'MULTI')
  const [filterData, setFilterData] = useState({ activities: [], performedBy: [] })
  const [greaterThan, setGreaterThan] = useState()
  const [lessThan, setLessThan] = useState()

  useEffect(() => {
    serviceWorker.uniqueServiceFilters(services).then(data => {
      setIsProcessing(false)
      setFilterData(data)
    })
  }, [services])

  function handleApply() {
    onApply({
      activityText: activitySelector.getSelectedIds(),
      performedByText: performedBySelector.getSelectedIds(),
      description: descriptionFilter,
      greaterThan, 
      lessThan
    })

  }
  if(!showFilter) return null

  return (
    <div className={ss('container')}>
      {(isProcessing) && <Loading />}

      {(!isProcessing)  &&
        <>
          <button type='button' className={s.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </button>
          <div className={s.filterMenu}>
            <div className={s.filterGroup}>
              <h3 className={s.filterTitle}>Performed By</h3>
              <StringSelectList selector={performedBySelector} list={filterData.performedBy} />
            </div>
            <div className={s.filterGroup}>
              <h3 className={s.filterTitle}>Activity</h3>
              <StringSelectList selector={activitySelector} list={filterData.activities} />
            </div>
            <div className={s.filterGroup}>
              <h3 className={s.filterTitle}>Description</h3>
              <Input
                label='Filter Text'
                value={descriptionFilter}
                onChange={(e) => setDescriptionFilter(e.target.value)}
              />
            </div>
              <div className={s.filterGroup}>
              <h3 className={s.filterTitle}>Hours</h3>
              <Input
                label='Greater than or equal to'
                type='number'
                value={greaterThan}
                onChange={(e) => setGreaterThan(e.target.value)}
              />
              <Input
                label='Less than or equal to'
                type='number'
                value={lessThan}
                onChange={(e) => setLessThan(e.target.value)}
              />
            </div>

          </div>
          <div className={s.buttonContainer}>
            <Button className={s.applyButton} onClick={handleApply} >Apply Filter(s)</Button>
          </div>
        </>
      }
    </div>
  )
}

function StringSelectList({ selector, list }) {
  return list?.map(l =>
    <div key={l} className={s.selectButton} onClick={(e) => selector.onSelect(e, { id: l })}>
      <SelectButton selector={selector} item={{ id: l }} />
      <p>{l}</p>
    </div>
  )

}