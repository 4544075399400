import { navigate } from '@reach/router'
import lg from 'api/logger'

const end = (path) => ({
  url: () => path,
  navTo: () => {
    lg.info('navigation/builder.navTo', { path })
    return navigate(path)
  }
})

const org = (path='') => ({
  ...end(path),
  settings: () => org(`${path}settings/`),
  targets: () => end(`${path}targets/`),
  services: () => end(`${path}services/`),
  members: () => end(`${path}members/`),
  summary: () => end(`${path}summary/`),

  // userGroups: () => orgUserGroup(`${path}userGroups/`),
})

const orgUserGroup = (path='') => ({
  summary: () => end(`${path}summary/`),
  targets: () => end(`${path}targets/`),
  services: () => end(`${path}services/`),
  members: () => end(`${path}members/`),
})

const userGroup = (path='') => ({
  targets: () => end(`${path}targets`),
  services: () => end(`${path}services`),
  ...end(path)
})


const absolute = {
  home: () => end(`/`),
  login: () => end(`/auth/login/`),
  settings: () => end(`/userSettings`),
  org: (orgId) => org(`/org/${orgId}/`),
  userGroup: (userGroupId) => userGroup(`/userGroup/${userGroupId}/`),

  pageNotFound: () => end('/pageNotFound/'),
  error: () => end('/error/'),
}


const relative = {
  org, orgUserGroup, userGroup
}

export default {
  ...absolute, relative
}
