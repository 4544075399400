
import { CategoryScale, Chart as ChartJS, Filler, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js'
import dayjs from 'dayjs'
import styleSynthesizer from 'modules/common/styles/styles'
import React, { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import s from './serviceByDateChart.module.scss'
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip)
const ss = styleSynthesizer(s, 'ServiceByDateChart')

/**
 * 
 * @typedef {("WEEK"|"MONTH"|"YEAR")} TimeFrames
 * 
 * @param {object} p 
 * @param {ServiceTotalsByDate []} p.serviceHoursByDate 
 * @returns 
 */
export default function ServiceByDateChart({ serviceHoursByDate = [] }) {

  const { labels, data } = useMemo(() => {
    const labels = []
    const data = []

    serviceHoursByDate?.forEach(service => {
      const day = dayjs(`${service.serviceYear}-${service.serviceMonth}`, 'YYYY-M')
      labels.push(`${day.format('YYYY-MMM')} wk(${service.serviceWeek})`)
      data.push(service.hours)
    })
    return { labels: labels.reverse(), data: data.reverse() }

  }, [ serviceHoursByDate ])


  const chartData = {
    labels,
    datasets: [ {
      backgroundColor: '#81E2E3',
      borderColor: '#00B7B8',
      fill: true,
      data,
    } ]
  }


  return (
    <div className={ss('container')} >
      <h2 className={s.header}>Service By Date</h2>
      <Line data={chartData}
        options={{ plugins: { legend: { display: false } }, maintainAspectRatio: false }}
      />
    </div>
  )
}