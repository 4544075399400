/**
 * @param {UpsertServiceInput} service
 * @returns {shoQuery} 
 */
export const upsertService = (service) => ({
  key: 'upsertService',
  variables: { service },
  query: `
  mutation upsertService($service: UpsertServiceInput!) {
    response: upsertService(input: $service) {
      service {
        _id
        serviceDate
        description
        hours
        activity {
          _id
          name
          description
        }
        performedByUser {
          _id
          firstName
          lastName
          email
        }
      }
    }
  }
`
})

/**
 * @param {DeleteServiceInput} deleteServiceInput 
 */
export const deleteService = (deleteServiceInput) => ({
  key: 'deleteService',
  variables: { deleteServiceInput },
  query: `
    mutation DeleteService($deleteServiceInput: DeleteServiceInput!) {
      response: deleteService(input: $deleteServiceInput) {
        _id
        userGroupId
        performedByUserId
      }
    }
  `
})

/**
 * @param {userGroupId} userGroupId
 * @param {object} [ filters ]
 * @param {number=} filters.limit - number of service records to return
 * @param {asOfDate=} filters.asOfDate - date to query back to
 * @param {string=} filters.untilDate - date to query back to
 */
export const getUserGroupServices = (userGroupId, filters) =>  {
  const key = ['services', userGroupId]
  // @ts-ignore
  if(filters) key.push(filters)

  return {
    key, 
    variables: { userGroupId, ...filters },
    query: `
    query ServicesByUserGroupId($userGroupId: ID, $limit: Int, $asOfDate: Date, $untilDate: Date) {
      services: servicesByUserGroupId(userGroupId: $userGroupId, limit: $limit, asOfDate: $asOfDate, untilDate: $untilDate) {
        _id
        userGroupId
        serviceDate
        hours
        description
        performedByUserText
        performedByUser {
          _id
          firstName
          lastName
          email
        }
        activity {
          _id
          name
          description
        }
      }
    }
  `
  }
}

/**
 * @param {userGroupId []} userGroupIds
 * @param {object} [filters] 
 * @param {number=} filters.limit - number of service records to return
 * @param {asOfDate=} filters.asOfDate - date to query back to
 * @param {string=} filters.string - date to query back to
 */
export const getUserGroupsServices = (userGroupIds, filters) =>  {
  const key = ['userGroups', 'services', userGroupIds]
  // @ts-ignore
  if(filters) key.push(filters)

  return {
    key, 
    variables: { userGroupIds, ...filters },
    query: `
    query UserGroupServices($userGroupIds: [ ID ], $asOfDate: Date, $limit: Int, $untilDate: Date) {
      userGroups: userGroups(userGroupIds: $userGroupIds) {
        _id
        name
        services(asOfDate:$asOfDate, limit: $limit, untilDate: $untilDate) {
          _id
          serviceDate
          description
          hours
          performedByUserText
          performedByUser {
            _id
            firstName
            lastName
            email
          }
          activity {
            _id
            name
          } 
        }
      }
    }
  `
  }
}