import { useConfigContext } from 'modules/common/context/configContext'
import s from './userGroupsTerm.module.css'
import React from 'react'

export default function UserGroupsTerm({count=1, lower=false}) {
  const { userGroupSingularName, userGroupPluralName} = useConfigContext()
  const className = lower ? s.lower : s.capital

  if(count === 1) return <span className={className}>{ userGroupSingularName }</span>
  if(count > 1) return <span className={className}>{ userGroupPluralName }</span>
};