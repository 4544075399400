import { queries, shoFetch } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

/** @returns {import("react-query").UseMutationResult<UpsertSponsorResponse, queryError, UpsertSponsorInput, any>} */
export function useUpsertSponsor() {
  const queryClient = useQueryClient()

  return useMutation(
    async (sponsor) => {
      return shoFetch(queries.upsertSponsor(sponsor))
    }, {

    mutationKey: 'updateSponsors', 

    onSuccess: async ({response: {sponsor}}, {orgId, businessName, _id}) => {
      toast.success(`${businessName}, ${_id ? 'updated' : 'created'}`)
      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org._id === orgId && old.org.sponsors) {
          const index = old.org.sponsors.findIndex(s => s._id === sponsor._id)
          index === -1 ? old.org.sponsors.push(sponsor) : old.org.sponsors[index] = sponsor
        }
        return old
      })
    }
  })
}

/** @returns {import("react-query").UseMutationResult<RemoveSponsorResponse, queryError, RemoveSponsorInput, any>} */
export function useRemoveSponsor() {
  const queryClient = useQueryClient()

  return useMutation(
    async (sponsor) => {
      return shoFetch(queries.removeSponsor(sponsor))
    }, {

    mutationKey: 'removeSponsor', 

    onSuccess: async ({response: {orgId, sponsorId}} ) => {
      toast.success('Sponsor removed')
      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org._id === orgId && old.org.sponsors) {
          old.org.sponsors = old.org.sponsors.filter(s => s._id !== sponsorId)
        }
        return old
      })
    }
  })
}