import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import Requests from 'modules/user/page/Requests'
import { Router } from '@reach/router'
import 'modules/common/styles/global.module.css'
import 'modules/common/styles/scrollbar.module.css'
import App from 'modules/core/App'
import Home from 'modules/core/scenes/HomePage'
import PageNotFound from 'modules/core/scenes/NotFoundPage'
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'






const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // staleTime: 30000
    }
  }
})

ReactDOM.render(

  <QueryClientProvider client={queryClient}>
    <Router>
      <Home path="/" />
      <Requests path="request/*"/>
      <App path="/*" />
      <PageNotFound default />
    </Router>
    <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
  </QueryClientProvider>

  , document.getElementById('root'))


// if(process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }
