import { useEffect, useState } from 'react'

export default function useScreenSize() {
  const [ sizes, setSizes ] = useState(getSizes())
  function getSizes() {
    return {
      minSize:
      {
        xSmall: window.matchMedia('(min-width: 300px)').matches,
        mobile: window.matchMedia('(min-width: 414px)').matches,
        tablet: window.matchMedia('(min-width: 679px)').matches,
        desktop: window.matchMedia('(min-width: 1024px)').matches,
        desktopMedium: window.matchMedia('(min-width: 1440px)').matches,
        desktopLarge: window.matchMedia('(min-width: 1920px)').matches,
        desktopMassive: window.matchMedia('(min-width: 2200px)').matches,
      },
      maxSize: {
        xSmall: window.matchMedia('(max-width: 300px)').matches,
        mobile: window.matchMedia('(max-width: 414px)').matches,
        tablet: window.matchMedia('(max-width: 679px)').matches,
        desktop: window.matchMedia('(max-width: 1024px)').matches,
        desktopMedium: window.matchMedia('(max-width: 1440px)').matches,
        desktopLarge: window.matchMedia('(max-width: 1920px)').matches,
        desktopMassive: window.matchMedia('(max-width: 2200px)').matches,
      }
    }
  }

  useEffect(() => {
    const listener = () => setSizes(getSizes())

    window.addEventListener("resize", listener)
    return () => {
      window.removeEventListener("resize", listener)
    }
  }, [])

  return sizes
}