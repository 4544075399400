import { faFileExcel, faFilter, faPencilAlt, faPlusCircle, faTools, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TargetsDownload from 'modules/admin/components/TargetsDownload'
import { Loading, PopupMenu } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { useOrgUserGroups } from 'api'
import { usePathParameters } from 'navigation'
import React, { useEffect, useState } from 'react'
import TargetCreateForm from './TargetCreateForm'
import TargetsDeleteConfirmation from './TargetsDeleteConfirmation'
import TargetEditForm from './TargetsEditForm'
import s from './userGroupsTargetManagement.module.scss'
import TargetFilters from 'modules/common/components/TargetFilters'
const ss = styleSynthesizer(s, 'UserGroupsTargetManagement')

const TOGGLE_OFF = { button: false, form: false }
const TOGGLE_ON = { button: true, form: true }

/**
 * 
 * @param {object} p 
 * @param {TargetsWithUserGroupInfo []=} p.targets - List of targets to edit or delete
 * @param {userGroupId []} p.userGroupIds - list of userGroups to add a target to
 * @param {function(TargetFilters):void} p.onFilters - list of userGroups to add a target to
 * @returns 
 */
export default function UserGroupsTargetManagement({ userGroupIds, targets=[], onFilters }) {
  const { orgId } = usePathParameters()
  const { org, isLoading } = useOrgUserGroups(orgId)
  const togglesInitialState = () => ({
    createTarget: { button: true, form: false },
    editTargets: { button: !!targets.length, form: false },
    deleteTargets: { button: !!targets.length, form: false }
  })
  const [ toggles, setToggles ] = useState(togglesInitialState())
  const [showFilter, setShowFilter] = useState(false)

  useEffect(() => setToggles(togglesInitialState()), [ targets ])

  if (isLoading) return <Loading />

  function resetToggles() {
    setToggles(togglesInitialState())
  }
  function handleActionClick(action) {
    setToggles({
      createTarget: action === 'create' ? TOGGLE_ON : TOGGLE_OFF,
      deleteTargets: action === 'delete' ? TOGGLE_ON : TOGGLE_OFF,
      editTargets: action === 'edit' ? TOGGLE_ON : TOGGLE_OFF,
    })
  }

  return (
    <React.Fragment>
      <div className={ss('actionButtons')}>
        <PopupMenu icon={faTools} iconLabel='Targets'>
          <li data-type='title'>Target Management</li>
          <li className={ss('showCreate')} data-disabled={!toggles.createTarget.button} onClick={() => handleActionClick('create')}>
            <FontAwesomeIcon icon={faPlusCircle} /><span>Add</span>
          </li>

          <li className={ss('showEdit')} data-disabled={!toggles.editTargets.button} onClick={() => handleActionClick('edit')}>
            <FontAwesomeIcon icon={faPencilAlt} /><span>Edit</span>
          </li>

          <li className={ss('showDelete')} data-disabled={!toggles.deleteTargets.button} onClick={() => handleActionClick('delete')}>
            <FontAwesomeIcon icon={faTrashAlt} /> <span>Delete</span>
          </li>


          {/* //TODO: Hacked this, need to create my own downloader to prevent this */}
          {targets.length <= 0
            ?
            <li data-disabled={targets.length <= 0} >
              <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span>
            </li>
            :
            <TargetsDownload targets={targets} >
              <li data-disabled={targets.length <= 0} >
                <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span>
              </li>
            </TargetsDownload>

          }

          <li onClick={() => setShowFilter(true)} >
            <FontAwesomeIcon icon={faFilter} /><span>Filters</span>
          </li>

        </PopupMenu>
      </div>

      {toggles.editTargets.form && <TargetEditForm org={org} targets={targets} onClose={resetToggles} />}
      {toggles.createTarget.form && <TargetCreateForm org={org} userGroupIds={userGroupIds} onClose={resetToggles} />}
      {toggles.deleteTargets.form && <TargetsDeleteConfirmation org={org} targets={targets} onClose={resetToggles} />}
      <TargetFilters showFilter={showFilter} onApply={onFilters} onClose={() => setShowFilter(false)}/>
    </React.Fragment>
  )
}


