import { faBug } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import React from 'react'
import s from './componentError.module.css'

export default function ComponentError({ componentName }) {

  return (
    <div className={s.container}>
      <div className={s.content}>
        <FA className={s.bug} icon={faBug} />
        <div className={s.messages}>
          <p className={s.message}>Oh No..., Something went wrong with {componentName}!</p>
          <p className={s.close}>Please refresh your browser and try again, if the problem presets please contact support</p>
        </div>
        <FA className={s.bug} icon={faBug} />
      </div>
    </div>
  )
}
