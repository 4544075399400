import styleSynthesizer from 'modules/common/styles/styles'
import ServiceList from 'modules/user/container/ServiceList'
import Targets from 'modules/user/container/Targets'
import UserOpportunities from 'modules/user/container/UserOpportunities'
import React from 'react'
import styles from './dashboardPage.module.scss'
const s = styleSynthesizer(styles, 'UserDashboard')

export default function UserDashboard({ userGroupId }) {
  return (
    <div className={s('container')}>
      <Targets userGroupId={userGroupId} />
      {/* <UserOpportunities userGroupId={userGroupId} /> */}
      <ServiceList userGroupId={userGroupId} />
    </div>
  )
}
