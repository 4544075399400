import { Button, InLineForm, NotificationMessage } from 'modules/common/components'
import { useInactivateUserGroups } from 'api'
import React, { useEffect } from 'react'
import s from './inactivateUserGroupsConfirmation.module.scss'

export default function InactivateUserGroupsConfirmation({ show, org, userGroups, onClose, onSuccess }) {
  const inactivateUserGroups = useInactivateUserGroups()

  useEffect(() => { if(inactivateUserGroups.isSuccess) onSuccess() }, [ inactivateUserGroups.isSuccess ])

  function handleRemoveClick() {
    inactivateUserGroups.mutate({
      userGroupIds: userGroups.map(t => t._id),
      orgId: org._id
    })
  }

  return (
    <InLineForm isEnabled={show} onClose={onClose}>
      <div className={s.contentWrapper}>
        <NotificationMessage error={inactivateUserGroups.error} />
        <div className={s.content}>
          <p className={s.deleteUserGroups}>Are you sure you want to remove {userGroups.length} {userGroups.length > 1 ? 'userGroups' : 'target'}? </p> 
          <div className={s.actionButtons}>
            <Button className={s.cancel} onClick={onClose} isBlocking={inactivateUserGroups.isLoading}> Cancel </Button>
            <Button className={s.delete} onClick={handleRemoveClick} isBlocking={inactivateUserGroups.isLoading}> Inactivate </Button>
          </div>
        </div>
      </div>
    </InLineForm>
  )
}