import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { Loading, NotificationMessage } from 'modules/common/components'
import { Button, Input, Password, useFormManagement } from 'modules/common/components/Forms'
import { useAcceptInviteRequest, useRejectInviteRequest, useRequest } from 'api'
import { rejectInviteRequest } from 'api/queries'
import { useNavigation, usePathParameters } from 'navigation'
import React, { useEffect } from 'react'
import __ from './userInvitePage.module.css'



function UserInvitePage({ }) {
  const { requestId } = usePathParameters()
  const { isLoading, request } = useRequest(requestId)
  const nav = useNavigation()
  const acceptInvite = useAcceptInviteRequest()
  const rejectInvite = useRejectInviteRequest()
  const { data, onChange, isDirty, onSubmit } = useFormManagement({
    requestId,
    firstName: '',
    lastName: '',
    password: ''
  }, acceptInvite.mutate)

  useEffect(() => {
    if(acceptInvite.isSuccess) nav.login().navTo()
  }, [acceptInvite.isSuccess])

  useEffect(() => {
    if(rejectInvite.isSuccess) nav.home().navTo()
  }, [rejectInvite.isError, rejectInvite.isSuccess])

  if (isLoading) return <Loading />

  if (!request) return (
    <div className={__.invalidRequest}>
      <p className={__.message}>Sorry, the link provided seems to have expired <FA className={__.frown} icon={['far', 'frown']} /></p>
    </div>
  )

  return (
    <div className={__.container}>
      <p className={__.intro}>Begin making a difference at {request.requestData.org.name}</p>
      <form className={__.formContainer} onSubmit={onSubmit}>
        {/* //TODO: If it is the last user and they decline it errors */}
        <NotificationMessage error={acceptInvite.error} />
        {request.requestType === 'NEW_USER_INVITE' && <>
          <Input autoFocus
            label='First Name'
            type='text'
            name='firstName'
            autoComplete='off'
            value={data.firstName}
            onChange={onChange} />

          <Input label='Last Name'
            type='text'
            name='lastName'
            autoComplete='off'
            value={data.lastName}
            onChange={onChange} />

          <Password label='Password'
            type='password'
            name='password'
            autoComplete='off'
            value={data.password}
            onChange={onChange} />
        </>
        }
        <div className={__.actions}>
          <Button className={__.buttonSubmit} onClick={onsubmit} isBlocking={isLoading} type="submit">Join Now</Button>
        </div>
      </form>
      <p className={__.rejectMessage}>
        If you wish to decline the invite,
        <span className={__.decline} onClick={() => rejectInvite.mutate({ requestId }) }> please click here</span>
      </p>
    </div>
  );
};


export default UserInvitePage;
