import { sort } from 'fast-sort'


export function sortServicesByDate(services) {
  return sort([...services]).desc(s => s.serviceDate)
}

/** 
 * @typedef {targetType & targets} targetByType 
 *
 * @typedef targets
 * @property {Target[]} targets
 * 
 * @param {Target[]} targets
 * @returns {targetByType[]}
 */
export function groupTargetsByTargetType(targets) {
  return targets.reduce((tts, target) => {
    const index = tts.findIndex(tt => tt._id === target.type._id)

    if (index === -1) tts.push({ ...target.type, targets: [target] })
    if (index >= 0) tts[index].targets.push(target)

    return tts
  }, [])
}

export function getUsersFullName(user) {
  return `${user?.firstName || ''} ${user?.lastName || ''}`.trim()
}

export function getUsersDisplayName(user) {
  const name = getUsersFullName(user)
  return name ? name : user.email
}

export function sortActivitiesByName(activities) {
  return sort([...activities]).asc(a => a.name)
}

export function getPerformedByValue (service) {
  if (service.performedByUser) return getUsersDisplayName(service.performedByUser)
  return service.performedByUserText
}
