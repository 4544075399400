import React from 'react';
import s from './ruleSelector.module.scss';
import styleSynthesizer from 'modules/common/styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons'
import { PopupMenu } from 'modules/common/components'
const ss = styleSynthesizer(s, 'RuleSelector')

export default function RuleSelector({ targetRules }: {targetRules: string[]}) {

  /**Change this to be a simple list with a selector Probably add it in to the target fields to make it easier to maintain */
  return ( 
    <div className={ss('container')}>
        <div>
          {targetRules.map(t => {
            <p>{t}</p>
          })}
          <p>Form</p>
        </div>
    </div>
  );
};