import { queries, shoFetch } from 'api'
import { useLocation, useNavigation } from 'navigation'
import { QueryClient, useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

/** @returns {import("react-query").UseMutationResult<User, queryError, {userName: string, password: string}, any>} */
export function useLogin() {
  const { path } = useLocation()
  const nav = useNavigation()
  const queryClient = useQueryClient()

  return useMutation(
    variables => shoFetch(queries.login(variables)),

    {
      mutationKey: 'login',

      onSuccess: data => {
        queryClient.setQueryData(queries.getLoggedInUser().key, data)
        navigateToAuthenticated(nav, path, data.user)
      },
    }
  )
}

/** @returns {import("react-query").UseMutationResult<any, queryError, any, any>} */
export function useLogout() {
  const queryClient = useQueryClient()
  const nav = useNavigation()

  return useMutation(() => shoFetch(queries.logout()), {
    mutationKey: 'logout',
    onSettled: async () => {
      await nav.home().navTo()
      queryClient.cancelQueries()
      queryClient.cancelMutations()
      queryClient.clear()
    },
  })
}

export function useAuthentication() {
  const { path } = useLocation()
  const nav = useNavigation()

  const response = useLoggedInUser()

  if (response.isSuccess) navigateToAuthenticated(nav, path, response.user)
  return response
}

/** @returns {import("react-query").UseMutationResult<User, queryError, UpdateUserPasswordInput, any>} */
export function useUpdatePassword() {
  return useMutation(async updateUserPasswordInput => shoFetch(queries.updateUserPassword(updateUserPasswordInput)), {
    mutationKey: 'updatePassword',
    onSuccess: () => { toast.success('Password updated') }
  })
}

/** @returns {import("react-query").UseMutationResult<User, queryError, UpdateUserEmailInput, any>} */
export function useUpdateEmail() {
  const queryClient = useQueryClient()

  return useMutation(
    async updateUserEmailInput => {
      return shoFetch(queries.updateUserEmail(updateUserEmailInput))
    },
    {
      mutationKey: 'updateEmail',

      onSuccess: ({ response: { user } }) => { 
        toast.success('User email updated')
        updateUserQueries(queryClient, user)
       },
    }
  )
}

/** @returns {import("react-query").UseMutationResult<User, queryError, UpdateUserInfoInput, any>} */
export function useUpdateUserInfo() {
  const queryClient = useQueryClient()

  return useMutation(
    async updateUserInfoInput => {
      return shoFetch(queries.updateUserInfo(updateUserInfoInput))
    },
    {
      mutationKey: 'updateUserInfo',

      onSuccess: ({ response: { user } }) => { 
        toast.success('User information updated')
        updateUserQueries(queryClient, user) 
        queryClient.setQueriesData(['userGroups', 'memberRoles'], (old) => {
           old.userGroups.forEach(ug => {
             ug.memberRoles = [...ug.memberRoles]
             ug.memberRoles.forEach(m => {
               if(m?.user._id === user._id) {
                 m.user.firstName = user.firstName
                 m.user.lastName = user.lastName
               }
             })
           })

           old.userGroups = [ ...old.userGroups ]
           return old
        })
      },
    }
  )
}

function updateUserQueries(queryClient, user) {
  queryClient.setQueryData(queries.getLoggedInUser().key, old => {
    if (old.user._id === user._id) old.user = { ...old.user, ...user }
    return old
  })

  queryClient.setQuery
}

/*************************************************************
 * Queries
 *************************************************************/
export function useLoggedInUser() {
  const query = queries.getLoggedInUser()
  const { data, ...response } = useQuery(query.key, () => shoFetch(query), { staleTime: Infinity })
  return { user: /** @type{User} */ (data?.user), ...response }
}

export function useUserMemberships(userId) {
  const query = queries.getUserMemberships(userId)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { userMemberships: /** @type {UserGroupRole []} */ (data?.user?.userGroupRoles), ...response }
}

function navigateToAuthenticated(nav, path, user) {
  if (path !== '/' && !path.startsWith('/auth')) return

  if(user.managingOrgs && user.managingOrgs.length > 0 ) {
    nav.org(user.managingOrgs[0].org._id).navTo()
  } else if(user.userGroupRoles && user.userGroupRoles.length > 0) {
    nav.userGroup(user.userGroupRoles[0].userGroup._id).navTo()
  } else {
    nav.pageNotFound().navTo()
  }
}
