import { faHandPointLeft, faHandPointUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAdminContext } from 'modules/admin/context/adminContext'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import useScreenSize from 'modules/common/hooks/useScreenSize'
import styleSynthesizer from 'modules/common/styles/styles'
import React, { useMemo } from 'react'
import s from './orgDetailPageWrapper.module.scss'
const ss = styleSynthesizer(s, 'OrgDetailPageWrapper')

export default function OrgDetailPageWrapper({ title='', Page, ...props }) {
  const { selectedUserGroups } = useAdminContext()
  const userGroupIds = useMemo(() => selectedUserGroups.map(s => s._id), [ selectedUserGroups ])
  const sizes = useScreenSize()
  return (
    <React.Fragment>
      <h2 className={ss('header')}>{title}</h2>
      {selectedUserGroups.length <= 0
        ?
        <div className={s.selectUserGroup}>
          {sizes.maxSize.mobile
            ? <FontAwesomeIcon className={s.hand} icon={faHandPointUp} />
            : <FontAwesomeIcon className={s.hand} icon={faHandPointLeft} />
          }
          <p>Please select a <UserGroupsTerm lower={true} /> to view</p>
        </div>
        : <Page userGroupIds={userGroupIds} { ...props } /> 
        }
    </React.Fragment>
  )
}