import InLineForm from 'modules/common/components/InLineForm'
import { useConfigContext } from 'modules/common/context/configContext'
import styleSynthesizer from 'modules/common/styles/styles'
import ServiceListEditMenu from 'modules/user/components/ServiceRowEditMenu'
import { EditServiceEntry } from 'modules/user/container/ServiceEntry'
import { useDeleteService } from 'api'
import { getPerformedByValue } from 'api/selectors'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import styles from './serviceRow.module.scss'
const s = styleSynthesizer(styles, 'ServiceRow')


export default function ServiceRow({ service, userGroupId }) {
  const { serviceDate, description, activity, hours, performedByUser, performedByUserText } = service
  const { showPerformedBy } = useConfigContext()
  let month = dayjs(serviceDate).format('MM')
  let day = dayjs(serviceDate).format('DD')
  const [showEditForm, setShowEditForm] = useState(false)

  const deleteService = useDeleteService()

  function onEditSelected() {
    setShowEditForm(true)
  }

  return (
    <React.Fragment>
      <div className={s('serviceRow')} data-testid={'service-row'}>
        <div className={s('hours')}>
          <p className={s('hoursValue')}>
            {hours}
            {hours > 1 ? ' hrs' : ' hr'}
          </p>
        </div>
        <div className={s('date')}>
          <p className={s('month')} data-testid={'service-month'}>
            {month}/{day}
          </p>
        </div>
        <div className={s('content')}>
          <p className={s('activity')}>
            {activity.name} - {description}
          </p>
          {showPerformedBy && <p className={s('performedByUser')}>By: {getPerformedByValue(service)}</p> }
        </div>
        <div className={s('action')}>
          <ServiceListEditMenu
            service={service}
            onEditSelected={onEditSelected}
            onDeleteSelected={service => deleteService.mutate({ serviceId: service._id, userGroupId })}
          />
        </div>
      </div>

      <section className={s('editForm')}>
        <InLineForm isEnabled={showEditForm} onClose={setShowEditForm}>
          <EditServiceEntry service={service} userGroupId={userGroupId} onSuccess={() => setShowEditForm(false)} />
        </InLineForm>
      </section>
    </React.Fragment>
  )
}
