import { useAddMemberToUserGroup } from 'api'
import { Button, Checkbox, Input, useFormManagement } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import React, { useEffect } from 'react'
import { AddUserToUserGroupInput } from 'types/query'
import s from './addMemberForm.module.scss'
const ss = styleSynthesizer(s, 'AddMemberForm')

export default function AddMemberForm({ userGroupId, onSuccess }: { userGroupId: string, onSuccess?: (data: AddUserToUserGroupInput) => void }) {

  const addUserToUserGroup = useAddMemberToUserGroup()
  const { data, ...form } = useFormManagement({ email: '', firstName: '', lastName: '', userGroupId, sendWelcomeEmail: false }, (data) => {
    addUserToUserGroup.mutate(data)
    form.resetState()
  })

  useEffect(() => {
    if (addUserToUserGroup.isSuccess && typeof onSuccess === 'function') onSuccess(data)
  }, [addUserToUserGroup.isSuccess])

  return (
    <form className={ss('addMemberForm')} onSubmit={form.onSubmit}>
      <p className={s.errorText}>{addUserToUserGroup.error?.message}</p>
      <Input
        label='Email'
        placeholder='Email'
        type='email'
        name='email'
        autoComplete="on"
        value={data.email}
        onChange={form.onChange}
      />

      <Input
        label='First Name'
        placeholder='(optional)'
        type='text'
        name='firstName'
        value={data.firstName}
        onChange={form.onChange}
      />

      <Input
        label='Last Name'
        placeholder='(optional)'
        type='text'
        name='lastName'
        value={data.lastName}
        onChange={form.onChange}
      />

      <Checkbox
        label='Send Welcome Email'
        name='sendWelcomeEmail'
        value={data.sendWelcomeEmail}
        onChange={form.onChange} />
      <div className={ss('actions')}>
        <Button className={ss('submitButton')} type="submit" isDirty={form.isDirty} isBlocking={addUserToUserGroup.isLoading} onClick={form.onSubmit}>Add Member</Button>
      </div>
    </form>
  )
}
