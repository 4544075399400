import dayjs from 'dayjs'
import { sort } from 'fast-sort'
import styleSynthesizer from 'modules/common/styles/styles'
import { useServiceTotals } from 'modules/common/workers'
import React, { useState } from 'react'
import { Service } from 'types/query'
import { Button } from '../Forms'
import Loading from '../LoadingIndicator'
import s from './serviceTotals.module.scss'
const ss = styleSynthesizer(s, 'ServiceTotals')

type params = {
  services: Service[],
  asOfDate: string,
  untilDate: string
}
export default function ServiceTotals({ services, asOfDate, untilDate }: params) {
  const { status, serviceTotals } = useServiceTotals(services)

  if (status !== 'SUCCESS') return <Loading />
  if (!(serviceTotals?.totalHours > 0)) return null

  return (
    <div className={ss('container')}>

      <div className={s.totals}>
        <aside className={s.allTotal}>
          <p className={s.serviceTotal}><span className={s.totalNumber}>{serviceTotals.totalHours} Hour(s)</span></p>
          {(!untilDate || untilDate === '9999-12-31')
            ? <p className={s.date}>As Of: {dayjs(asOfDate).format('MM/DD/YYYY')}</p>
            : <p className={s.date}>From: {dayjs(asOfDate).format('MM/DD/YYYY')} to {dayjs(untilDate).format('MM/DD/YYYY')}</p>
          }
        </aside>
        <aside className={s.activityTotals}>
          <h3>Activity Totals</h3>
          <RecordTotals total={serviceTotals.totalHours} objectTotals={serviceTotals.activities} />
        </aside>

        <aside className={s.memberTotals}>
          <h3>Member Totals</h3>
          <RecordTotals total={serviceTotals.totalHours} objectTotals={serviceTotals.performedBy} />
        </aside>
      </div>
    </div>
  )
}

function RecordTotals({ objectTotals, total }) {
  const [showCount, setShowCount] = useState(3)

  const flatObject = Object.keys(objectTotals).map(k => ({ name: k, total: objectTotals[k] }))
  const totals = sort(flatObject).desc(o => o.total)
  return (
    <>
      {totals.map((totalObj, index) => {
        if (index === showCount) return <Button className={s.showMoreButton} onClick={() => setShowCount(showCount + 3)}>Show More</Button>
        if (index > showCount) return
        return (
          <div key={totalObj.name} className={s.record}>
            <div className={s.recordData}><span>{totalObj.name}</span><span>{ totalObj.total }</span></div>
            <div className={s.percentIndicator} style={{ width: calculatePercent(total, totalObj.total) + '%' }}></div>
          </div>
        )
      }
      )}
    </>
  )
}

function calculatePercent(total: number, amount: number) {
  if (total === 0 || amount === 0) return 1
  if (amount >= total) return 100
  return (amount / total) * 100
}