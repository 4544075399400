import { useLoggedInUser, useUpdateUserInfo } from 'api'
import { Loading, NotificationMessage } from 'modules/common/components'
import { Button, Input, useFormManagement } from 'modules/common/components/Forms'
import pDebounce from 'p-debounce'
import React, { useEffect } from 'react'
import __ from './basicUserInfoEntry.module.css'


/**
 * 
 * @param {object} p
 * @param {import('types/query').User} p.user
 * @param {function(import('types/query').User):void} [p.onSuccess]
 * @returns 
 */
export default function BasicInfoEntry({user, onSuccess}) {
  const { isLoading: isUpdating, error, mutate, isSuccess } = useUpdateUserInfo()
  const { data, onChange, isDirty, onSubmit } = useFormManagement({
    userId: user._id,
    firstName: user.firstName,
    lastName: user.lastName
  }, mutate)

  useEffect(() => { if(isSuccess && typeof onSuccess === 'function') onSuccess(data)
  }, [isSuccess])

  return (
    <form className={__.formFields} onSubmit={onSubmit}>

      <NotificationMessage error={error} />

      <Input autoFocus
        label='First Name'
        type='text'
        name='firstName'
        value={data.firstName}
        onChange={onChange} />

      <Input label='Last Name'
        type='text'
        name='lastName'
        value={data.lastName}
        onChange={onChange} />

      <div className={__.actions}>
        <Button type='submit' className={__.buttonSubmit} onClick={onSubmit} isDirty={isDirty} isBlocking={isUpdating} >Save</Button>
      </div>
    </form>
  )
}
