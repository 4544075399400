import { useUpdateService as useUpdateService } from 'api'
import { useFormManagement } from 'modules/common/components/Forms'
import React, { useEffect } from 'react'
import ServiceEntry from '../ServiceForm'

/**
 * @param {object} p
 * @param {Service} p.service
 * @param {userGroupId} p.userGroupId
 * @param {function(Service):void} p.onSuccess - function executed when the service is successfully updated
 */
export default function EditServiceEntry({ service, userGroupId, onSuccess }) {
  console.log('🚀 ~ EditServiceEntry ~ service', service)
  const updateService = useUpdateService()

  const { performedByUser, activity, ...serviceForm } = service
  const form = useFormManagement(
    {
      userGroupId,
      ...serviceForm,
      activityId: activity?._id,
      performedByUserId: performedByUser?._id,
    },
    updateService.mutate
  )

  useEffect(() => {
    if (updateService.isSuccess) onSuccess(form.data)
  }, [updateService.isSuccess])

  return (
    <ServiceEntry
      userGroupId={userGroupId}
      form={form}
      error={updateService.error}
      isBlocking={updateService.isLoading}
    />
  )
}
