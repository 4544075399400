import { useProcessPasswordResetRequest } from 'api'
import __ from 'modules/auth/styles/auth.module.css'
import { Loading, NotificationMessage } from 'modules/common/components'
import { Button, Input, Password, useFormManagement } from 'modules/common/components/Forms'
import { useNavigation, usePathParameters } from 'navigation'
import React from 'react'
import { Helmet } from 'react-helmet'

export default function ProcessPasswordResetRequest() {
  const { requestId } = usePathParameters()
  const nav = useNavigation()
  const processRequest = useProcessPasswordResetRequest()

  const { data, isDirty, ...form } = useFormManagement(
    { requestId, userName: '', newPassword: '' },
    processRequest.mutate
  )

  if (processRequest.isLoading) return <Loading />
  if (processRequest.isSuccess) nav.login().navTo()

  return (
    <React.Fragment>
      <Helmet>
        <title>Password Reset - Service Hours Online</title>
      </Helmet>

      <form className={__.formFields} onSubmit={form.onSubmit}>
        <NotificationMessage error={processRequest.error} />

        <h2 className={__.header}>Reset Password</h2>

        <Input label='Email Address' type='email' name='userName' value={data.userName} onChange={form.onChange} />

        <Password
          label='Password'
          type='password'
          name='newPassword'
          value={data.newPassword}
          onChange={form.onChange}
        />

        <div className={__.actions}>
          <Button
            className={__.button}
            onClick={form.onSubmit}
            isDirty={isDirty}
            isBlocking={processRequest.isLoading}
            type='submit'>
            Reset Password
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}
