import { useUpdateEmail, useUpdatePassword } from 'api'
import { NotificationMessage } from 'modules/common/components'
import { Button, Input, Password, useFormManagement } from 'modules/common/components/Forms'
import React, { useEffect } from 'react'
import __ from './changeEmailEntry.module.css'


/**
 * @param {object} p
 * @param {string} p.userName - userName for the password which is changing
 * @param {string} p.userId - userId of the user whos password is changing
 * @param {function():void} p.onSuccess - executed on successful update
 */
export default function EmailEntry({ userId, userName, onSuccess }) {
  const { isLoading, error, mutate, isSuccess } = useUpdateEmail()
  const { data, onChange, isDirty, onSubmit } = useFormManagement({
    userName,
    userId,
    password: '',
    newEmail: '',
    confirmedNewEmail: ''
  }, mutate)

  useEffect(() => isSuccess && onSuccess(), [isSuccess])

  return (
    <form className={__.form} onSubmit={onSubmit}>
      <NotificationMessage error={error} />

      <Password label='Current Password'
        type='password'
        name='password'
        value={data.password}
        onChange={onChange} />

      <Input label='New Email'
        type='email'
        name='newEmail'
        value={data.newEmail}
        onChange={onChange} />

      <Input label='Confirm New Email'
        type='email'
        name='confirmedNewEmail'
        value={data.confirmedNewEmail}
        onChange={onChange} />

      <div className={__.actions}>
        <Button className={__.buttonUpdate} onClick={onSubmit} isDirty={isDirty} isBlocking={isLoading} type="submit">Update</Button>
      </div>
    </form>
  )
}
