import React, { useState } from 'react'
import s from './orgMetaDataDashboard.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { userServiceHoursMetaData } from 'api'
import dayjs from 'dayjs'
import { usePathParameters } from 'navigation'
import { useAdminContext } from 'modules/admin/context/adminContext'
import { Loading, TimeFrameFilters } from 'modules/common/components'
import ServiceByDateChart from 'modules/admin/components/ServiceByDateChart'
import ServiceByActivity from 'modules/admin/components/ServiceByActivityChart'
import ServiceByUserGroups from 'modules/admin/components/ServiceByUserGroups'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
const ss = styleSynthesizer(s, 'OrgMetaDataDashboard')

export default function OrgMetaDataDashboard({ }) {
  const { orgId } = usePathParameters()
  const [ asOfDate, setAsOfDate ] = useState(dayjs().subtract(6, 'months').format('YYYY-MM-DD'))
  const [ untilDate, setUntilDate ] = useState('9999-12-31')
  const { getUserGroupIds } = useAdminContext()

  return (
    <div className={ss('container')}>
      <TimeFrameFilters onFilterChange={(asOfDate, untilDate) => {
        setAsOfDate(asOfDate)
        setUntilDate(untilDate)
      }} />
      <ServiceCharts asOfDate={asOfDate} untilDate={untilDate} orgId={orgId} userGroupIds={getUserGroupIds()} />
    </div>
  )
}

function ServiceCharts({ asOfDate, orgId, userGroupIds, untilDate }) {
  const { isLoading, meta } = userServiceHoursMetaData({ orgId, userGroupIds, asOfDate, untilDate })

  if (isLoading) return <Loading />
  if (meta.totalServiceHoursLogged <= 0) return <p className={s.notFound}>No services for the given time frame</p>

  return (
    <React.Fragment>
      <p className={s.totals}>
        <span className={s.totalHours}>{meta.totalServiceHoursLogged?.toLocaleString() || 0}</span> hours
        completed by <span className={s.totalUserGroups}>{meta.totalNumberOfUserGroups?.toLocaleString() || 0}</span> <UserGroupsTerm count={meta.totalNumberOfUserGroups} />!
      </p>

      <ServiceByDateChart serviceHoursByDate={meta.serviceHoursByDate} />
      <ServiceByActivity serviceTotalsByActivity={meta.serviceHoursByActivities} />
      {userGroupIds.length !== 1 &&
        <ServiceByUserGroups serviceTotalsByUserGroups={meta.serviceHoursByUserGroups} />
      }
    </React.Fragment>

  )
}