import { useState, useEffect } from "react";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {function():void} onOutsideClick - Function to execute when an outside click is detected. 
 */
export const useDetectOutsideClick = (el, onOutsideClick) => {

  useEffect(() => {
    function onClick(e) {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        onOutsideClick()
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    window.addEventListener("click", onClick);

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [el]);
};
