import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import { SelectAllButton, SelectButton } from 'modules/common/hooks/useListSelector'
import tableStyles from 'modules/common/styles/sho-table.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import dayjs from 'dayjs'
import { sort } from 'fast-sort'
import React, { useEffect, useMemo, useState } from 'react'
import s from './targetList.module.scss'
import { Button } from 'modules/common/components'

const t = styleSynthesizer(tableStyles, 'TargetList')

/**
 * @param {object} p
 * @param {Target[]} p.targets
 * @param {import('modules/common/hooks/useListSelector').useListSelector<Target> =} p.listSelector
 */
function TargetList({ targets, listSelector }) {

  const sortedTargets = useMemo(() => sort([ ...targets ]).by([ { desc: t => t.endDate }, { asc: t => t.hours } ]), [ targets ])
  useEffect(() => listSelector?.unSelectAll(), [ targets ])

  if (targets.length <= 0) return <p className={t('notFound')}>No targets for the given time frame</p>
  
  function progressColor(logged, required) {
    if (logged >= required) return <FA className={s.check} icon={[ 'far', 'check-circle' ]} />
    return <FA className={s.warning} icon='exclamation-triangle' />
  }

  function formatDate(date) {
    return dayjs(date).format('MM-DD-YYYY')
  }

  return (
      <table className={t('table')}>
        <thead>
          <tr>
            {listSelector &&
              <React.Fragment>
                <th className={t('selector')}><SelectAllButton selector={listSelector} items={targets} /></th>
                <th className={s.userGroup}><UserGroupsTerm /></th>
              </React.Fragment>
            }
            <th className={s.startDate}>Start Date</th>
            <th className={s.endDate}>End Date</th>
            <th className={s.type}>Type</th>
            <th className={s.description}>Description</th>
            <th className={s.target}>Target</th>
            <th className={s.logged}>Logged</th>
            <th className={s.percent}>Percent</th>
            <th className={s.status}>Status</th>
          </tr>
        </thead>
        <tbody>
          {sortedTargets.map(target => (
            <tr key={target._id} >
              {listSelector &&
                <React.Fragment>
                  <td className={t('selector')} onClick={(e) => listSelector.onSelect(e, target)}><SelectButton selector={listSelector} item={target} /></td>
                  <td className={s.userGroup}>{target.userGroupName}</td>
                </React.Fragment>
              }
              <td className={s.startDate}><label>Start Date:</label>{formatDate(target.startDate)}</td>
              <td className={s.endDate}><label>End Date:</label>{formatDate(target.endDate)}</td>
              <td className={s.type}><label>Type</label>{target.type.name}</td>
              <td className={s.description}><label>Description</label>{target.description}</td>
              <td className={s.target}><label>Target</label>{target.hours}</td>
              <td className={s.logged}><label>Logged</label>{target.meta.totalHours}</td>
              <td className={s.percent}><label>Percent</label>{target.meta.percentComplete}%</td>
              <td className={s.status}><label>Status</label>{progressColor(target.meta.totalHours, target.hours)}</td>
            </tr>
          ))}
        </tbody>
      </table>
  )
}

export default TargetList
