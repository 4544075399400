import { useUpdatePassword } from 'api'
import { NotificationMessage } from 'modules/common/components'
import { Button, Password, useFormManagement } from 'modules/common/components/Forms'
import React, { useEffect } from 'react'
import __ from './changePasswordEntry.module.css'

/**
 * @param {object} p
 * @param {string} p.userName - userName for the password which is changing
 * @param {string} p.userId - userId of the user whos password is changing
 * @param {function():void} p.onSuccess - executed on successful update
 */
export default function PasswordEntry({ userId, userName, onSuccess }) {
  const { isLoading, error, mutate, isSuccess } = useUpdatePassword()
  const { data, onChange, isDirty, onSubmit } = useFormManagement({ 
    userName, 
    userId, 
    password: '', 
    newPassword: '', 
    confirmedNewPassword: '' 
  }, mutate)

  useEffect(() => isSuccess && onSuccess(), [isSuccess])

  return (
    <form className={__.form} onSubmit={onSubmit}>
      <NotificationMessage error={error} />

      <Password label='Current Password'
        type='password'
        name='password'
        value={data.password}
        onChange={onChange} />

      <Password label='New Password'
        type='password'
        name='newPassword'
        value={data.newPassword}
        onChange={onChange} />

      <Password label='Confirm New Password'
        type='password'
        name='confirmedNewPassword'
        value={data.confirmedNewPassword}
        onChange={onChange} />

      <div className={__.actions}>
        <Button className={__.buttonUpdate} onClick={onsubmit} isDirty={isDirty} isBlocking={isLoading} type="submit">Update</Button>
      </div>
    </form>
  )
}
