
/**
 * @param { object } variables
 * @param {string} variables.userName
 * @param {string} variables.password
 * @returns { shoQuery }
 */
export const login = (variables) => ({
  key: ['login-user'],
  variables,
  query: `
  mutation Login($userName: String, $password: String) {
    user: login(userName: $userName, password: $password) {
      _id
      firstName
      lastName
      userName
      lastLoggedInTimestamp
      userGroupRoles {
        memberRole
        memberType
        userGroup {
          _id
          name
          org {
            _id
            name
          }
        }
      }
      managingOrgs {
        administratorRole
        org {
          _id
          name
        }
      }
    }
  }
  `
})

/**
 * @returns {shoQuery}
 */
export const logout = () => ({
  key: ['logout-user'],
  query: `
  mutation {
    logOut
  }
  `,
})

/**
 * @returns {shoQuery}
 */
export const getLoggedInUser = () => ({
  key: ['logged-in-user'],
  query: `
    query {
      user: getLoggedInUser {
        _id
        firstName
        lastName
        userName
        lastLoggedInTimestamp
        userGroupRoles {
          memberRole
          memberType
          userGroup {
            _id
            name
            org {
              _id
              name
            }
          }
        }
        managingOrgs {
          administratorRole
          org {
            _id
            name
          }
        }
      }
    }
   `,
})

/**
 * @param {userId} userId
 * @returns {shoQuery}
 */
export const getUserMemberships = userId => ({
  key: ['user-memberships', { userId }],
  variables: { userId },
  query: `
  query UserByUserId($userId: ID ) {
    user: userByUserId(userId: $userId) {
      _id
      userGroupRoles {
        userId
        memberRole
        memberType
        userGroup {
          _id
          name
          tags
          createTimestamp
          inactiveTimestamp
          org {
            _id
            name
          }
          memberRoles {
            memberRole
            userGroupId
            user {
              _id
              firstName
              lastName
              userName
              email
              lastLoggedInTimestamp
            }
          }
        }
      }
    }
  }
   `,
})

/**
 * @param {UpdateUserPasswordInput} updateUserPasswordInput
 * @returns {shoQuery}
 */
export const updateUserPassword = updateUserPasswordInput => ({
  key: ['update-user-password', { userId: updateUserPasswordInput.userId }],
  variables: { updateUserPasswordInput },
  query: `
  mutation updateUserPassword($updateUserPasswordInput: UpdateUserPasswordInput!) {
    response: updateUserPassword(input: $updateUserPasswordInput) {
      user {
        _id
        userName
        email
        firstName
        lastName
      }
    }
  }
   `,
})

/**
 * @param {UpdateUserEmailInput} updateUserEmailInput
 * @returns {shoQuery}
 */
export const updateUserEmail = updateUserEmailInput => ({
  key: ['update-user-email', { userId: updateUserEmailInput.userId }],
  variables: { updateUserEmailInput },
  query: `
  mutation updateUserEmail($updateUserEmailInput: UpdateUserEmailInput!) {
    response: updateUserEmail(input: $updateUserEmailInput) {
      user {
        _id
        userName
        email
        firstName
        lastName
      }
    }
  }
   `,
})

/**
 * @param {UpdateUserInfoInput} updateUserInfoInput
 * @returns {shoQuery}
 */
export const updateUserInfo = updateUserInfoInput => ({
  key: ['update-user-email', { userId: updateUserInfoInput.userId }],
  variables: { updateUserInfoInput },
  query: `
  mutation updateUserInfo($updateUserInfoInput: UpdateUserInfoInput!) {
    response: updateUserInfo(input: $updateUserInfoInput) {
      user {
        _id
        userName
        email
        firstName
        lastName
      }
    }
  }
   `,
})
