/**
 * @param {userGroupId} userGroupId
 * @param {object} [filters]
 * @param {boolean=} filters.active - active only targets
 * @param {asOfDate=} filters.asOfDate - date to query back to
 * @param {string=} filters.untilDate - date to query back to
 */
export function getUserGroupTargets(userGroupId, filters) {
  const key = [ 'targets', userGroupId ]
  if (filters) key.push(filters)

  return {
    key,
    variables: { userGroupId, ...filters },
    query: `
    query TargetsByUserGroupId($userGroupId: ID, $active:Boolean, $asOfDate:Date $untilDate: Date) {
      targets: targetsByUserGroupId(userGroupId: $userGroupId, active: $active, asOfDate: $asOfDate, untilDate: $untilDate) {
        _id
        userGroupId
        type {
          _id
          name
          description
        }
        startDate
        endDate
        hours
        description
        meta {
          totalHours
          percentComplete
        }
      }
    }
`,
  }
}

/**
 * @param {userGroupId[]} userGroupIds
 * @param {object} [filters]
 * @param {boolean=} filters.active - active only targets
 * @param {asOfDate=} filters.asOfDate - date to query back to
 * @param {string=} filters.untilDate - date to query back to
 * @returns {shoQuery}
 */
export function getUserGroupsTargets(userGroupIds, filters) {
  const key = [ 'userGroups', 'targets', userGroupIds ]
  if (filters) key.push(filters)

  return {
    key,
    variables: { userGroupIds, ...filters },
    query: `
    query UserGroupsTargets($userGroupIds: [ ID ], $active:Boolean, $asOfDate:Date $untilDate: Date) {
      userGroups(userGroupIds: $userGroupIds) {
        _id
        name
        targets (active: $active, asOfDate: $asOfDate, untilDate: $untilDate) {
          _id
          userGroupId
          type {
            _id
            name
            description
          }
          startDate
          endDate
          hours
          description
          meta {
            totalHours
            percentComplete
          }
        }
      }
    }
`,
  }
}

/**
 * @param {RemoveTargetsInput} input
 */
export const removeTargetsForUserGroups = (input) => ({
  key: [ 'removeTargetsForUserGroups' ],
  variables: { input },
  query: `
  mutation removeTargetsFromUserGroup($input: RemoveTargetsInput!) {
    removeTargetsForUserGroups(input: $input) {
      removedTargets {
        userGroupId
        targetId
      }
    }
  }
  `
})

/**
 * @param {UpdateTargetsInput} input
 */
export const updateTargetsForUserGroups = (input) => ({
  key: [ 'updateTargetsForUserGroups' ],
  variables: { input },
  query: `
  mutation updateTargetsFroUserGroup($input: UpdateTargetsInput!) {
    updateTargetsForUserGroups(input: $input) {
      targets {
        _id
        userGroupId
      }
    }
  }
  `
})


/**
 * @param {CreateTargetForUserGroupsInput} input
 */
export const createTargetForUserGroups = (input) => ({
  key: [ 'createTargetForUserGroups' ],
  variables: { input },
  query: `
  mutation createTargetFroUserGroup($input: CreateTargetForUserGroupsInput!) {
    createTargetForUserGroups(input: $input) {
      targets {
        _id
        userGroupId
      }
    }
  }
  `
})