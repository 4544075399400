import { queries, shoFetch } from "api"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from 'react-toastify'
import { AddUserToUserGroupInput, CreateUserGroupInput, CreateUserGroupResponse, InactivateUserGroupsInput, Org, RemoveUserFromUserGroupInput, RemoveUserGroupsResponse, UpdateUserGroupInput, UpdateUserGroupMembersResponse, UpdateUserGroupResponse, UserGroup } from "types/query"

/** 
 * @param {object} o
 * @param {userGroupId} o.userGroupId 
 */
export function useUserGroup({ userGroupId }) {
  const query = queries.getUserGroup(userGroupId)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { userGroup: /** @type {UserGroup} */(data?.userGroup), ...response }
}

/** 
 * @param {userGroupId []} userGroupIds
 */
export function useUserGroups(userGroupIds) {
  const query = queries.getUserGroups(userGroupIds)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query), { cacheTime: 1000 })
  return { userGroups: /** @type {UserGroup []} */(data?.userGroups), ...response }
}

export function useAddMemberToUserGroup() {
  const queryClient = useQueryClient()

  return useMutation<UpdateUserGroupMembersResponse, queryError, AddUserToUserGroupInput>(
    async (addUserToUserGroupInput) => shoFetch(queries.addUserToUserGroup(addUserToUserGroupInput))
    , {
      mutationKey: 'addUserToUserGroup',
      onSuccess: (__, {email, sendWelcomeEmail}) => {
         
        const message = sendWelcomeEmail ? `Member added and notification email sent to ${email}` : 'Member Added'
        toast.success(message)
        
        queryClient.invalidateQueries(queries.getUserMemberships(null)[ 0 ])
        queryClient.invalidateQueries(queries.getUserGroups(null).key[ 0 ])
      },
    }
  )
}

export function useRemoveUserFromUserGroup() {
  const queryClient = useQueryClient()

  return useMutation<UpdateUserGroupMembersResponse, queryError, RemoveUserFromUserGroupInput>(
    async (input) => {
      return shoFetch(queries.removeUserFromUserGroup(input))
    }, {
    mutationKey: 'removeUserFromUserGroup',
    onSuccess: () => {
      toast.success(`User removed`)
      queryClient.invalidateQueries(queries.getUserMemberships(null).key[ 0 ])
      queryClient.invalidateQueries(queries.getUserGroups(null).key[ 0 ])
    },
  }
  )
}

/** @returns {import("react-query").UseMutationResult} */
export function useCreateUserGroup() {
  const queryClient = useQueryClient()

  return useMutation<{response: CreateUserGroupResponse}, queryError, CreateUserGroupInput >(
    async (input) => {
      return shoFetch(queries.createUserGroup(input))
    }, {
    mutationKey: 'createUserGroup',
    onSuccess: ({ response: { userGroup } }, input) => {
      toast.success(`${input.name}, created`)

      queryClient.setQueryData([ 'org', 'user-groups', input.orgId ], (old: {org: Org}) => {
        old?.org?.userGroups.push(userGroup)
        return old
      })
    },
  })
}

export function useInactivateUserGroups() {
  const queryClient = useQueryClient()

  return useMutation<{response: RemoveUserGroupsResponse }, queryError, InactivateUserGroupsInput>(
    async (input) => {
      return shoFetch(queries.inactivateUserGroups(input))
    }, {
    mutationKey: 'inactivateUserGroups',
    onSuccess: ({ response: { userGroups } }, input) => {
      toast.success(`${input.userGroupIds.length} inactivated`)

      queryClient.setQueryData([ 'org', 'user-groups', input.orgId ], (old: {org: Org}) => {
        if(old?.org?.userGroups) old.org.userGroups = old.org.userGroups.map(ug => {
          return userGroups.find(nug => nug._id === ug._id) ?? ug
        })
        return old
      })
    }
  })
}

export function useUpdateUserGroup() {
  const queryClient = useQueryClient()

  return useMutation<{response: UpdateUserGroupResponse }, queryError, UpdateUserGroupInput>(
    async (input) => {
      return shoFetch(queries.updateUserGroup(input))
    }, {
    mutationKey: 'updateUserGroup',
    onSuccess: ({ response: { userGroup } }, input) => {
      toast.success(`${input.name} updated`)

      queryClient.setQueryData([ 'org', 'user-groups', input.orgId ], (old: {org: Org}) => {
         const index = old?.org?.userGroups.findIndex(ug => ug._id === userGroup._id) 
         if(index !== -1) old.org.userGroups[index] = userGroup
         return old
      })

      queryClient.setQueriesData(['userGroups'], (old: { userGroups: UserGroup[] }) => {
        if(old.userGroups) {
         const index = old?.userGroups.findIndex(ug => ug._id === userGroup._id) 
         if(index !== -1) old.userGroups[index].name = userGroup.name
        }
        old.userGroups = [...old.userGroups]
        return old
      })
    }
  })
}