import { faSave } from '@fortawesome/free-regular-svg-icons'
import { faEdit, faPencilAlt, faPlusCircle, faTrash, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TargetRule from 'modules/admin/components/TargetRule'
import { Button, Dropdown, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import useListSelector from 'modules/common/hooks/useListSelector'
import styleSynthesizer from 'modules/common/styles/styles'
import { useRemoveTargetRule, useUpsertTargetRule } from 'api/hooks/targetRule'
import React, { useEffect, useState } from 'react'
import s from './targetRulesManagement.module.scss'
const ss = styleSynthesizer(s, 'TargetRulesManagement')

const clusivityOptions = [ { key: 'INCLUSIVE', name: 'Inclusive' }, { key: 'EXCLUSIVE', name: 'Exclusive' } ]
/**
 * @param {object} p 
 * @param {orgId} p.orgId
 * @param {TargetRule []} p.targetRules
 * @param {Activity []} p.activities
 */
export default function TargetRulesManagement({ orgId, targetRules, activities = [] }) {
  const upsertTargetRule = useUpsertTargetRule()

  const [ showCreate, setShowCreate ] = useState(false)
  const form = useFormManagement({ orgId, name: '', maxHours: 0, activityIds: [], description: '', activitiesClusivity: 'INCLUSIVE' }, upsertTargetRule.mutate)

  useEffect(() => {
    if (upsertTargetRule.isSuccess || showCreate) document.getElementsByName('maxHours')?.item(0)?.focus()
    if (upsertTargetRule.isSuccess) form.resetState()
  }, [ upsertTargetRule.isSuccess, showCreate ])

  if (activities?.length < 1) return <p className={s.noActivities}>Rules require multiple activities to be effective.</p>

  return (
    <div className={ss('container')}>
      <Button className={ss('showCreate')} onClick={() => setShowCreate(true)}><FontAwesomeIcon icon={faPlusCircle} /></Button>

      <InLineForm isEnabled={showCreate} onClose={setShowCreate}>
        <NotificationMessage error={upsertTargetRule.error} />
        <p>{upsertTargetRule?.error?.message}</p>

        <form className={ss('createForm')} onSubmit={form.onSubmit} >
          <TargetRuleInputs form={form} activities={activities} />
          <Button className={s.addButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={upsertTargetRule.isLoading}> Save Rule </Button>
        </form>
      </InLineForm>

      {targetRules.map(rule => <EditTargetForm key={rule._id} orgId={orgId} activities={activities} targetRule={rule} />)}

    </div>
  )
};

function EditTargetForm({ targetRule, orgId, activities: activityList }) {
  const upsertTargetRule = useUpsertTargetRule()
  const removeTargetRule = useRemoveTargetRule()
  const [ showEdit, setShowEdit ] = useState(false)

  const { activities, ...formData} = targetRule
  const form = useFormManagement({ orgId, ...formData }, upsertTargetRule.mutate)

  const RuleDisplay = () =>
    <React.Fragment>
      <TargetRule targetRule={targetRule} />
      <div className={s.editButtons}>
        <Button className={s.editButton} onClick={() => setShowEdit(true)} >
          <FontAwesomeIcon icon={faPencilAlt} />
        </Button>
      </div>
    </React.Fragment>

  return (
    <article className={s.rule}>
      {showEdit ?
        <InLineForm isEnabled={showEdit} onClose={setShowEdit}>
          <form className={ss('editForm')} onSubmit={form.onSubmit}>
            <TargetRuleInputs form={form} selectedActivities={targetRule.activities }activities={activityList} />
            <Button className={s.removeButton} onClick={() => removeTargetRule.mutate({ orgId, targetRuleId: targetRule._id })} >
              Remove Rule
            </Button>
            <Button className={s.saveButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={upsertTargetRule.isLoading}> Save Rule </Button>
          </form>
        </InLineForm>
        : <RuleDisplay />
      }
    </article>
  )

}

/**
 * @param {object} p 
 * @param {import('modules/common/components/Forms/useFormManagement').useInputFormReturn<TargetRule>} p.form
 * @param {Activity []} p.activities
 * @param {Activity []} p.selectedActivities
 */
function TargetRuleInputs({ form, activities = [], selectedActivities = [] }) {
  const selector = useListSelector(selectedActivities, undefined, 'MULTI')
  useEffect(() => {
    form.updateState({ ...form.data, activityIds: selector.getSelectedIds() })
  }, [ selector.count, selector.getSelected()[ 0 ]?._id ])

  return (
    <React.Fragment>
      <Input
        label='Rule Name'
        className={s.name}
        type='text'
        name='name'
        value={form.data.name}
        onChange={form.onChange} />
      <Input
        label='Max Hours'
        className={s.maxHours}
        type='number'
        name='maxHours'
        value={form.data.maxHours}
        helpText='Zero represents unlimited'
        onChange={form.onChange} />

      <Dropdown
        className={s.activitiesClusivity}
        label='Clusivity'
        emptyOption={false}
        optionId={'key'}
        options={clusivityOptions}
        name='activitiesClusivity'
        value={form.data.activitiesClusivity}
        onChange={form.onChange} />

      <Input
        label='Description'
        className={s.description}
        type='text'
        name='description'
        value={form.data.description}
        onChange={form.onChange} />


      <ol className={s.activityList}>
        {activities.map(activity =>
          <li key={activity._id}
            onClick={(e) => selector.onSelect(e, activity)}
            className={selector.isSelected(activity) ? s.selectedActivity : s.activity}>
            {activity.name}
          </li>
        )}
      </ol>

    </React.Fragment>
  )

}