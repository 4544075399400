/**
 * @param {UpsertTargetTypeInput} input
 */
export const upsertTargetType = (input) => ({
  key: 'upsertTargetType',
  variables: { input },
  query: `
    mutation UpsertTargetType($input: UpsertTargetTypeInput!) {
      response: upsertTargetType(input: $input) {
        targetType {
          _id
          name
          description
        }
      }
    }
  `
})

/**
 * @param {RemoveTargetTypeInput} input
 */
export const removeTargetType = (input) => ({
  key: 'removeTargetType',
  variables: { input },
  query: `
    mutation RemoveTargetType($input: RemoveTargetTypeInput!) {
      response: removeTargetType(input: $input) {
        orgId, targetTypeId
      }
    }
  `
})