import React from 'react'
import styles from './userOpportunities.module.css'
import styleSynthesizer from 'modules/common/styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserNinja } from '@fortawesome/free-solid-svg-icons'
const s = styleSynthesizer(styles, 'UserOpportunities')

export default function UserOpportunities({userGroupId}) {
  return (
    <React.Fragment >
      <h1 className={s('opportunitiesHeader')}>Opportunities</h1>
      <article className={s('opportunities')}>
        <p><FontAwesomeIcon icon={faUserNinja}/><span> - coming soon-ish</span> </p>
      </article>
    </React.Fragment>
  )
}
