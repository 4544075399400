import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import __ from './styles.module.css'

const ErrorPage = () => (
  <div className={__.page}>
    <FA className={__.bug} icon={['far', 'sad-tear']}/> 
    <p className={__.message}>Oh No ..., Something went wrong!</p>
    <p className={__.close}>Please close your browser and try again, if the problem continues contact your support</p>
  </div>
)

export default ErrorPage