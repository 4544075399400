import { Loading } from 'modules/common/components'
import { Button } from 'modules/common/components/Forms'
import NavBar from 'modules/common/components/Navbar'
import BasicUserInfoEntry from 'modules/user/container/BasicUserInfoEntry'
import CredentialManagement from 'modules/user/container/CredentialManagement'
import UserGroupMemberships from 'modules/user/container/UserGroupMemberships'
import { useLoggedInUser } from 'api'
import React from 'react'
import { Helmet } from 'react-helmet'
import __ from './userSettings.module.css'

export default function UserPage() {
  const { isLoading, user } = useLoggedInUser()

  if (isLoading) return <Loading />

  return (
    <div className={__.page}>
      <Helmet>
        <title>User Settings - Service Hours Online</title>
      </Helmet>
      <NavBar/>
      <section className={__.account}>
        <div className={__.action}>
          <Button onClick={() => window.history.back()}>Return</Button>
        </div>
        <h1 className={__.header}>Account Details</h1>
        <CredentialManagement />
      </section>
      <section className={__.info}>
        <h1 className={__.header}>User Information</h1>
        <BasicUserInfoEntry user={user} />
      </section>
      <section className={__.memberships}>
        <h1 className={__.header}>Memberships</h1>
        <UserGroupMemberships userId={user._id} />
      </section>
    </div>
  )
}

