import { useConfigContext } from 'modules/common/context/configContext'
import React from 'react'
import { CSVLink } from 'react-csv'
import s from './servicesDownload.module.css'

export default function MembersDownload({ members, children }) {
  const { userGroupSingularName } = useConfigContext()
  const headers = [
    {label: userGroupSingularName, key:'userGroupName'},
    {label: 'First Name', key:'firstName'},
    {label: 'Last Name', key:'lastName'},
    {label: 'Email', key:'email'},
    {label: 'Last Logged In', key:'lastLoggedInTimestamp'},
    {label: 'Registered Date', key:'registeredDate'},
  ]

  return (
    <CSVLink data={members} headers={headers} className={s.downloadLink} >
      {children}
    </CSVLink>
  )
}