import dayjs from 'dayjs'
import { TagList } from 'modules/common/components'
import { SelectButton, useListSelector } from 'modules/common/hooks/useListSelector'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import { UserGroup } from 'types/query'
import styles from './orgUserGroups.module.scss'
const s = styleSynthesizer(styles, 'OrgUserGroups')

export default function OrgUserGroups({ userGroups, selector }) {


  return (
    <section className={s('container')}>
      {userGroups.map(ug =>
        <UserGroupRow key={ug._id} userGroup={ug} selector={selector} />
      )}
    </section>
  )
}

function UserGroupRow({ userGroup, selector }:{userGroup: UserGroup, selector: useListSelector<UserGroup>}) {

  return (
    <article className={s('userGroup')} onClick={(e) => selector.onSelect(e, userGroup)}>
      <SelectButton selector={selector} className={s('selectButton')} item={userGroup} />

      <div className={s('userGroupInfo')}>
        <div className={s('nameLine')}>
          <p className={s('userGroupName')}>{userGroup.name}</p>
          {userGroup.inactiveTimestamp 
          ?  <p className={s('inactiveTime')}>{dayjs(userGroup.inactiveTimestamp).format('MM/DD/YYYY')}</p>
          :  <p className={s('createTime')}>{dayjs(userGroup.createTimestamp).format('MM/DD/YYYY')}</p>
            }
        </div>
        <TagList tags={userGroup.tags} />
      </div>
    </article>
  )

}