import { queries, shoFetch } from "api"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from 'react-toastify'

/**
 * @param {object} input
 * @param {userGroupId} input.userGroupId - return org information by user group id
 * @param {boolean} input.active - only return active targets or not
 * @param {asOfDate} input.asOfDate - when to query them by 
 * @param {string} input.untilDate - when to query them by 
 */
export function useTargets(input) {
  const { userGroupId, ...variables } = input
  const query = queries.getUserGroupTargets(userGroupId, variables)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { targets: /** @type {Target[]}*/(data?.targets), ...response }
}

/**
 * @param {object} input
 * @param {userGroupId[]} input.userGroupIds
 * @param {boolean=} input.active - only return active targets or not
 * @param {asOfDate=} input.asOfDate - when to query them by 
 * @param {string=} input.untilDate - when to query them by 
 */
export function useUserGroupsTargets(input) {
  const { userGroupIds, ...variables } = input
  const query = queries.getUserGroupsTargets(userGroupIds, variables)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query), { cacheTime: 1000 })
  return { userGroups: /** @type {UserGroupExtended[]}*/(data?.userGroups), ...response }
}

/**
 * @param {object} input
 * @param {userGroupId[]} input.userGroupIds
 * @param {number=} input.limit - only return active targets or not
 * @param {asOfDate=} input.asOfDate - when to query them by 
 * @param {string=} input.untilDate - when to query them by 
 */
export function useUserGroupsServices(input) {
  const { userGroupIds, ...variables } = input
  const query = queries.getUserGroupsServices(userGroupIds, variables)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query), { cacheTime: 1000 })
  return { userGroups: /** @type {UserGroupExtended[]}*/(data?.userGroups), ...response }
}


/** @returns {import("react-query").UseMutationResult<RemoveTargetTypeResponse, queryError, RemoveTargetsInput, any>} */
export function useRemoveTargetsForUserGroups() {
  const queryClient = useQueryClient()

  return useMutation(
    async (removeTargetsInput) => { return shoFetch(queries.removeTargetsForUserGroups(removeTargetsInput)) },
    {
      mutationKey: 'removeTargetsForUserGroups',

      onSuccess: (__, {targetIds}) => {
        toast.success(`${targetIds.length} target(s) removed`)
        queryClient.invalidateQueries([ 'userGroups', 'targets' ])
        queryClient.invalidateQueries([ 'targets' ])
      }
    })
}

/** @returns {import("react-query").UseMutationResult<UpsertTargetsResponse, queryError, UpdateTargetsInput, any>} */
export function useUpdateTargetsForUserGroups() {
  const queryClient = useQueryClient()

  return useMutation(
    async ({orgId, startDate, endDate, targetTypeId, approvedServicesOnly=false, description, hours, targetIds}) => {
      return shoFetch(queries.updateTargetsForUserGroups({orgId, startDate, endDate, targetTypeId, approvedServicesOnly, description, hours: +hours, targetIds, targetRuleIds: []}))
    }, {

    mutationKey: 'updateTargetsForUserGroups',

    onSuccess: (_, {targetIds}) => {
      toast.success(`${targetIds.length} target(s) updated`)

      queryClient.invalidateQueries([ 'userGroups', 'targets' ])
      queryClient.invalidateQueries([ 'targets' ])
    }
  })
}

/** @returns {import("react-query").UseMutationResult<UpsertTargetsResponse, queryError, CreateTargetForUserGroupsInput, any>} */
export function useCreateTargetForUserGroups() {
  const queryClient = useQueryClient()

  return useMutation(
    async ({orgId, startDate, endDate, targetTypeId, approvedServicesOnly=false, description, hours, userGroupIds}) => {
      return shoFetch(queries.createTargetForUserGroups({orgId, startDate, endDate, targetTypeId, approvedServicesOnly, description, hours: +hours, userGroupIds, targetRuleIds: []}))
    }, {

    mutationKey: 'createTargetForUserGroups',

    onSuccess: (__, {userGroupIds}) => {
      toast.success(`${userGroupIds.length} target(s) created`)
      
      queryClient.invalidateQueries([ 'userGroups', 'targets' ])
      queryClient.invalidateQueries([ 'targets' ])
    }
  })
}