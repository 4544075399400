import { Link } from '@reach/router'
import { useLogin } from 'api/hooks'
import { Loading, NotificationMessage } from 'modules/common/components'
import { Button, Input, Password, useFormManagement } from 'modules/common/components/Forms'
import React from 'react'
import { Helmet } from 'react-helmet'
import __ from 'modules/auth/styles/auth.module.css'

function Login() {
  const { mutate, error, isLoading } = useLogin()
  const { data, isDirty, onChange, onSubmit } = useFormManagement({ userName: '', password: '' }, mutate)

  return (
    <div className='auth__login'>
      <Helmet>
        <title>Login - Service Hours Online</title>
      </Helmet>

      <form className={__.formFields} onSubmit={onSubmit} aria-label='login form'>
        <NotificationMessage error={error} />

        <h1 className={__.header}>Login</h1>

        <Input label='Email Address' type='text' name='userName' value={data.userName} onChange={onChange} />

        <Password label='Password' type='password' name='password' value={data.password} onChange={onChange} />

        <div className={__.actions}>
          <Button className={__.button} onClick={onSubmit} isDirty={isDirty} isBlocking={isLoading} type='submit'>
            Login
          </Button>
          <Link className={__.passwordReset} to='/auth/passwordReset'>
            Forgot Password
          </Link>
        </div>
      </form>
    </div>
  )
}

export default Login
