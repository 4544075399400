import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import PopupMenu from 'modules/common/components/PopupMenu'
import useIsServiceMutationDisabled from 'modules/common/hooks/useIsServiceMutationDisabled'
import React from 'react'
import __ from './serviceRowEditMenu.module.css'


/**
 * @param {object} p 
 * @param {Service} p.service 
 * @param {function(Service):void} p.onDeleteSelected - function to execute when delete is selected
 * @param {function(Service):void} p.onEditSelected - function executed when edit is selected
 */
export default function ServiceListEditMenu({ service, onDeleteSelected, onEditSelected }) {
  const { isServiceMutationDisabled } = useIsServiceMutationDisabled(service.userGroupId)
  if (isServiceMutationDisabled) return null

  return (
    <PopupMenu >
      <li className={__.edit} onClick={() => onEditSelected(service)}>
        <FA className={__.pencil} icon='pencil-alt' />
        Edit
      </li>
      <li className={__.remove} onClick={() => onDeleteSelected(service)}>
        <FA className={__.trash} icon='trash-alt' />
        Remove
      </li>
    </PopupMenu>
  )
};

