import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import InLineForm from 'modules/common/components/InLineForm'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import { useConfigContext } from 'modules/common/context/configContext'
import { SelectAllButton, SelectButton } from 'modules/common/hooks/useListSelector'
import tableStyles from 'modules/common/styles/sho-table.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { getDisplayDate } from 'modules/common/utils/dateFormats'
import { EditServiceEntry } from 'modules/user/container/ServiceEntry'
import { useDeleteService } from 'api'
import { getPerformedByValue } from 'api/selectors'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import ServiceListEditMenu from '../ServiceRowEditMenu'
import s from './serviceTable.module.scss'
const t = styleSynthesizer(tableStyles, 'ServiceTable')

/**
 * @param {object} p 
 * @param {Service[]} p.services
 * @param {userGroupId=} p.userGroupId - this is used for the edit form, so it knows what to display
 * @param {import('modules/common/hooks/useListSelector').useListSelector<Service> =} p.listSelector
 */
export default function ServiceTable({ services, userGroupId }) {
  const { showPerformedBy } = useConfigContext()

  if (services.length <= 0) return <p className={t('notFound')}>No services for the given time frame</p>

  return (
    <table className={t('table')}>
      <thead>
        <tr>
          {services[ 0 ].userGroupName &&
            <React.Fragment>
              <th className={s.userGroup}><UserGroupsTerm /></th>
            </React.Fragment>
          }
          <th className={s.date}>Service Date</th>
          {showPerformedBy && <th className={s.performedBy}>Performed By</th>}
          <th className={s.activity}>Activity</th>
          <th className={s.description}>Description</th>
          <th className={s.hours}>Hours</th>
          <th className={s.editIcon}>
            <FA className={s.edit} icon={'pencil-alt'} />
          </th>
        </tr>
      </thead>
      <tbody>
        {services.map(service =>
          <ServiceTableRow key={service._id} service={service} userGroupId={userGroupId} />
        )}
      </tbody>
    </table>
  )
};

function ServiceTableRow({ service, userGroupId }) {
  const { showPerformedBy } = useConfigContext()
  const [ showEditForm, setShowEditForm ] = useState(false)
  const deleteService = useDeleteService()

  return (
    <React.Fragment>
      <tr>
        {service.userGroupName &&
          <React.Fragment>
            <td className={s.userGroup}><label><UserGroupsTerm /></label>{service.userGroupName}</td>
          </React.Fragment>
        }
        <td className={s.date}><label>Service Date:</label>{getDisplayDate(service.serviceDate)}</td>
        {showPerformedBy && <td className={s.performedBy}><label>Performed By:</label>{getPerformedByValue(service)}</td> }
        <td className={s.activity}><label>Activity</label>{service.activity.name}</td>
        <td className={s.description}><label>Description</label>{service.description}</td>
        <td className={s.hours}><label>Hours</label>{service.hours}</td>
        <td className={t('editIcon')}>
          <label> <FA className={s.edit} icon={'pencil-alt'} /> </label>
          <ServiceListEditMenu
            service={service}
            onEditSelected={() => setShowEditForm(true)}
            onDeleteSelected={service => deleteService.mutate({ serviceId: service._id, userGroupId: service.userGroupId || userGroupId })}
          />
        </td>
      </tr>
      {showEditForm &&
        <tr className={s.editForm} >
          <td colSpan={6}>
            <InLineForm isEnabled={showEditForm} onClose={setShowEditForm}>
              <EditServiceEntry service={service} userGroupId={service.userGroupId || userGroupId} onSuccess={() => setShowEditForm(false)} />
            </InLineForm>
          </td>
        </tr>
      }
    </React.Fragment>
  )

}