import { faPlusCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { useRemoveActivity, useUpsertActivity } from 'api'
import { sortActivitiesByName } from 'api/selectors'
import React, { useEffect, useRef, useState } from 'react'
import s from './activitiesManagement.module.scss'
const ss = styleSynthesizer(s, 'ActivitiesManagement')

//TODO: Break this out into multiple components and structure it similar to Sponsors Management.   
export default function ActivitiesManagement({ activities, orgId }) {
  const upsertActivity = useUpsertActivity()
  const inputRef = useRef(null)
  const { data, isDirty, onChange, onSubmit, resetState } = useFormManagement({ orgId, name: '', description: '' }, upsertActivity.mutate)
  const sortedActivities = sortActivitiesByName(activities)
  const [ showCreate, setShowCreate ] = useState(false)

  useEffect(() => { 
    if(upsertActivity.isSuccess || showCreate) inputRef?.current?.focus()
    if(upsertActivity.isSuccess) resetState() 
  }, [upsertActivity.isSuccess, showCreate])

  return (
    <div className={ss('container')}>
      <Button className={ss('showCreate')} onClick={() => setShowCreate(true)}><FontAwesomeIcon icon={faPlusCircle} /></Button>

      <InLineForm isEnabled={showCreate} onClose={setShowCreate}>
        <NotificationMessage error={upsertActivity.error} />
        <form className={ss('createForm')} onSubmit={onSubmit}>
          <Input
            ref={inputRef}
            autoComplete='off'
            className={s.activityName}
            label='Activity Name'
            type='text'
            name='name'
            value={data.name}
            onChange={onChange} />
          <Input
            className={s.activityDescription}
            autoComplete='off'
            label='Description'
            type='text'
            name='description'
            value={data.description}
            onChange={onChange} />

          <Button className={s.addButton} type="submit" onClick={onSubmit} isDirty={isDirty} isBlocking={upsertActivity.isLoading}> Add </Button>
        </form>

      </InLineForm>

      {sortedActivities.map(activity =>
        <EditActivityForm  key={activity._id} activity={{ orgId, ...activity }} />
      )}
    </div>
  )
};

function EditActivityForm({ activity }) {
  const upsertActivity = useUpsertActivity()
  const removeActivity = useRemoveActivity()

  activity.description = activity.description ? activity.description : ''
  const { data, isDirty, onChange, onSubmit, resetState } = useFormManagement(activity, upsertActivity.mutate)
  useEffect(() => resetState(data), [upsertActivity.isSuccess])

  return (
    <form className={ss('form')} onSubmit={onSubmit}>
      <p className={s.updateError}>{upsertActivity?.error?.message}</p>

      <Input
        className={s.activityName}
        autoComplete='off'
        placeholder='Activity Name'
        type='text'
        name='name'
        value={data.name}
        onChange={onChange} />
      <Input
        className={s.activityDescription}
        autoComplete='off'
        placeholder='Description (optional)'
        type='text'
        name='description'
        value={data.description}
        onChange={onChange} />

      <div className={ss('buttons')}>
        {isDirty &&
          <Button className={s.submitButton} type="submit" onClick={onSubmit} isDirty={isDirty} >
            <FontAwesomeIcon icon={faSave} />
          </Button>}
        <Button className={s.deleteButton} onClick={() => removeActivity.mutate({orgId: activity.orgId, activityId: activity._id})} >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    </form>
  )
}