import { Router } from '@reach/router';
import UserInvitePage from './UserInvitePage'
import React from 'react';

import __ from './requests.module.css';
import { Navbar } from 'modules/common/components'

function Requests({}) {

  return ( 
    <div className={__.container}>
      <Navbar />
      <div className={__.content}>
        <Router>
          <UserInvitePage path="userInvite/:requestId"/>

        </Router>
      </div>
    </div>
  );
};


export default Requests;