import TargetProgress from 'modules/user/components/TargetProgress'
import dayjs from 'dayjs'
import { sort } from 'fast-sort'
import React from 'react'
import __ from './targets.module.css'

/** 
 * @param {object} props
 * @param {target []} props.targets - full array of targets to filter down for display
 */
function ProgressSection({ targets }) {
  const sortedTargets = sort(targets).asc(['endDate', 'hours'])

  const TargetView = sortedTargets.map((target, index, targets) =>
    <React.Fragment key={target._id}>
      <TargetDateDisplay prevT={targets[index - 1]} target={target} />
      <TargetProgress target={target} />
    </React.Fragment>
  )

  return <React.Fragment>{TargetView}</React.Fragment>
}

function TargetDateDisplay({prevT, target}) {
  const isSameDateAndType = (prevT &&
    (prevT.startDate === target.startDate &&
     prevT.endDate === target.endDate &&
     prevT.type._id === target.type._id))

  if (!isSameDateAndType) return (
    <p className={__.targetDate}>
      {`${dayjs(target.startDate).format('MMM DD, YYYY')} - ${dayjs(target.endDate).format('MMM DD, YYYY')}`}
    </p>
  )
  return null
}

export default ProgressSection