import { Button, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import { useUpdateUserGroup } from 'api'
import React, { useEffect } from 'react'
import s from './editUserGroupForm.module.scss'

export default function UpdateUserGroupForm({ orgId, userGroup,  onClose, onSuccess }) {
  const updateUserGroup = useUpdateUserGroup()
  const form = useFormManagement({ orgId, _id: userGroup?._id, name: userGroup.name }, updateUserGroup.mutate)

  useEffect(() => {
    if (updateUserGroup.isSuccess) { onSuccess() } }, [updateUserGroup.isSuccess])

  return (
    <InLineForm isEnabled={true} onClose={onClose}>
      <NotificationMessage error={updateUserGroup.error} />
      <form className={s.form} onSubmit={form.onSubmit} >

        <Input
          label='Name'
          autoComplete="off"
          type='text'
          name='name'
          value={form.data.name}
          onChange={form.onChange} />

        <Button className={s.saveButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={updateUserGroup.isLoading}>Save
        </Button>
      </form>
    </InLineForm>
  )
}

