import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useNavigation } from 'navigation'
import React from 'react'
import styles from './navbar.module.css'
const s = styleSynthesizer(styles, 'NavBar')

/**
 * @param {object} p
 * @param {userGroupId=} p.userGroupId - if passed in the link will be the user group page
 * @param {orgId=} p.orgId - if passed in the link will be the org page
 */
function NavBar({userGroupId, orgId}) {
  const nav = useNavigation()
  let navTo = nav.home().url()

  if(userGroupId) navTo = nav.userGroup(userGroupId).url()
  if(orgId) navTo = nav.org(orgId).url()

  return (
    <nav className={s('container')} aria-label="Service Hours Online Title, Click to navigate to the home page">
      <Link className={s('link')} to={navTo} > 
        <span className={s('service')}>Service </span>
        <span className={s('hours')}>Hours </span>
        <span className={s('online')}>Online </span>
      </Link>

    </nav>

  )
}

export default NavBar