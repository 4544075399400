import { faFileExcel, faFilter, faPlusCircle, faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUserGroupsServices } from 'api'
import dayjs from 'dayjs'
import ServicesDownload from 'modules/admin/components/ServicesDownload'
import { InLineForm, Loading, PopupMenu, TimeFrameFilters } from 'modules/common/components'
import ServiceFilters from 'modules/common/components/ServiceFilters'
import ServiceTotals from 'modules/common/components/ServiceTotals'
import styleSynthesizer from 'modules/common/styles/styles'
import { useFlattenUserGroupServices, useServiceFilters } from 'modules/common/workers'
import { filterServices } from 'modules/common/workers/serviceWorker'
import ServiceTable from 'modules/user/components/SerivceTable'
import { CreateServiceEntry } from 'modules/user/container/ServiceEntry'
import React, { useState } from 'react'
import s from './userGroupsServices.module.scss'
const ss = styleSynthesizer(s, 'UserGroupsServices')

export default function UserGroupsServices({ userGroupIds, limit = 50 }) {

  const [ showCreate, setShowCreate ] = useState(false)
  const [ asOfDate, setAsOfDate ] = useState(dayjs().subtract(6, 'months').format("YYYY-MM-DD"))
  const [ untilDate, setUntilDate ] = useState(undefined)
  const { isLoading, userGroups } = useUserGroupsServices({ userGroupIds, limit, asOfDate, untilDate })
  const [ showFilter, setShowFilter ] = useState(false)
  const [ serviceFilters, setServiceFilters ] = useState()
  const { services } = useFlattenUserGroupServices(userGroups)
  const { services: filteredServices, status: filteredServiceStatus } = useServiceFilters(services, serviceFilters)


  return (
    <React.Fragment >
      <div className={ss('tooManyMessage')}>
        {(limit >= 2000 && limit === services.length) && <p className={s.limited}>Only showing first 2000 services</p>}
      </div>

      <div className={ss('tools')}>
        <PopupMenu icon={faTools} iconLabel='Services'>
          <li data-type='title'>Service Management</li>
          <li data-disabled={userGroups?.length !== 1} onClick={() => setShowCreate(true)}>
            <FontAwesomeIcon icon={faPlusCircle} /><span>Add</span>
          </li>

          {/* //TODO: Hacked this, need to create my own downloader to prevent this */}
          {services.length <= 0
            ?
            <li data-disabled={true} >
              <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span>
            </li>
            :
            <ServicesDownload services={services} >
              <li > <FontAwesomeIcon icon={faFileExcel} /> <span>Export</span> </li>
            </ServicesDownload>
          }

          <li onClick={() => setShowFilter(true)} data-disabled={services.length === 0}>
            <FontAwesomeIcon icon={faFilter} /><span>Filters</span>
          </li>
        </PopupMenu>
      </div>


      <TimeFrameFilters onFilterChange={(asOfDate, untilDate) => {
        setAsOfDate(asOfDate)
        setUntilDate(untilDate)
      }} />


      <ServiceFilters showFilter={(showFilter && userGroups?.length > 0)} services={services} onClose={() => setShowFilter(false)} onApply={(data) => {
        setShowFilter(false)
        setServiceFilters(data)
      }} />

      <InLineForm isEnabled={showCreate} onClose={() => setShowCreate(false)}>
        <CreateServiceEntry userGroupId={userGroups ? userGroups[ 0 ]?._id : undefined} />
      </InLineForm>



      {(isLoading || filteredServiceStatus !== 'SUCCESS') ? <Loading /> : <ServiceTotals services={filteredServices} asOfDate={asOfDate} untilDate={untilDate} />}
      {(isLoading || filteredServiceStatus !== 'SUCCESS') ? <Loading /> : <ServiceTable services={filteredServices} />}
    </React.Fragment>
  )
}

