/**
 * @param {userGroupId} userGroupId
 * @returns {shoQuery}
 */
export const getOrgByUserGroupId = (userGroupId) => ({
  key: ['org', 'user-group', userGroupId],
  variables: {userGroupId},
  query: `
  query OrgByUserGroup($userGroupId: ID) {
    userGroup: userGroup(userGroupId: $userGroupId) {
      org {
        _id
        name
        supportEmail
        supportNumber
        userGroupsType
        userGroupPluralName
        userGroupSingleName
        targetRules {
          _id
          name
          description
          maxHours
          activitiesClusivity
          activities {
            _id
            name
          }
        }
        activities {
          _id
          name
          description
        }
        sponsors {
          _id
          businessName
          businessPhone
          businessUrl
        }
      }
    }
  }
  `
})

/**
 * @param {orgId} orgId
 * @returns {shoQuery}
 */
export const getOrgByOrgId = (orgId) => ({
  key: ['org', orgId],
  variables: {orgId},
  query: `
  query orgByOrgId($orgId: ID) {
    org(orgId: $orgId) {
      _id
      name
      zipCode
      supportEmail
      supportNumber
      defaultPassword
      userGroupsType
      userGroupPluralName
      userGroupSingleName
      rules
      admins {
        orgId
        administratorRole
        user {
          _id
          firstName
          lastName
          email
          userName
        }
      }
      targetTypes {
        _id
        name
        description
      }
      activities {
        _id
        name
        description
      }
      sponsors {
        _id
        businessName
        businessPhone
        businessUrl
      }
      targetRules {
        _id
        name
        description
        maxHours
        activitiesClusivity
        activities {
          _id
          description
          name
        }
       
      }
    }
  }
  
  `
})
/**
 * @param {orgId} orgId
 * @returns {shoQuery}
 */
export const getOrgWithUserGroups = (orgId) => ({
  key: ['org', 'user-groups', orgId],
  variables: {orgId},
  query: `
  query org($orgId: ID) {
    org(orgId: $orgId) {
      _id
      name
      targetTypes {
        _id
        name
        description
      }
      targetRules {
        _id
        name
        description
        maxHours
        activitiesClusivity
        activities {
          _id
          name
        }
      }
      userGroups {
        _id
        name
        memberRoles {
          memberRole 
          user {
            firstName
            lastName
            email
            tags
          }
        }
        tags
        inactiveTimestamp
        createTimestamp
      }
    }
  }
  `
})

/**
 * @param {UpdateOrgInfoInput} input
 */
export const updateOrgInfo = (input) => ({
  key: 'updateOrg',
  variables: { input },
  query: `
    mutation UpdateOrgInfo($input: UpdateOrgInfoInput!) {
      response: updateOrgInfo(input: $input) {
        org {
          _id
        }
      }
    }
  `
})

/** 
 * @param {ServiceHoursMetaDataInput} input
 */
export const getServiceHoursMetaData = (input) => ({
  key: ['org', 'meta', 'service', input.orgId, {...input}],
  variables: { input },
  query: `
  query ServiceHoursMetaData($input: ServiceHoursMetaDataInput) {
    meta: getServiceHoursMetaData(input: $input) {
      totalNumberOfUserGroups
      totalServiceHoursLogged
      paretoPrinciple
      serviceHoursByDate {
        serviceYear
        serviceMonth
        serviceWeek
        hours
      }
       serviceHoursByActivities{
        activityId
        name
        hours
        percentage
      }
      serviceHoursByUserGroups {
        userGroupId
        name
        hours
        percentage
      }
    }
  }
  `
})