import { Loading } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useUserGroup } from 'api'
import { useOrg } from 'api/hooks/org'
import { useNavigation } from 'navigation'
import React from 'react'
import styles from './userGroupHeader.module.css'
const s = styleSynthesizer(styles, 'UserGroupHeader')

function UserGroupHeader({ userGroupId }) {
  const nav = useNavigation()
  const { isLoading: orgIsLoading, org } = useOrg({ userGroupId })
  const { isLoading: userIsLoading, userGroup } = useUserGroup({ userGroupId })

  if (orgIsLoading || userIsLoading) return <Loading />

  return (
    <div className={s('container')}>
      <Link to={nav.org(org._id).url()}>
        <h2 className={s('orgName')}>{org.name} </h2>
      </Link>
      <p className={s('userGroupName')}>{userGroup.name}</p>
    </div>
  )
}

export default UserGroupHeader
