/**
 * @param {UpsertSponsorInput} input
 */
export const upsertSponsor = (input) => ({
  key: 'upsertSponsor',
  variables: { input },
  query: `
    mutation UpsertSponsor($input: UpsertSponsorInput!) {
      response: upsertSponsor(input: $input) {
        sponsor {
          _id
          businessName
          businessPhone
          businessUrl
        }
      }
    }
  `
})

/**
 * @param {RemoveSponsorInput} input
 */
export const removeSponsor = (input) => ({
  key: 'removeSponsor',
  variables: { input },
  query: `
    mutation RemoveSponsor($input: RemoveSponsorInput!) {
      response: removeSponsor(input: $input) {
        orgId, sponsorId
      }
    }
  `
})