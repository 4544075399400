import { queries } from "api"
import { shoFetch } from "api/fetch"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from 'react-toastify'

/**
 * @param {object} o
 * @param {userGroupId} o.userGroupId - return org information by user group id
 */
export function useServiceSummary({ userGroupId }) {
  const query = queries.getUserGroupServices(userGroupId, { limit: 5 })
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { services: /** @type {Service[]} */ (data?.services), ...response }
}

/**
 * @param {userGroupId} userGroupId - return org information by user group id
 * @param {asOfDate} asOfDate - date of the services to return
 * @param {number} limit - number of records to return
 * @param {string} untilDate - date of the services to return
 */
export function useServices(userGroupId, asOfDate, limit = 1000, untilDate) {
  const query = queries.getUserGroupServices(userGroupId, { limit, asOfDate, untilDate })
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { services: /** @type {Service[]} */ (data?.services), ...response }
}

const serviceDefaults = {
  serviceDate: '',
  description: '',
  hours: 0,
  performedByUserId: '',
  userGroupId: '',
  activityId: ''
}


/** @returns {import("react-query").UseMutationResult<Service, queryError, UpsertServiceInput, any>} */
export function useCreateService() {
  const queryClient = useQueryClient()

  return useMutation(
    async (formData) => {
      const service = { ...serviceDefaults, ...formData, hours: +formData.hours || 0 }
      return shoFetch(queries.upsertService(service))
    }, {

    mutationKey: 'createService',

    onSuccess: ({ response: { service } }, { userGroupId, hours }) => {
      toast.success(`${hours}hr of service added`)
      // Update the current service list in cache
      const query = queries.getUserGroupServices(userGroupId)
      queryClient.setQueriesData(['services', userGroupId], (old) => {
        if (old?.services) old.services.unshift({userGroupId,  ...service })
        return old
      })

      queryClient.setQueriesData([ 'userGroups', 'services' ], (old) => {
        const userGroup = old.userGroups?.find(ug => ug._id === userGroupId)
        userGroup.services.unshift(service)
        old.userGroups = [...old.userGroups]
        return old
      })

      //Refresh the targets from db to get updated metadata
      queryClient.invalidateQueries([ 'userGroups', 'targets' ])
      queryClient.invalidateQueries([ 'targets', userGroupId ])
      queryClient.invalidateQueries([ 'userGroup', userGroupId ])
    }
  }
  )
}

/** @returns {import("react-query").UseMutationResult<Service, queryError, UpsertServiceInput, any>} */
export function useUpdateService() {
  const queryClient = useQueryClient()

  return useMutation(
    async (serviceToEdit) => {
      const service = { ...serviceDefaults, ...serviceToEdit, hours: +serviceToEdit.hours || 0 }
      const { _id, userGroupId, performedByUserId, activityId, serviceDate, description, hours } = service
      return shoFetch(queries.upsertService({ _id, userGroupId, performedByUserId, activityId, serviceDate, description, hours }))
    }, {
    mutationKey: 'editService',

    onSuccess: async ({ response: { service } }, { userGroupId, serviceDate }) => {
      toast.success(`${serviceDate} service was updated`)

      queryClient.setQueriesData([ 'services', userGroupId ], (old) => {
        const index = old.services.findIndex(s => s._id === service._id)
        old.services[ index ] = service
        return old
      })

      queryClient.setQueriesData([ 'userGroups', 'services' ], (old) => {
        old?.userGroups.forEach(ug => {
          const index = ug?.services.findIndex(s => s._id === service._id)
          if (index === -1) return
          ug.services[ index ] = service
        })

        old.userGroups = [...old.userGroups]
        return old
      })

      queryClient.invalidateQueries([ 'userGroups', 'targets' ])
      queryClient.invalidateQueries([ 'targets', userGroupId ])
      queryClient.invalidateQueries([ 'userGroup', userGroupId ])
    }
  })
}

/** @returns {import("react-query").UseMutationResult<DeleteServiceResponse, queryError, DeleteServiceInput, any>} */
export function useDeleteService() {
  const queryClient = useQueryClient()

  return useMutation(
    (DeleteServiceInput) => shoFetch(queries.deleteService(DeleteServiceInput)), {

    mutationKey: 'deleteService',

    onSuccess: async ({ response: { _id: serviceId, userGroupId } }) => {


      toast.success(`Service was deleted`)
      queryClient.setQueriesData([ 'userGroups', 'services' ], (old) => {
        let found = false
        old.userGroups.forEach(ug => {
          const index = ug.services.findIndex(s => s._id === serviceId)
          if (index == -1) return
          found = true
          ug.services.splice(index, 1)
          ug.services = [ ...ug.services ]
        })
        if (!found) return old
        old.userGroups = [ ...old.userGroups ]
        return old

      })

      queryClient.invalidateQueries([ 'services', userGroupId ])
      queryClient.invalidateQueries([ 'userGroups', 'targets' ])
      queryClient.invalidateQueries([ 'targets', userGroupId ])
      queryClient.invalidateQueries([ 'userGroup', userGroupId ])
    }
  })
}
