import { faHiking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AdminProvider } from 'modules/admin/context/adminContext'
import OrgSettingsPage from 'modules/admin/scenes/OrgSettingsPage'
import LoadingOverlay from 'modules/common/components/LoadingOverlay'
import { ConfigProvider } from 'modules/common/context/configContext'
import UserDashboard from 'modules/user/page/UserDashboard'
import UserGroupServicesPage from 'modules/user/page/UserGroupServicesPage'
import UserGroupTargetsPage from 'modules/user/page/UserGroupTargetsPage'
import { Router } from '@reach/router'
import { useAuthentication } from 'api'
import Org from 'modules/admin/scenes/OrgPage'
import AuthPage from 'modules/auth/scenes/AuthPage'
import ErrorPage from 'modules/core/scenes/ErrorPage'
import Error from 'modules/core/scenes/ErrorPage'
import PageNotFound from 'modules/core/scenes/NotFoundPage'
import UserGroup from 'modules/user/page/UserGroupPage'
import User from 'modules/user/page/UserSettingsPage'
import 'normalize.css'
import React, { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useIsFetching } from 'react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import __ from './app.module.scss'
import FontAwesomeLoad from './FontAwesomeLoad'


FontAwesomeLoad()

export default function App() {
  const isFetching = useIsFetching()
  const { status } = useAuthentication()

  if (!(status === 'success' || status === 'error')) return (
    <div className={__.loading}>
      <p className={__.loadingIcon}><FontAwesomeIcon icon={faHiking} /></p>
      <p className={__.loadingMessage}>Give us a quick minute to get the server started</p>
    </div>
  )

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>

      <div className={__.ieMessage}>
        For the best experience please use the latest version of Chrome, Firefox, Safari, or Edge!
      </div>

      <div className={__.isFetching}>
        {isFetching > 0 && <FontAwesomeIcon className={__.spinner}
          icon='spinner' spin data-testid='loading-spinner' aria-live="polite" aria-label="saving" />}
      </div>
      <ConfigProvider>
        <AdminProvider>
          <div className={__.container}>
            <Suspense fallback={<LoadingOverlay message='Loading Page' />}>
              <Router>
                <AuthPage path='auth/*' />
                <UserGroup path='/userGroup/:userGroupId'>
                  <UserDashboard path='/' />
                  <UserGroupTargetsPage path='targets' />
                  <UserGroupServicesPage path='services' />
                </UserGroup>
                <Org path='/org/:orgId/*' />
                <OrgSettingsPage path='/org/:orgId/settings/*' />
                <User path='/userSettings' />
                <Error path='/error' />
                <PageNotFound default />
              </Router>
            </Suspense>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              limit={3}
              theme='light'
            />
          </div>
        </AdminProvider>
      </ConfigProvider>
    </ErrorBoundary>
  )
}
