import { useLocation as useReachLocation, useParams } from "@reach/router"
import lg from 'api/logger'
import navBuilder from 'navigation/builder'
import queryString from 'query-string'




/** @returns { navigationParameters } */
export function useLocation() {
  const location = useReachLocation() 
  const pathParams = useParams()

  return {
    pathParams,
    path: location.pathname
  }
}

/** @returns { urlParameters} */
export function usePathParameters() {
  return useParams() 
}

export function useNavigation() {
  return navBuilder
}


export const navigateToUnauthenticated = () => {
  const path = window.location.pathname
  if(path.startsWith('/auth')) return 
  navBuilder.login().navTo()
}

export const navigateToErrorPage = () => {
  navBuilder.error().navTo()
}

export function updateQueryString (newParams) {
  const params = queryString.parse(window.location.search)
  const search = queryString.stringify({...params, ...newParams})
  return `${window.location.pathname}?${search}`
}

export function removeQueryStringParameter (location, key) {
  let params = queryString.parse(location.search)
  delete params[key]
  const search = queryString.stringify(params)
  if(Object.keys(params).length >= 1) return `${location.pathname}?${search}`
  return location.pathname
}

