import { Loading } from 'modules/common/components'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import UserMenu from 'modules/common/components/UserMenu'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useLoggedInUser } from 'api'
import { useNavigation } from 'navigation'
import React from 'react'
import styles from './userNavigation.module.scss'
const s = styleSynthesizer(styles, 'UserNavigation')

export default function UserNavigation({ userGroupId }) {
  const nav = useNavigation()
  const { isLoading: userIsLoading, user } = useLoggedInUser()

  if(userIsLoading) return <Loading />

  const currentOrgId = user.userGroupRoles.find(ugr => ugr.userGroup._id === userGroupId)?.userGroup.org._id
  const orgId = user.managingOrgs.find(mo => mo.org._id === currentOrgId)?.org._id

  const isActiveLink = ({ isCurrent }) => {
    return isCurrent ? { className: s('activeLink') + s('navLink') } : { className: s('navLink') }
  }

  return (
    <nav className={s('container')}>
      <Link getProps={isActiveLink} to={nav.userGroup(userGroupId).url()}>
        Dashboard
      </Link>
      <Link getProps={isActiveLink} to={nav.userGroup(userGroupId).targets().url()}>
        Targets
      </Link>
      <Link getProps={isActiveLink} to={nav.userGroup(userGroupId).services().url()}>
        Services
      </Link>

      <span className={s('userGroupTerm')}><UserGroupsTerm /></span>

      <UserMenu orgId={orgId}  />
    </nav>
  )
}
