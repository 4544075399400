import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import __ from './styles.module.css'

const NotFoundPage = () => (
  <div className={__.page}>
    <FA className={__.bug} icon={['far', 'frown']}/> 
    <p className={__.message}>Oh No, the requested page was not found</p>
    <p className={__.close}>Please navigate to the <a href="http://ServiceHours.online">home page</a> and try again. If the problem continues contact your support</p>
  </div>
)

export default NotFoundPage