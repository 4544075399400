/**
 * @param {UpsertActivityInput} input
 */
export const upsertActivity = (input) => ({
  key: 'upsertActivity',
  variables: { input },
  query: `
    mutation UpsertActivity($input: UpsertActivityInput!) {
      response: upsertActivity(input: $input) {
        activity {
          _id
          name
          description
        }
      }
    }
  `
})

/**
 * @param {RemoveActivityInput} input
 */
export const removeActivity = (input) => ({
  key: 'removeActivity',
  variables: { input },
  query: `
    mutation RemoveActivity($input: RemoveActivityInput!) {
      response: removeActivity(input: $input) {
        orgId, activityId
      }
    }
  `
})