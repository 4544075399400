import OrgDetailPageWrapper from 'modules/admin/components/OrgDetailPageWrapper'
import UserGroupsTargets from 'modules/admin/containers/UserGroupsTargets'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import s from './orgTargetsPage.module.scss'
const ss = styleSynthesizer(s, 'OrgTargetsPage')

export default function OrgTargetsPage({ }) {
  return (
    <main className={ss('container')}>
      <OrgDetailPageWrapper title="Targets" Page={UserGroupsTargets} />
    </main>
  )
}