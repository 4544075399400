import { Button, InLineForm, NotificationMessage } from 'modules/common/components'
import { useRemoveTargetsForUserGroups } from 'api'
import React, { useEffect } from 'react'
import s from './targetsDeleteConfirmation.module.scss'

export default function TargetsDeleteConfirmation({ org, targets, onClose }) {
  const removeTargets = useRemoveTargetsForUserGroups()

  useEffect(() => { if(removeTargets.isSuccess) onClose() }, [ removeTargets.isSuccess ])

  function handleRemoveClick() {
    removeTargets.mutate({
      targetIds: targets.map(t => t._id),
      orgId: org._id
    })
  }

  return (
    <InLineForm isEnabled={true} onClose={onClose}>
      <div className={s.contentWrapper}>
        <NotificationMessage error={removeTargets.error} />
        <div className={s.content}>
          <p className={s.deleteTargets}>Are you sure you want to remove {targets.length} {targets.length > 1 ? 'targets' : 'target'}? </p> 
          <p>This can not be undone.</p>
          <div className={s.actionButtons}>
            <Button className={s.cancel} onClick={onClose} isBlocking={removeTargets.isLoading}> Cancel </Button>
            <Button className={s.delete} onClick={handleRemoveClick} isBlocking={removeTargets.isLoading}> Remove </Button>
          </div>
        </div>
      </div>
    </InLineForm>
  )
}