import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ServicesDownload from 'modules/admin/components/ServicesDownload'
import { Loading } from 'modules/common/components'
import TimeFrameFilters from 'modules/common/components/TimeFrameFilters'
import styleSynthesizer from 'modules/common/styles/styles'
import ServiceTable from 'modules/user/components/SerivceTable'
import { useServices } from 'api'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styles from './userGroupServicesPage.module.scss'
const s = styleSynthesizer(styles, 'UserServices')

export default function UserGroupServicesPage({ userGroupId }) {
  const [asOfDate, setAsOfDate] = useState(dayjs().subtract(6, 'months').format('YYYY-MM-DD'))
  const [untilDate, setUntilDate] = useState('9999-12-31')
  const { isLoading, services } = useServices(userGroupId, asOfDate, undefined, untilDate)

  return (
    <React.Fragment>
      <Helmet>
        <title>Services - Service Hours Online</title>
      </Helmet>
      
      <div className={s('container')}>
        <div className={s('actions')}>
          <TimeFrameFilters onFilterChange={( asOfDate, untilDate ) => { 
            setAsOfDate(asOfDate) 
            setUntilDate(untilDate)
            }} />
          <ServicesDownload services={services} >
            <button className={s( 'exportButton' )}><FontAwesomeIcon icon={faFileExcel} /> Export </button>
          </ServicesDownload>
        </div>
        {isLoading ? <Loading /> : <ServiceTable services={services} userGroupId={userGroupId} />}
      </div>
    </React.Fragment>
  )
}
