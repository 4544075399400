import { useEffect, useState } from "react"
import { Service, Target, UserGroupExtended } from "types/query"

//@ts-ignore
const ServiceWorker = require('workerize-loader!modules/common/workers/serviceWorker')
const serviceWorker = ServiceWorker()

//@ts-ignore
const TargetWorker = require('workerize-loader!modules/common/workers/targetWorker')
const targetWorker = TargetWorker()

export {
  serviceWorker
}

type ProcessStatus = 'UNKNOWN' | 'PROCESSING' | 'SUCCESS' | 'ERROR'
export function useFlattenUserGroupServices(userGroups: UserGroupExtended[]) {
  const [services, setServices] = useState([])
  const [status, setStatus] = useState<ProcessStatus>('UNKNOWN')

  useEffect(() => {
    setStatus('PROCESSING')
    serviceWorker.flattenUserGroupServices(userGroups).then(data => {
      setStatus('SUCCESS')
      setServices(data)
    })
  }, [userGroups])

  return {
    status,
    services
  }
}

export function useServiceFilters(services: Service[], filters: ServiceFilters) {
  const [filteredServices, setFilteredServices] = useState([])
  const [status, setStatus] = useState<ProcessStatus>('UNKNOWN')

  useEffect(() => {
    setStatus('PROCESSING')
    serviceWorker.filterServices(services, filters).then(data => {
      setStatus('SUCCESS')
      setFilteredServices(data)
    })
  }, [services, filters])

  return {
    status,
    services: filteredServices
  }
}

export function useServiceTotals(services: Service[])  {
  const [serviceTotals, setServiceTotals] = useState<ServiceTotals>()
  const [status, setStatus] = useState<ProcessStatus>('UNKNOWN')

  useEffect(() => {
    setStatus('PROCESSING')
    serviceWorker.calculateServiceTotals(services).then(data => {
      setStatus('SUCCESS')
      setServiceTotals(data)
    })

  }, [services])

  return {
    status,
    serviceTotals
  }
}

export function useTargetFilters(targets: Target[], filters: TargetFilters) {
  const [filteredTargets, setFilteredTargets] = useState([])
  const [status, setStatus] = useState<ProcessStatus>('UNKNOWN')

  useEffect(() => {
    setStatus('PROCESSING')
    targetWorker.filterTargets(targets, filters).then(data => {
      setStatus('SUCCESS')
      setFilteredTargets(data)
    })
  }, [targets, filters])

  return {
    status,
    targets: filteredTargets
  }

}