import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import __ from 'modules/common/components/Forms/styles/inputs.module.css'

const SelectInput = ({ label, value, options=[], optionId='_id', optionName='name', emptyOption=true, className='', onChange, ...otherProps }) => {
  return (
    <div className={`${className} ${__.inputContainer} input-container` }>
      <label className={`${__.label} label`}>
        {label && <FA className={`${__.caretRight} caret-right`}  icon='caret-right'/> }
        {label}
        <div className={__.inputWrapper}>
          <select className={`${__.dropDown} dropDown`} value={value || ''} onChange={onChange} {...otherProps}>
            { emptyOption && <option value="" disabled></option> }
            {options.map((option) => 
              <option key={option[optionId]} className={__.dropDownValue} value={option[optionId]}>{option[optionName]}</option>
            )}
          </select>
        </div>
      </label>
    </div>
  )
}

export default SelectInput