import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCheckSquare, faFrown, faSadTear, faSquare } from '@fortawesome/free-regular-svg-icons'
import {
  faBars,
  faBullseye,
  faCaretRight,
  faChartLine,
  faCheckSquare as faCheckSquareSolid,
  faChevronRight,
  faCog,
  faCopy,
  faEllipsisV,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileExcel,
  faGlobe,
  faHashtag,
  faPencilAlt,
  faPlus,
  faSignOutAlt,
  faSpinner,
  faTimes,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faUserCog,
  faUserPlus,
  faUsers,
  faUserSlash,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons'

function load() {
  library.add(
    faTrashAlt,
    faSitemap,
    faUserPlus,
    faEllipsisV,
    faCaretRight,
    faCog,
    faUserCog,
    faSpinner,
    faPencilAlt,
    faTimes,
    faEye,
    faEyeSlash,
    faGlobe,
    faChevronRight,
    faBars,
    faSignOutAlt,
    faUsers,
    faBullseye,
    faChartLine,
    faCheckSquare,
    faCheckCircle,
    faCheckSquareSolid,
    faPlus,
    faCopy,
    faSquare,
    faFileExcel,
    faUserSlash,
    faHashtag,
    faToggleOn,
    faToggleOff,
    faExclamationTriangle,
    faFrown,
    faSadTear
  )
}

export default load
