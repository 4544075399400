import React, { useRef, useState } from 'react'
import dayjs from 'dayjs'
import styles from './timeFrameFilters.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { Button, Input } from '../Forms'
import { useDetectOutsideClick } from 'modules/common/hooks/useDetectOutsideClick'
const s = styleSynthesizer(styles, 'TimeFrameFilters')

const TODAY = 'TODAY'
const SIX_MONTHS = '6-MONTHS'
const ONE_YEAR = '1-YEAR'
const TWO_YEARS = '2-YEARS'
const THREE_YEARS = '3-YEARS'
const ALL_TIME = 'ALL'
const CUSTOM = 'CUSTOM'

const filters = {
  [ TODAY ]: () => dayjs(),
  [ SIX_MONTHS ]: () => dayjs().subtract(6, 'months'),
  [ ONE_YEAR ]: () => dayjs().subtract(1, 'year'),
  [ TWO_YEARS ]: () => dayjs().subtract(2, 'year'),
  [ THREE_YEARS ]: () => dayjs().subtract(3, 'year'),
  [ CUSTOM ]: () => dayjs('12/31/9999'),
}
export const FILTERS = { TODAY, SIX_MONTHS, ONE_YEAR, TWO_YEARS, CUSTOM }

export default function TimeFrameFilters({ onFilterChange, showToday = false, activeFilter = SIX_MONTHS }) {
  const menuRef = useRef(null)
  useDetectOutsideClick(menuRef, () => setShowCustomDate(false))
  const [ asOfDate, setAsOfDate ] = useState(filters[ activeFilter ]().format('YYYY-MM-DD'))
  const [ untilDate, setUntilDate ] = useState(undefined)
  const [ showCustomDate, setShowCustomDate ] = useState(false)
  const [ activeButton, setActiveButton] = useState(activeFilter)

  function handleFilterClick(filter) {
    const asOfDate = filters[ filter ]().format('YYYY-MM-DD')
    setAsOfDate(asOfDate)
    onFilterChange(asOfDate, '12/31/9999')
    setShowCustomDate(false)
    setActiveButton(filter)
  }

  function handleCustomFilter(asOfDate, untilDate = '9999-12-31') {
    setAsOfDate(asOfDate)
    setUntilDate(untilDate)
    setShowCustomDate(false)
    onFilterChange(asOfDate, untilDate)
    setActiveButton(CUSTOM)
  }

  function style(filter) {
    if (activeButton === filter) return `${s('button')} ${s('activeButton')}`
    return s('button')
  }

  return (
    <aside ref={menuRef} className={s('filters')}>
      <button className={style(CUSTOM)} onClick={() => setShowCustomDate(true)}> Custom </button>
      {showCustomDate && <CustomDateSelector
        asOfDate={asOfDate}
        untilDate={untilDate}
        onUpdate={handleCustomFilter} />}
      <button className={style(THREE_YEARS)} onClick={() => handleFilterClick(THREE_YEARS)}> 3 Years </button>
      <button className={style(TWO_YEARS)} onClick={() => handleFilterClick(TWO_YEARS)}> 2 Years </button>
      <button className={style(ONE_YEAR)} onClick={() => handleFilterClick(ONE_YEAR)}> 1 Year </button>
      <button className={style(SIX_MONTHS)} onClick={() => handleFilterClick(SIX_MONTHS)}> 6 Months </button>
      {showToday && <button className={style(TODAY)} onClick={() => handleFilterClick(TODAY)}> Today </button>}
    </aside>
  )
}

function CustomDateSelector({ asOfDate: inAsOfDate, untilDate: inUntilDate, onUpdate }) {
  const [ asOfDate, setAsOfDate ] = useState(inAsOfDate)
  const [ untilDate, setUntilDate ] = useState(inUntilDate)
  return (
    <div className={s('customDateForm')}>
      <form>
        <Input autoFocus
          className={s('asOfDateSelect')}
          label='From Date'
          type='date'
          value={asOfDate}
          onChange={(e) => setAsOfDate(e.target.value)} />
        <Input
          className={s('untilDateSelect')}
          label='To Date'
          type='date'
          value={untilDate}
          onChange={(e) => setUntilDate(e.target.value)} />
        <Button className={s('applyButton')} onClick={() => onUpdate(asOfDate, untilDate)}>Apply</Button>
      </form>
    </div>
  )
}