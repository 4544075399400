import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import { Button, Dropdown, Input, useFormManagement } from '../Forms'
import s from './targetFilters.module.scss'
const ss = styleSynthesizer(s, 'TargetFilters')

const statusOptions = [
  { _id: 'ALL', name: 'All' },
  { _id: 'COMPLETE', name: 'Complete' }, 
  { _id: 'IN-COMPLETE', name: 'In Complete' }
]
export default function TargetFilters({ showFilter, onClose, onApply }:
  { showFilter: boolean, onClose: () => void, onApply: (FilterContent: TargetFilters) => void }) {

  const form = useFormManagement<TargetFilters>({}, (data) => onApply(data))


  if (!showFilter) return null

  return (
    <div className={ss('container')}>
      <button type='button' className={s.closeButton} onClick={onClose}>
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <div className={s.filterMenu}>
        <div className={s.filterGroup}>
          <h3 className={s.filterTitle}>Status</h3>
          <Dropdown
            label=''
            name='status'
            options={ statusOptions}
            emptyOption={false}
            value={form.data.status}
            onChange={form.onChange}
          />
        </div>
        <div className={s.filterGroup}>
          <h3 className={s.filterTitle}>Target Hours</h3>
          <Input
            label='Greater than or equal to'
            type='number'
            name='targetGreaterThan'
            value={form.data.targetGreaterThan}
            onChange={form.onChange}
          />
          <Input
            label='Less than or equal to'
            type='number'
            name='targetLessThan'
            value={form.data.targetLessThan}
            onChange={form.onChange}
          />
        </div>
        <div className={s.filterGroup}>
          <h3 className={s.filterTitle}>Percent Complete</h3>
          <Input
            label='Greater than or equal to'
            type='number'
            name='percentCompleteGreaterThan'
            value={form.data.percentCompleteGreaterThan}
            onChange={form.onChange}
          />
          <Input
            label='Less than or equal to'
            type='number'
            name='percentCompleteLessThan'
            value={form.data.percentCompleteLessThan}
            onChange={form.onChange}
          />
        </div>

      </div>
      <div className={s.buttonContainer}>
        <Button className={s.applyButton} onClick={form.onSubmit} >Apply Filter(s)</Button>
      </div>
    </div>
  )
}
