import ProgressBar from 'modules/common/components/ProgressBar'
import React from 'react'
import __ from './targetProgress.module.css'


const TargetProgress = ({ target }) => {
  const { description, hours, meta: { totalHours, percentComplete } } = target

  return (
    <section className={__.details}>
      <ProgressBar className={__.progressBar} label={description} percent={percentComplete} />
      <div className={__.hours} data-testid='target-progress-totals'>
        <p className={__.total}>{totalHours}</p>
        {/* <hr/> */}
        <p className={__.requiredHours}>{hours}hrs</p>
      </div>
    </section>
  )
}

export default TargetProgress