/**
 * @param {requestId} requestId
 * @returns {shoQuery} 
 */
 export const getRequestById = (requestId) => ({
  key: ['request', {requestId}],
  variables: { requestId },
  query: `
  query requestByRequestId($requestId: ID) {
    request: requestByRequestId(requestId: $requestId) {
      _id
      requestType
      requestData {
        ... on UserInviteRequest {
          invitee {
            _id
            userName
            email
            userStatus
          }
          inviter {
            _id
            firstName
            lastName
            userName
            email
          }
          userGroup {
            _id
            name
          }
          org {
            _id
            name
            zipCode
            supportEmail
            supportNumber
          }
        }
      }
    }
  }
`
})

/**
 * @param {AcceptInviteRequestInput} input
 * @returns {shoQuery} 
 */
export const acceptInviteRequest = (input) => ({
  key: ['request-accept'],
  variables: { input },
  query: `
  mutation acceptInviteRequestInput($input: AcceptInviteRequestInput) {
    response: acceptInviteRequest(input:$input) {
      user {
        _id
        firstName
        lastName
        userName
        userStatus
      }
    }
  }
  `
})

/**
 * @param {RejectInviteRequestInput} input
 * @returns {shoQuery} 
 */
export const rejectInviteRequest = (input) => ({
  key: ['request-reject'],
  variables: { input },
  query: `
  mutation rejectInviteRequest($input: RejectInviteRequestInput) {
    response: rejectInviteRequest(input: $input) {
      user {
        _id
        firstName
        lastName
        userName
        userStatus
      }
    }
  }
  `
})

/**
 * @param {RequestPasswordResetInput} input
 * @returns {shoQuery} 
 */
export const requestPasswordReset = (input) => ({
  key: ['request-password-reset'],
  variables: { input },
  query: `
  mutation requestPasswordReset($input: RequestPasswordResetInput) {
    response: requestPasswordReset(input: $input) {
      requestId
    }
  }
  `
})


/**
 * @param {ProcessPasswordResetRequestInput} input
 * @returns {shoQuery} 
 */
export const processPasswordResetRequest = (input) => ({
  key: ['process-password-reset-request'],
  variables: { input },
  query: `
      mutation processPasswordResetRequest($input: ProcessPasswordResetRequestInput) {
        response: processPasswordResetRequest(input: $input) {
          requestId
        }
      }
  `
})