import { queries, shoFetch } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

/** @returns {import("react-query").UseMutationResult<UpsertTargetTypeResponse, queryError, UpsertTargetTypeInput, any>} */
export function useUpsertTargetType() {
  const queryClient = useQueryClient()

  return useMutation(
    async (targetType) => {
      return shoFetch(queries.upsertTargetType(targetType))
    }, {

    mutationKey: 'updateTargetTypes',

    onSuccess: async ({ response: { targetType } }, { orgId, _id, name }) => {
      _id ? toast.success(`Target ${name} updated`) : toast.success(`Target ${name} created`)
      queryClient.setQueriesData([ 'org' ], (old) => {
        if (old.org?._id === orgId && old.org?.targetTypes && targetType) {
          const index = old.org.targetTypes.findIndex(s => s._id === targetType._id)
          index === -1 ? old.org.targetTypes.push(targetType) : old.org.targetTypes[ index ] = targetType
        }
        return { ...old }
      })
    },

  })
}

/** @returns {import("react-query").UseMutationResult<RemoveTargetTypeResponse, queryError, RemoveTargetTypeInput, any>} */
export function useRemoveTargetType() {
  const queryClient = useQueryClient()

  return useMutation(
    async (targetType) => {
      return shoFetch(queries.removeTargetType(targetType))
    }, {

    mutationKey: 'removeTargetType',

    onSuccess: async ({ response: { orgId, targetTypeId } }) => {
      toast.success('Target Removed')

      queryClient.setQueriesData([ 'org' ], (old) => {
        if (old.org?._id === orgId && old.org?.targetTypes) {
          old.org.targetTypes = old.org.targetTypes.filter(t => t._id !== targetTypeId)
        }
        return { ...old }
      })
    },


  })
}