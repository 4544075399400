import { Button, InLineForm, NotificationMessage, useFormManagement } from 'modules/common/components'
import { useUpdateTargetsForUserGroups } from 'api'
import React from 'react'
import TargetFormFields from '../TargetFormFields'
import s from '../userGroupsTargetManagement.module.scss'

export default function TargetEditForm({ org, targets, onClose }) {
  const editTargets = useUpdateTargetsForUserGroups()
  const { matches, target } = determineDefaultValues(targets)
  const form = useFormManagement({ 
    ...target, 
    orgId: org._id, 
    targetIds: targets.map(t => t._id), 
    targetTypeId: target?.type?._id,
    targetRuleIds: target.rules?.map(r => r._id)
  }, editTargets.mutate)

  return (
    <InLineForm isEnabled={true} onClose={onClose}>
      <NotificationMessage error={editTargets.error} />
      <form className={s.form} onSubmit={form.onSubmit}>
        {matches || <p className={s.overwritten}>Selected target do not have the same values, and will be overwritten on save!</p>}
        <TargetFormFields form={form} org={org} />
        <Button className={s.saveButton} type="submit"  isDirty={form.isDirty} isBlocking={editTargets.isLoading}>
          Update {targets.length} target(s)
        </Button>
      </form>
    </InLineForm>
  )
}

/**
 * Looping thru the targets, identify the defaults, along with an indicator if they all match
 * @param {Target []} targets 
 * @returns { {matches: boolean, target: Target} }
 */
function determineDefaultValues(targets) {
  const target = { ...targets[ 0 ] }
  let matches = true

  targets.forEach(t => {
    checkMatch('startDate')
    checkMatch('endDate')
    checkMatch('hours')
    checkMatch('description')
    if (target.type._id !== t.type._id) {
      target.type._id = ''
      matches = false
    }

    function checkMatch(key) {
      if (target[ key ] === t[ key ]) return
      target[ key ] = ''
      matches = false
    }
  })

  return { matches, target }

}
