import ActivitiesManagement from 'modules/admin/containers/orgSettings/ActivitiesManagement'
import OrgInfoForm from 'modules/admin/containers/orgSettings/OrgInfoForm'
import SponsorsManagement from 'modules/admin/containers/orgSettings/SponsorsManagement'
import TargetRulesManagement from 'modules/admin/containers/orgSettings/TargetRulesManagement'
import TargetsManagement from 'modules/admin/containers/orgSettings/TargetsManagement'
import { Button, Loading, Navbar } from 'modules/common/components'
import ComponentError from 'modules/common/components/ComponentError'
import { useOrgInfo } from 'api'
import React, { useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import s from './orgSettingsPage.module.scss'
import { faArrowCircleLeft } from '@fortawesome/fontawesome-free-solid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleDown, faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from '@reach/router'
import { useNavigation } from 'navigation'


export default function OrgSettingsPage({ orgId }) {
  const { isLoading, org } = useOrgInfo(orgId)
  const nav = useNavigation()
  const generalHeaderRef = useRef(null)
  const sponsorsHeaderRef = useRef(null)
  const activitiesHeaderRef = useRef(null)
  const targetTypesHeaderRef = useRef(null)
  const targetRulesHeaderRef = useRef(null)

  if (isLoading) return <Loading />

  return (
    <div className={s.container}>
      <Helmet>
        <title>{org?.name} Settings</title>
      </Helmet>

      <header className={s.header}>
        <Navbar orgId={orgId} />
        <ul className={s.headerList}>
          <li onClick={() => generalHeaderRef.current.scrollIntoView({ behavior: 'smooth' })}>General</li>
          <li onClick={() => sponsorsHeaderRef.current.scrollIntoView({ behavior: 'smooth' })}>Sponsors</li>
          <li onClick={() => activitiesHeaderRef.current.scrollIntoView({ behavior: 'smooth' })}>Activities</li>
          <li onClick={() => targetTypesHeaderRef.current.scrollIntoView({ behavior: 'smooth' })}>Target Types</li>
          <li onClick={() => targetRulesHeaderRef.current.scrollIntoView()}>Target Rules</li>
        </ul>
      </header>
      <div className={s.returnContainer}>
        <Link className={s.returnLink} to={nav.org(org._id).url()}>
          <Button className={s.returnButton} >
            <FontAwesomeIcon icon={faArrowCircleLeft} />Return
          </Button>
        </Link>
      </div>

      <main className={s.content}>
        <ErrorBoundary fallback={<ComponentError componentName='Contact Information' />}>
          <article className={s.orgInfo}>
            <h2 ref={generalHeaderRef} className={s.titles}>General</h2>
            <OrgInfoForm orgInfo={org} />
          </article>
        </ErrorBoundary>

        <ErrorBoundary fallback={<ComponentError componentName='Sponsors' />}>
          <article className={s.sponsors}>
            <h2 ref={sponsorsHeaderRef} className={s.titles}>Sponsors</h2>
            {/* <p>Comprehensive list of activities your organization allows users to log service to.</p> */}
            <SponsorsManagement sponsors={org.sponsors} orgId={orgId} />
          </article>
        </ErrorBoundary>

        <ErrorBoundary fallback={<ComponentError componentName='Activities' />}>
          <article className={s.activities}>
            <h2 ref={activitiesHeaderRef} className={s.titles}>Activities</h2>
            {/* <p>Comprehensive list of activities your organization allows users to log service to.</p> */}
            <ActivitiesManagement activities={org.activities} orgId={orgId} />
          </article>
        </ErrorBoundary>

        <ErrorBoundary fallback={<ComponentError componentName='Target Types' />}>
          <article className={s.targets}>
            <h2 ref={targetTypesHeaderRef} className={s.titles}>Target Types</h2>
            {/* <p>Target Types provide the ability to group targets into different categories.</p> */}
            <TargetsManagement targets={org.targetTypes} orgId={orgId} />
          </article>
        </ErrorBoundary>

        <ErrorBoundary fallback={<ComponentError componentName='Target Rules' />}>
          <article className={s.targetRules}>
            <h2 ref={targetRulesHeaderRef} className={s.titles}>Target Rules</h2>
            {/* <p>Target Types provide the ability to group targets into different categories.</p> */}
            <TargetRulesManagement targetRules={org.targetRules} orgId={orgId} activities={org.activities} />
          </article>
        </ErrorBoundary>


      </main>

    </div>
  )
}
