import { faHiking } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Loading, Navbar } from 'modules/common/components'
import { useNavigation } from 'navigation'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import s from './home.module.scss'



export default function Home() {
  const nav = useNavigation()

  function navigateToLogin() {
    nav.login().navTo()
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Service Hours Online</title>
      </Helmet>

      <main className={s.container}>
        <section className={s.content}>
          <Navbar />
          <hr />
          <p className={s.message}>
            Service Hours Online makes the recording and tracking of service hours for organizations easy.
          </p>
          <p className={s.action}>
            Already using Service Hours Online?
            <Button className={s.login} onClick={navigateToLogin}>Login</Button>
          </p>
          <p className={s.contact}>
            Interested in how you can use Service Hours Online within your organization?  <a href="https://form.jotform.com/212435905317150" >Contact Us!</a>
          </p>
        </section>
      </main> 
    </React.Fragment>
  )
}
