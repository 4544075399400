import { Button, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import Checkbox from 'modules/common/components/Forms/Checkbox'
import { useCreateUserGroup } from 'api'
import React, { useEffect, useRef } from 'react'
import s from './addUserGroupForm.module.scss'
import { useConfigContext } from 'modules/common/context/configContext'

export default function AddUserGroupForm({ orgId, show, onClose, onSuccess }) {
  const createUserGroup = useCreateUserGroup()
  const nameRef = useRef(null)
  const form = useFormManagement({ orgId, name: '', firstName: '', lastName: '', sendWelcomeEmail: false, email: '' }, createUserGroup.mutate)
  const { userGroupSingularName} = useConfigContext()

  useEffect(() => {
    if (createUserGroup.isSuccess) {
      nameRef?.current?.focus()
      form.resetState({...form.data, name: '', firstName: '', lastName: '', email: ''})
      onSuccess(createUserGroup.data.response.userGroup)
    }
  }, [createUserGroup.isSuccess])

  return (
    <InLineForm isEnabled={show} onClose={onClose}>
      <NotificationMessage error={createUserGroup.error} />
      <form className={s.form} onSubmit={form.onSubmit} >

        <Input
          label={userGroupSingularName + ' Name'}
          ref={nameRef}
          autoComplete="off"
          type='text'
          name='name'
          value={form.data.name}
          onChange={form.onChange} />

        <Input
          label='First Name'
          autoComplete="off"
          placeholder='(optional)'
          type='text'
          name='firstName'
          value={form.data.firstName}
          onChange={form.onChange} />

        <Input
          label='Last Name'
          autoComplete="off"
          placeholder='(optional)'
          type='text'
          name='lastName'
          value={form.data.lastName}
          onChange={form.onChange} />

        <Input
          label='Email Address'
          autoComplete="off"
          type='email'
          name='email'
          value={form.data.email}
          onChange={form.onChange} />

        <Checkbox
          label='Send Welcome Email'
          name='sendWelcomeEmail'
          value={form.data.sendWelcomeEmail}
          onChange={form.onChange} />

        <Button className={s.saveButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={createUserGroup.isLoading}>
        </Button>
      </form>
    </InLineForm>
  )
}

