import { shoFetch, queries } from 'api'
import { useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'

/** @returns {import("react-query").UseMutationResult<UpsertTargetRuleResponse, queryError, UpsertTargetRuleInput, any>} */
export function useUpsertTargetRule() {
  const queryClient = useQueryClient()

  return useMutation(
    async (targetRule) => {
      targetRule.maxHours = +targetRule.maxHours
      return shoFetch(queries.upsertTargetRule(targetRule))
    }, {

    mutationKey: 'updateTargetRule', 

    onSuccess: async ({response: {targetRule}}, {orgId, _id, name}) => {
      _id ? toast.success(`Target Rule ${name} updated`) : toast.success(`Target Rule ${name} created`)
      
      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org._id === orgId && old.org.targetRules && targetRule) {
          const index = old.org.targetRules.findIndex(s => s._id === targetRule._id)
          index === -1 ? old.org.targetRules.push(targetRule) : old.org.targetRules[index] = targetRule
        }
        return old
      })

      /** Should not need to update the targets, because this is a create, so no existing targets will have it */
    }
  })
}

/** @returns {import("react-query").UseMutationResult<RemoveTargetRuleResponse, queryError, RemoveTargetRuleInput, any>} */
export function useRemoveTargetRule() {
  const queryClient = useQueryClient()

  return useMutation(
    async (removeTargetRuleInput) => {
      return shoFetch(queries.removeTargetRule(removeTargetRuleInput))
    }, {

    mutationKey: 'removeTargetRule', 

    onSuccess: async ({response: {orgId, targetRuleId}} ) => {
      toast.success('Target Removed')

      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org._id === orgId && old.org.targetRules) {
          old.org.targetRules = old.org.targetRules.filter(t => t._id !== targetRuleId)
        }
        return old
      })

      /** 
       * Should not need to update targets, because the target rule is a soft delete and the original rule is still
       * valid for a given target until it has been removed
       */
    }
  })
}