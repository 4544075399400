import UserMenu from 'modules/common/components/UserMenu'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useNavigation } from 'navigation'
import React from 'react'
import styles from './adminUserGroupNavBar.module.scss'
const s = styleSynthesizer(styles, 'AdminUserGroupNavBar')

export default function AdminUserGroupNavBar({ orgId }) {
  const nav = useNavigation()

  const isActiveLink = ({ isCurrent }) => {
    return isCurrent ? { className: s('activeLink') + s('navLink') } : { className: s('navLink') }
  }

  return (
    <nav className={s('container')}>
      <Link getProps={isActiveLink} className={s('navLink')} to={nav.org(orgId).url()}>
        Dashboard
      </Link>
      <Link getProps={isActiveLink} className={s('navLink')} to={nav.org(orgId).summary().url()}>
        Summary
      </Link>
      <Link getProps={isActiveLink} className={s('navLink')} to={nav.org(orgId).members().url()}>
        Members
      </Link>
      <Link getProps={isActiveLink} className={s('navLink')} to={nav.org(orgId).targets().url()}>
        Targets
      </Link>
      <Link getProps={isActiveLink} className={s('navLink')} to={nav.org(orgId).services().url()}>
        Services
      </Link>


        <UserMenu orgId={orgId} />
    </nav>
  )
}
