import React from 'react'
import s from './checkbox.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faToggleOff, faToggleOn } from '@fortawesome/free-solid-svg-icons'
const ss = styleSynthesizer(s, 'Checkbox')

export default function Checkbox({ label = null, labelBefore = null, value, name, onChange }) {

  function handleClick() {
    const e = {
      preventDefault: () => { },
      target: { value: !value, name }
    }
    onChange(e)
  }

  return (
    <button className={ss('buttonToggle')} onClick={handleClick} name={name} type='button'>
      {labelBefore && <span className={s('labelBefore')}>{labelBefore}</span>}
      {value
        ? <FontAwesomeIcon className={ss('toggleOn')} icon={faToggleOn} />
        : <FontAwesomeIcon className={ss('toggleOff')} icon={faToggleOff} />}

      {label && <span className={ss('label')}>{label}</span>}
    </button>
  )
}