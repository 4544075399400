import { Link } from '@reach/router'
import { useOrg, useUserGroup } from 'api'
import { getUsersDisplayName, sortActivitiesByName } from 'api/selectors'
import { Loading, NotificationMessage } from 'modules/common/components'
import { Button, Dropdown, Input } from 'modules/common/components/Forms'
import { useConfigContext } from 'modules/common/context/configContext'
import useIsServiceMutationDisabled from 'modules/common/hooks/useIsServiceMutationDisabled'
import styleSynthesizer from 'modules/common/styles/styles'
import { useNavigation } from 'navigation'
import React from 'react'
import s from './serviceForm.module.scss'
const ss = styleSynthesizer(s, 'ServiceForm')

/**
 * @param {object} p
 * @param {userGroupId} p.userGroupId
 * @param {import('modules/common/components/Forms/useFormManagement').useInputFormReturn<Service>} p.form 
 * @param {queryError} p.error 
 * @param {boolean} p.isBlocking - indicator to know when the form is updating
 */
function ServiceEntry({ userGroupId, form: inForm, error, isBlocking }, ref) {
  const nav = useNavigation()
  const { showPerformedBy } = useConfigContext()
  const { isServiceMutationDisabled: isDisabled } = useIsServiceMutationDisabled(userGroupId)

  const { isLoading: orgIsLoading, org } = useOrg({ userGroupId })
  const { isLoading: userIsLoading, userGroup } = useUserGroup({ userGroupId })

  if (orgIsLoading || userIsLoading) return <Loading />



  const userGroupMembers = userGroup.memberRoles.map(ugm => ({ _id: ugm.user._id, name: getUsersDisplayName(ugm.user) }))
  const { data, isDirty, ...form } = inForm

  return (
    <form className={`${ss('form')} ${!showPerformedBy && ss('performedByHidden')} ${isDisabled ? s.disabledForm : ''}`} onSubmit={form.onSubmit}>
      <NotificationMessage error={error} />

      <Input autoFocus
        className={ss('serviceDate')}
        label='Service Date'
        type='date'
        name='serviceDate'
        disabled={isDisabled}
        value={data.serviceDate}
        onChange={form.onChange} />

      {showPerformedBy && <>        
      <Dropdown label='Performed By'
        className={ss('performedBy')}
        name='performedByUserId'
        options={userGroupMembers}
        optionId='_id'
        emptyOption={true}
        value={data.performedByUserId}
        onChange={form.onChange} />
        
        {!data._id && <p className={ss('performedByMessage')}>Add additional members in <Link to={nav.settings().url()}>account settings</Link></p>}
      </>

      }

      <Dropdown label='Activity'
        className={ss('activity')}
        name='activityId'
        options={sortActivitiesByName(org.activities)}
        optionId='_id'
        optionName='name'
        disabled={isDisabled}
        value={data.activityId}
        onChange={form.onChange} />

      <Input label='Hours'
        className={ss('hours')}
        type='number'
        name='hours'
        step='0.25'
        autoComplete="off"
        ref={ref}
        value={data.hours}
        disabled={isDisabled}
        onChange={form.onChange} />

      <Input label='Description'
        className={ss('description')}
        type='text'
        name='description'
        autoComplete="off"
        rows="2"
        value={data.description}
        disabled={isDisabled}
        onChange={form.onChange} />

      <div className={ss('actions')}>
        <Button className={ss('submitButton')} disabled={isDisabled} type="submit" onClick={form.onSubmit} isDirty={isDirty} isBlocking={isBlocking}>SUBMIT</Button>
      </div>
    </form>
  )
}

export default React.forwardRef(ServiceEntry)