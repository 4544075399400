import { useRequestPasswordReset } from 'api'
import __ from 'modules/auth/styles/auth.module.css'
import { NotificationMessage } from 'modules/common/components'
import { Button, Input, useFormManagement } from 'modules/common/components/Forms'
import React from 'react'
import { Helmet } from 'react-helmet'

function PasswordResetSentMessage(email) {
  return (
    <div className={__.emailSentMessage}>
      <p className={__.mainMessage}>
        Password reset email sent to <span className={__.messageEmail}>{email}</span>!
      </p>
      <p className={__.subMessage}>Please close this window and check your email for further instructions.  <br></br><br></br><i>(Don't forget to check your junk folder)</i></p>
    </div>
  )
}

export default function PasswordResetRequest() {
  const { isSuccess, isLoading, error, mutate } = useRequestPasswordReset()
  const { isDirty, data, ...form } = useFormManagement({ email: '' }, mutate)

  if (isSuccess) return PasswordResetSentMessage(data.email)

  return (
    <React.Fragment>
      <Helmet>
        <title>Password Reset - Service Hours Online</title>
      </Helmet>
      <form className={__.formFields} onSubmit={form.onSubmit}>
        <NotificationMessage error={error} />
        <h1 className={__.header}>Reset Password</h1>

        <Input label='Email Address' type='email' name='email' value={data.email} onChange={form.onChange} />

        <div className={__.actions}>
          <Button className={__.button} onClick={form.onSubmit} isDirty={isDirty} isBlocking={isLoading} type='submit'>
            Reset Password
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}
