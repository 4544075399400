import React, { useState, useEffect, useRef } from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import __ from './notificationMessage.module.css'

/**
 * @param {object} p 
 * @param {object} p.error
 * @param {string} p.error.message - the error message to display
 * @param {React.Component} [p.children] - child react component
 * @param {string} [p.className] - class name applied to the outer component
 */
const NotificationMessage = ({ error, children, className='' }) => {
  const [{ hide }, setHide] = useState({hide: false})
  const ref = useRef()

  useEffect(() => {
    setHide({hide: false})
    // @ts-ignore
    if(ref.current) ref.current.focus()
  }, [error]); // eslint-disable-line

  const handleHide = () => {
    setHide({hide: true})
  }

  const message = error && error.message
  if(!message && !children) return null
  if(hide) return null

  return (
    <div tabIndex={-1} ref={ref} className={`${__.notification} ${className} NotificationMessage--container`} aria-live="polite" aria-label="Error occurred while processing">
      <p tabIndex={1} className={__.message} data-testid="notification-message">{message}</p>
      
      {/* <button  type="button" className={__.hide} onClick={handleHide} aria-label="close message">
        <FA icon="times" />
      </button> */}

      <div className={__.children}>
        {children}
      </div>
    </div>
  )
}

export default NotificationMessage