import OrgDetailPageWrapper from 'modules/admin/components/OrgDetailPageWrapper'
import userGroupsServices from 'modules/admin/containers/UserGroupsServices'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import s from './orgServicesPage.module.scss'
const ss = styleSynthesizer(s, 'OrgServicesPage')

export default function OrgServicesPage({ }) {
  return (
    <main className={ss('container')}>
      <OrgDetailPageWrapper title="Services" Page={ userGroupsServices } limit={10000} />
    </main>
  )
}