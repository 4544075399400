import { Loading } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { useOrg } from 'api'
import React, { useEffect, useState } from 'react'
import styles from './sponsors.module.css'
const s = styleSynthesizer(styles, 'Sponsors')

export default function Sponsors({ userGroupId }) {
  const { isLoading, org } = useOrg({ userGroupId })
  const sponsors = org?.sponsors || []

  if (isLoading) return <Loading />
  if (sponsors.length <= 0) return null
  return (
    <div className={s('container')}>
      <h3 className={s('header')}>Thank You To Our Sponsors</h3>
      {isLoading ? <Loading /> : <SponsorRotation sponsors={sponsors} />}
    </div>
  )
}


function SponsorRotation({ sponsors }) {
  const [ timer, setTimer ] = useState(null)
  const [ displayIndex, setDisplayIndex ] = useState(0)

  useEffect(() => {
    clearInterval(timer)
    const tTimer = setTimeout(() => setDisplayIndex(displayIndex === sponsors.length - 1 ? 0 : displayIndex + 1), 5000)
    setTimer(tTimer)
    return () => {
      clearInterval(timer)
      clearInterval(tTimer)
    }
  }, [ displayIndex, sponsors ])

  const displaySponsor = sponsors[ displayIndex ]

  return (
    <React.Fragment >
      <div key={displaySponsor.phone} className={s('sponsor')}>
        <p><a className={s('sponsorLink')} href={displaySponsor.businessUrl} target="_blank">{displaySponsor.businessName}</a></p>
        <p>{displaySponsor.businessPhone}</p>
      </div>
    </React.Fragment>
  )
}

