import React, { useState } from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { Input } from 'modules/common/components/Forms'
import __ from './passwordField.module.css'

/**
 * @param {object} props 
 * @property {string} value - value of input field
 * @property {string} name - Name of the input field
 * @property {string} label - Label of the input field
 * @property {function(React.ChangeEvent<HTMLInputElement>)} onChange - onChange function
 */
const PasswordField = ({value, name, label, onChange, ...props}) => {

  return (
    <div className={__.container}>
      <Input label={label}
        type="password"
        name={name}
        value={value}
        onChange={onChange}
        {...props} />
    </div>
  )

}

export default PasswordField