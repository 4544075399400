import { Button, Dropdown, Input, NotificationMessage } from 'modules/common/components'
import useInputForm from 'modules/common/components/Forms/useFormManagement'
import styleSynthesizer from 'modules/common/styles/styles'
import { useUpdateOrgInfo } from 'api'
import React from 'react'
import s from './orgInfoForm.module.scss'
import { Org, UpdateOrgInfoInput } from 'types/query'
const ss = styleSynthesizer(s, 'OrgInfoForm')

const userGroupOptions = [ { key: 'MULTI', name: 'Multiple' }, { key: 'SINGLE', name: 'One' } ]
const helpText = {
  MULTI: {
    single: 'Examples: Team, Family, Squad',
    plural: 'Examples: Teams, Families, Squads'
  },
  SINGLE: {
    single: 'Examples: Student, Scout, Employee',
    plural: 'Examples: Students, Scouts, Employees'
  }

}

export default function OrgInfoForm({ orgInfo: {
  _id: orgId,
  name,
  zipCode,
  supportEmail,
  supportNumber,
  defaultPassword,
  userGroupSingleName,
  userGroupPluralName,
  userGroupsType }
}: { orgInfo: Org}) {

  const updateOrgInfo = useUpdateOrgInfo()
  const { data, isDirty, onChange, onSubmit } = useInputForm<UpdateOrgInfoInput>({
    orgId,
    name,
    zipCode,
    supportEmail,
    supportNumber,
    defaultPassword,
    userGroupPluralName,
    userGroupSingleName,
    userGroupsType
  }, updateOrgInfo.mutate)

  return (
    <React.Fragment>
      <NotificationMessage error={updateOrgInfo.error} />
      <form className={ss('form')} onSubmit={onSubmit}>

        <Input autoFocus
          className={s.org}
          label='Organization Name'
          type='text'
          name='name'
          value={data.name}
          onChange={onChange} />
        
        <Input
          className={s.defaultPassword}
          label='Default Password'
          type='text'
          name='defaultPassword'
          value={data.defaultPassword}
          helpText='Initial password for new members'
          onChange={onChange} />

        <Input
          className={s.zip}
          label='Zip Code'
          type='text'
          name='zipCode'
          value={data.zipCode}
          onChange={onChange} />

        <Input
          className={s.email}
          label='Support Email'
          type='text'
          name='supportEmail'
          value={data.supportEmail}
          onChange={onChange} />

        <Input
          className={s.number}
          label='Support Number'
          type='text'
          name='supportNumber'
          value={data.supportNumber}
          onChange={onChange} />




        <Dropdown
          className={s.groupType}
          label='Contributors Per Group'
          emptyOption={false}
          optionId={'key'}
          options={userGroupOptions}
          name='userGroupsType'
          value={data.userGroupsType}
          onChange={onChange} />

        <Input
          className={s.singular}
          label='Group Singular Name'
          type='text'
          name='userGroupSingleName'
          value={data.userGroupSingleName}
          helpText={helpText[ data.userGroupsType ].single}
          onChange={onChange} />

        <Input
          className={s.plural}
          label='Group Plural Name'
          type='text'
          name='userGroupPluralName'
          value={data.userGroupPluralName}
          helpText={helpText[ data.userGroupsType ].plural}
          onChange={onChange} />


        <div className={ss('actions')}>
          <Button className={ss('submitButton')} type="submit" onClick={onSubmit} isDirty={isDirty} isBlocking={updateOrgInfo.isLoading}>Save</Button>
        </div>

      </form>
    </React.Fragment>
  )
}