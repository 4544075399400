import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { useDetectOutsideClick } from 'modules/common/hooks/useDetectOutsideClick'
import React, { useRef, useState } from 'react'
import styles from './popupMenu.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
const s = styleSynthesizer(styles, 'PopupMenu')


/**
 * Available Data Attributes for styling
 * data-disabled - Will gray out the menu item and make it un-clickable
 * data-type - [title, button] will color the list item as a section title
 * 
 * @param {object} p 
 * @param {JSX.Element | JSX.Element [] } p.children - li items to display/show in the menu
 * @param {import('@fortawesome/fontawesome-svg-core').IconDefinition=} p.icon - Font awesome icon to display
 * @param {string=} p.iconLabel - Label to display by the icon if needed
 * @returns 
 */
function PopupMenu({ children, icon=faEllipsisV, iconLabel = '' }) {
  const menuRef = useRef(null)
  const [ showMenu, setShowMenu ] = useState(false)
  useDetectOutsideClick(menuRef, () => setShowMenu(false))

  return (
    <div ref={menuRef} className={s('container')}>
      {showMenu &&
        <ul className={s('popupMenu')} onClick={() => {setShowMenu(false)}}>
          {children}
        </ul>}

      <button className={iconLabel ? s('button') : s('buttonIcon') }
        type="button"
        onClick={() => setShowMenu(true)}>
        <FA className={s('ellipsis')} icon={icon} /> {iconLabel}
      </button>

    </div>
  )
};


export default PopupMenu