import { Loading } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import ServiceRow from 'modules/user/components/ServiceRow/ServiceRow'
import { Link } from '@reach/router'
import { useServiceSummary } from 'api'
import { sortServicesByDate } from 'api/selectors'
import { useNavigation } from 'navigation'
import React from 'react'
import styles from './serviceList.module.css'
const s = styleSynthesizer(styles, 'ServiceList')

export default function ({ userGroupId }) {
  const nav = useNavigation()
  const { isLoading, services } = useServiceSummary({ userGroupId })

  if (isLoading) return <Loading />
  if (services.length <= 0) return <p>No prior services found...</p>

  return (
    <React.Fragment >

      <div className={s('activitiesHeader')}>
        <h2 className={s('title')}>Services</h2>
        <Link to={nav.userGroup(userGroupId).services().url()}>Show All</Link>
      </div>

      <aside className={s('quoteContainer')}>
        <p className={s('quote')}>"Alone we can do so little; together we can do so much"</p>
        <p className={s('author')}>-- Helen Keller</p>
      </aside>

      <article className={s('serviceList')}>
        <h3 className={s('header')}>Hours</h3>
        {sortServicesByDate(services).map(service => (
          <ServiceRow key={service._id} service={service} userGroupId={userGroupId} />
        ))}
      </article>
    </React.Fragment>
  )
}
