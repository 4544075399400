import UserGroupsMembers from 'modules/admin/containers/UserGroupsMembers'
import UserGroupsServices from 'modules/admin/containers/UserGroupsServices'
import UserGroupsTargets from 'modules/admin/containers/UserGroupsTargets'
import { useAdminContext } from 'modules/admin/context/adminContext'
import ComponentError from 'modules/common/components/ComponentError'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useNavigation } from 'navigation'
import React, { useMemo } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import s from './orgUserGroupsPage.module.scss'
const ss = styleSynthesizer(s, 'OrgUserGroupsPage')

function OrgUserGroupsPageContent({userGroupIds}) {

  if(userGroupIds.length > 3) return (
    <section className={s.tooMany}>
      <p>Too many <UserGroupsTerm count={2} /> selected to view summary, please use the links above to view the members, targets, or services</p>
    </section>
  )

  return (
    <main className={s.container}>
      <div className={s.content}>
        <ErrorBoundary fallback={<ComponentError componentName='Members List' />}>
          <section className={s.members}>
            <h2 className={ss('title')}>Members</h2>
            <UserGroupsMembers userGroupIds={userGroupIds} />
          </section>
        </ErrorBoundary>

        {/* <section className={s('tags')}>
          <UserGroupsTags userGroups={selectedUserGroups} />
        </section> */}

        <ErrorBoundary fallback={<ComponentError componentName='Targets List' />}>
          <section className={s.targets}>
            <h2 className={ss('title')}>Targets</h2>
            <UserGroupsTargets userGroupIds={userGroupIds} />
          </section>
        </ErrorBoundary>

        <ErrorBoundary fallback={<ComponentError componentName='Service List' />}>
          <section className={s.services}>
            <h2 className={ss('title')}>Services</h2>
            <UserGroupsServices userGroupIds={userGroupIds} />
          </section>
        </ErrorBoundary>
      </div>
    </main>
  )
}

import OrgDetailPageWrapper from 'modules/admin/components/OrgDetailPageWrapper'

export default function OrgTargetsPage({ }) {
  return (
    <main className={ss('container')}>
      <OrgDetailPageWrapper  Page={OrgUserGroupsPageContent} />
    </main>
  )
}