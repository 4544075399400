import { Button, InLineForm, NotificationMessage, useFormManagement } from 'modules/common/components'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import { useCreateTargetForUserGroups } from 'api'
import dayjs from 'dayjs'
import React from 'react'
import TargetFields from '../TargetFormFields'
import s from '../userGroupsTargetManagement.module.scss'

export default function TargetCreateForm({ org, userGroupIds, onClose }) {
  const createTarget = useCreateTargetForUserGroups()
  const form = useFormManagement({
    orgId: org._id,
    userGroupIds,
    targetTypeId: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().add(6, 'months').format('YYYY-MM-DD'),
    hours: 0,
    description: '',
    targetRuleIds: []
  }, createTarget.mutate)

  return (
    <InLineForm isEnabled={true} onClose={onClose}>
      <NotificationMessage error={createTarget.error} />
      <form className={s.form} onSubmit={form.onSubmit} >
        <TargetFields form={form} org={org}/>
        <Button className={s.saveButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={createTarget.isLoading}>
           Add target to {userGroupIds.length} <UserGroupsTerm count={userGroupIds.length} />
        </Button>
      </form>
    </InLineForm>
  )
}


 