import { Loading } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useTargets, useUserGroup } from 'api'
import { groupTargetsByTargetType } from 'api/selectors'
import dayjs from 'dayjs'
import { useNavigation } from 'navigation'
import React from 'react'
import ProgressSection from './ProgressSection'
import styles from './targets.module.css'
const s = styleSynthesizer(styles, 'Targets')

export default function UserGroupProgress({ userGroupId }) {
  const nav = useNavigation()
  const userGroup = useUserGroup({ userGroupId })
  const { isLoading, targets } = useTargets({ userGroupId, active: true, asOfDate: dayjs().format('YYYY-MM-DD') , untilDate: '9999-12-31'})

  if (isLoading) return <Loading />
  if (targets.length === 0) return <p className={s('notFoundMessage')}>{`No Targets Found...`}</p>

  return (
    <React.Fragment >

      <div className={s('targetHeader')}>
        <h2 className={s('title')}>Targets</h2>
        <Link to={nav.userGroup(userGroupId).targets().url()}>Show All</Link>
      </div>


      <article className={s('progress')}>
        {groupTargetsByTargetType(targets).map(tt => (
          <div className={s('progressGroup')} key={tt._id} data-testid='target-type-node'>
            <div>
              <h3 className={s('header')}>{tt.name}</h3>
              <ProgressSection targets={tt.targets} />
            </div>
          </div>
        ))}
      </article>

      <aside className={s('lifetime')}>
        <div className={s('circle')}>
          {userGroup.isLoading 
          ? <Loading/> 
          : <React.Fragment>
              <span className={s('hours')}>{userGroup?.userGroup?.meta?.lifeTimeHours}</span>
              <span className={s('lifetimeText')}>Lifetime hours</span>
            </React.Fragment> 
          }
        </div>
      </aside>
    </React.Fragment>
  )
}
