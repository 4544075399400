
import ChartDataLabels from 'chartjs-plugin-datalabels'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import styleSynthesizer from 'modules/common/styles/styles'
import randomColor from 'randomcolor'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import s from './serviceByUserGroups.module.scss'
const ss = styleSynthesizer(s, 'ServiceByUserGroups')

/**
 * 
 * @typedef {("WEEK"|"MONTH"|"YEAR")} TimeFrames
 * 
 * @param {object} p 
 * @param {ServiceTotalsByUserGroup []} p.serviceTotalsByUserGroups
 * @returns 
 */
export default function ServiceByUserGroups({ serviceTotalsByUserGroups = [] }) {

  const { labels, data, colors } = useMemo(() => {
    const labels = []
    const data = []
    const colors = []

    serviceTotalsByUserGroups?.forEach(activity => {
      labels.push(activity.name)
      data.push(activity.hours)
      colors.push(randomColor())
    })
    return { labels, data, colors }

  }, [ serviceTotalsByUserGroups ])

  const chartData = {
    labels,
    datasets: [ {
      backgroundColor: colors,
      data,
    } ]
  }
  const maxHeight = ( data.length * 3 ) + 'vh';
  const minHeight = ( data.length * 2 ) + 'vh';
  return ( 
    <div className={ss('container')} style={{ maxHeight, minHeight}}>
      <h2 className={s.header}>Service Hours by <UserGroupsTerm /></h2>
      <Bar data={chartData}
        plugins={[ChartDataLabels]}
        options={{
          indexAxis: 'y',
          plugins: { legend: { display: false } },
          maintainAspectRatio: false,
        }}
      />
    </div>
  )
}