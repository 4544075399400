import { useConfigContext } from 'modules/common/context/configContext'
import { getPerformedByValue, getUsersFullName } from 'api'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { CSVLink } from 'react-csv'
import s from './servicesDownload.module.css'

/**
 * @param {object} p 
 * @param {ServiceWithUserGroupInfo [] | Service []} p.services
 * @param {JSX.Element | JSX.Element [] } p.children
 */
export default function ServicesDownload({ services=[], children }) {
  const { userGroupSingularName} = useConfigContext()
  const data = useMemo(() => {
    return services.map(s => ({
      ...s,
      performedByName: getUsersFullName(s?.performedByUser) || s.performedByUserText,
      performedByEmail: s?.performedByUser?.email,
      activity: s?.activity?.name
    }))

  }, [ services ])

  const headers = [
    {label: 'service date', key:'serviceDate'},
    {label: 'performed by name', key:'performedByName'},
    {label: 'performed by email', key:'performedByEmail'},
    {label: 'activity', key:'activity'},
    {label: 'service description', key:'description'},
    {label: 'hours', key:'hours'},
  ]

  if(data.length <= 0 ) return null
  if('userGroupName' in data[0]) headers.unshift({label: userGroupSingularName, key:'userGroupName'})

  return (
    <CSVLink filename={`SHO Services ${dayjs().format('MM-DD-YYYY')}.csv`}data={data} headers={headers} className={s.downloadLink} >
      {children}
    </CSVLink>
  )
}