import { Loading, TimeFrameFilters } from 'modules/common/components'
import { FILTERS } from 'modules/common/components/TimeFrameFilters'
import useListSelector from 'modules/common/hooks/useListSelector'
import styleSynthesizer from 'modules/common/styles/styles'
import TargetList from 'modules/user/components/TargetList'
import { useUserGroupsTargets } from 'api'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import UserGroupsTargetManagement from '../UserGroupsTargetManagement'
import s from './userGroupsTargets.module.scss'
import { useTargetFilters } from 'modules/common/workers'
const ss = styleSynthesizer(s, 'UserGroupsTargets')

export default function UserGroupTargets({userGroupIds}) {
  const [ filterDates, setFilterDates ] = useState({asOfDate: dayjs().format('YYYY-MM-DD'), untilDate: '' })
  const { isLoading, userGroups } = useUserGroupsTargets({ userGroupIds, active: false, ...filterDates })
  const selector = useListSelector()

  const targets = useMemo(() => {
    const targets = []
    userGroups?.forEach(ug => ug.targets.forEach(t => targets.push({ userGroupId: ug._id, userGroupName: ug.name, ...t })))
    return targets
  }, [ userGroups ])

  const [targetFilters, setTargetFilters] = useState<TargetFilters>()
  const {targets: filteredTargets, status: filterTargetStatus} = useTargetFilters(targets, targetFilters)


  return (
    <React.Fragment>
      <div className={ss('countDownload')}>
        <span className={s.selectedCount}>{selector.count} of {targets.length}</span>
      </div>
      <UserGroupsTargetManagement targets={selector.getSelected()} userGroupIds={userGroupIds} onFilters={(filters) => setTargetFilters(filters)}/>
      <TimeFrameFilters onFilterChange={(asOfDate, untilDate) => setFilterDates({asOfDate, untilDate})} showToday={ true } activeFilter={FILTERS.TODAY} />
      {( isLoading || filterTargetStatus !== 'SUCCESS' ) ? <Loading /> : <TargetList targets={filteredTargets} listSelector={selector} />}
    </React.Fragment>
  )
}

