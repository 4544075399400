import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

import __ from 'modules/common/components/Forms/styles/buttons.module.css'


/**
 * @param {object} props 
 * @param {JSX.Element[] | JSX.Element | text} [ props.children="Submit" ] - Value to render between the buttons
 * @param {string} [ props.className="" ] - Css Module class to add to the value
 * @param {"button" | "submit" | "reset"} [ props.type="button" ] - button Type
 * @param {boolean} [ props.isDirty=true ] - Identifies if the data this button represents is dirty, in order to enable or disable the button
 * @param {boolean} [ props.isBlocking=false ] - if true, a spinner will show on the button and the button will be disabled
 * @param {boolean} [ props.disabled=false ] - overrides the disabled or enabled based on isDirty or isBlocking 
 * @param {function(React.SyntheticEvent):void} props.onClick - onClick event 
 * @returns {JSX.Element}
 */
export default function Button (props)  {
  const {className='', children='Submit', isDirty=true, isBlocking=false, disabled=false, ...inputProps} = props
  const disabledResult = disabled ? disabled : !isDirty || isBlocking

  return (
    <button type="button" className={`${className} ${__.button}`} {...inputProps} disabled={disabledResult}>
      {isBlocking && <FA className={`${__.spinner} spinner`} data-testid="loading-spinner" icon='spinner' spin/>}
      {children}
    </button>
  )
}
