import { faBars, faLandmark, faRetweet, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import PopupMenu from 'modules/common/components/PopupMenu'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link } from '@reach/router'
import { useLoggedInUser, useLogout } from 'api'
import { useNavigation } from 'navigation'
import React from 'react'
import { Loading } from '..'
import UserGroupsTerm from '../UserGroupsTerm'
import styles from './userMenu.module.scss'
const s = styleSynthesizer(styles, 'UserMenu')

/**
 * @param {object} p
 * @param {orgId=} p.orgId - if passed in will display a link to the passed in org
 */
export default function UserMenu({ orgId }) {
  const { isLoading: userIsLoading, user } = useLoggedInUser()
  const nav = useNavigation()
  const logout = useLogout()

  if (userIsLoading) return <Loading />

  const otherUserGroups = user.userGroupRoles
    .map(ugr => ({
      userGroupId: ugr.userGroup._id,
      userGroupName: ugr.userGroup.name,
      orgId: ugr.userGroup?.org?._id,
      orgName: ugr.userGroup?.org?.name
    }))


  return (
    <div className={s('userSettings')}>
      <PopupMenu icon={faBars}>

        <li data-type='title'><UserGroupsTerm count={2} /></li>
        {otherUserGroups &&
          <div className={s('userGroups')}>
            {otherUserGroups.map(oug =>
              <li key={oug.userGroupId + oug.orgId} className={s('userGroupLink')}>
                <Link key={oug.userGroupId} className={s('navLink')} to={nav.userGroup(oug.userGroupId).url()}>
                  <FA icon={faRetweet} />
                  <div className={s('userGroupLabel')}>
                    <p className={s('userGroupName')}>{oug.userGroupName}</p>
                    <p className={s('orgName')}>{oug.orgName}</p>
                  </div>
                </Link>
              </li>
            )}
          </div>
        }

        <li data-type='title'>User</li>

        <li >
          <Link className={s('navLink')} to={nav.settings().url()}>
            <FA icon={faUser} /> Account
          </Link>
        </li>
        <li className={s('logout')} onClick={logout.mutate}>
          <FA icon={faSignOutAlt} /> Logout
        </li>

        {user.managingOrgs.length > 0 &&
          <React.Fragment>
            <li data-type='title'>Organizations</li>

            {user.managingOrgs.map(mo =>
              <li key={mo.org._id} className={s('orgSettings')} >
                <Link className={s('navLink')} to={nav.org(mo.org._id).url()}>
                  <FA icon={faLandmark} /> {mo.org.name}
                </Link>
              </li>
            )}
          </React.Fragment>
        }
      </PopupMenu>
    </div>
  )
}

