import { Loading } from 'modules/common/components';
import InLineForm from 'modules/common/components/InLineForm';
import ChangeEmailEntry from 'modules/user/components/ChangeEmailEntry';
import ChangePasswordEntry from 'modules/user/components/ChangePasswordEntry';
import { useLoggedInUser } from 'api';
import React, { useState } from 'react';
import __ from './credentialManagement.module.css';


export default function CredentialManagement({ }) {
  const { isLoading, user } = useLoggedInUser()
  const [showPasswordEntry, setShowPasswordEntry] = useState(false)
  const [showEmailEntry, setShowEmailEntry] = useState(false)

  if (isLoading) return <Loading />

  return (
    <div className={__.container}>
      <section className={__.emailDisplay}>
        <label className={__.label}>Email:</label>
        <p className={__.value}>{user.userName}</p>
        <p className={__.buttonText} onClick={() => setShowEmailEntry(true)}>change</p>
      </section>
      <InLineForm isEnabled={showEmailEntry} onClose={setShowEmailEntry}>
        <ChangeEmailEntry 
          userId={user._id}
          userName={user.userName}
          onSuccess={() => setShowEmailEntry(false)} />
      </InLineForm>

      <section className={__.passwordDisplay}>
        <label className={__.label}>Password:</label>
        <p className={__.value}>**********</p>
        <p className={__.buttonText} onClick={() => setShowPasswordEntry(true)}>change</p>
      </section>
      <InLineForm isEnabled={showPasswordEntry} onClose={setShowPasswordEntry}>
        <ChangePasswordEntry
          userId={user._id}
          userName={user.userName}
          onSuccess={() => setShowPasswordEntry(false)} />
      </InLineForm>
    </div>
  );
};

