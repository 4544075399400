import { Loading } from 'modules/common/components';
import MemberList from 'modules/user/components/MemberList';
import { useUserMemberships } from 'api';
import React from 'react';
import __ from './userGroupMemberships.module.css';


export default function UserGroupMemberships({userId} ) {
  const {isLoading, userMemberships} = useUserMemberships(userId)
  const active = userMemberships?.filter(ugs => ( ugs.userGroup.inactiveTimestamp === null ))

  if(isLoading) return <Loading/>

  return (
    <div className={__.container}>
      {active?.map(ugs =>
        <section key={ugs.userGroup._id} className={__.userGroup}>
          <div className={__.header}>
            <h3>{ugs.userGroup.org?.name}</h3>
            <p>{ugs.userGroup.name}</p>
          </div>
          <MemberList members={ugs.userGroup.memberRoles} userGroupId={ugs.userGroup._id} />
        </section>
      )}
    </div>
  );
};
