import { useConfigContext } from 'modules/common/context/configContext'
import dayjs from 'dayjs'
import React from 'react'
import { CSVLink } from 'react-csv'
import s from './targetsDownload.module.css'

/**
 * @param {object} p 
 * @param {TargetsWithUserGroupInfo [] | Target []} p.targets
 * @param {JSX.Element | JSX.Element [] } p.children
 */
export default function TargetsDownload({ targets=[], children }) {
  const { userGroupSingularName} = useConfigContext()
  const headers = [
    {label: 'start date', key:'startDate'},
    {label: 'end date', key:'endDate'},
    {label: 'target type', key:'type.name'},
    {label: 'target description', key:'description'},
    {label: 'target description', key:'description'},
    {label: 'target hours', key:'hours'},
    {label: 'logged hours', key:'meta.totalHours'},
    {label: 'percent complete', key:'meta.percentComplete'},
  ]

  if(targets.length <= 0) return null
  if(targets && targets[0]?.userGroupName) headers.unshift({label: userGroupSingularName, key:'userGroupName'})

  return (
    <CSVLink filename={`SHO Targets ${dayjs().format('MM-DD-YYYY')}.csv`} data={targets} headers={headers} className={s.downloadLink} >
      {children}
    </CSVLink>
  )
}