import React from 'react'
import { Router } from '@reach/router'
import { Navbar } from 'modules/common/components'

import Login from 'modules/auth/scenes/Login'
import PasswordResetRequest from 'modules/auth/scenes/PasswordResetRequest'
import ProcessPasswordResetRequest from 'modules/auth/scenes/ProcessPasswordResetRequest'

import __ from './authPage.module.css'

export default function AuthPage() {
  return (
    <div className={__.page}>
      <Navbar />
      <main className={__.main}>
        <Router>
          <Login path='login' />
          <PasswordResetRequest path='passwordReset' />
          <ProcessPasswordResetRequest path='passwordReset/:requestId' />
        </Router>
      </main>
    </div>
  )
}
