import { faPlusCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { useRemoveSponsor, useUpsertSponsor } from 'api'
import { sort } from 'fast-sort'
import React, { useEffect, useRef, useState } from 'react'
import s from './sponsorsManagement.module.scss'
const ss = styleSynthesizer(s, 'SponsorsManagement')


/**
 * @param {object} p 
 * @param {Sponsor []} p.sponsors
 * @param {orgId} p.orgId
 */
export default function SponsorsManagement({ sponsors, orgId }) {
  const upsertSponsor = useUpsertSponsor()

  const sortedSponsors = sort(sponsors).asc('businessName')
  const [ showCreate, setShowCreate ] = useState(false)

  const form = useFormManagement({ orgId, businessName: '', businessPhone: '', businessUrl: '' }, upsertSponsor.mutate)
  useEffect(() => {
    if (upsertSponsor.isSuccess || showCreate) document.getElementsByName("businessName").item(0).focus()
    if (upsertSponsor.isSuccess) form.resetState()
  }, [ upsertSponsor.isSuccess, showCreate ])

  return (
    <div className={ss('container')}>
      <Button className={ss('showCreate')} onClick={() => setShowCreate(true)}><FontAwesomeIcon icon={faPlusCircle} /></Button>

      <InLineForm isEnabled={showCreate} onClose={setShowCreate}>
        <NotificationMessage error={upsertSponsor.error} />

        <form className={ss('createForm')} onSubmit={form.onSubmit} >
          <SponsorInputs form={form} />
          <Button className={s.addButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} isBlocking={upsertSponsor.isLoading}> Add </Button>
        </form>
      </InLineForm>

      {sortedSponsors.map(sponsor =>
        <EditSponsorForm key={sponsor._id} sponsor={{ orgId, ...sponsor }} />
      )}
    </div>
  )
};

/**
 * @param {object} p 
 * @param {UpsertSponsorInput} p.sponsor
 */
function EditSponsorForm({ sponsor }) {
  const upsertSponsor = useUpsertSponsor()
  const removeSponsor = useRemoveSponsor()

  const form = useFormManagement(sponsor, upsertSponsor.mutate)
  useEffect(() => form.resetState(form.data), [ upsertSponsor.isSuccess ])

  return (
    <React.Fragment>
      <p className={s.updateError}>{upsertSponsor?.error?.message}</p>
      <form className={ss('form')} onSubmit={form.onSubmit}>
        <SponsorInputs form={form} />

        <div className={ss('buttons')}>
          {form.isDirty &&
            <Button className={s.submitButton} type="submit" onClick={form.onSubmit} isDirty={form.isDirty} >
              <FontAwesomeIcon icon={faSave} />
            </Button>
          }
          <Button className={s.deleteButton} onClick={() => removeSponsor.mutate({ orgId: sponsor.orgId, sponsorId: sponsor._id })} >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
      </form>
    </React.Fragment>
  )
}

/**
 * @param {object} p 
 * @param {import('modules/common/components/Forms/useFormManagement').useInputFormReturn<Sponsor>} p.form
 */
function SponsorInputs({ form }) {
  return (
    <React.Fragment>
      <Input
        label='Business Name'
        placeholder='Business Name'
        className={s.businessName}
        type='text'
        name='businessName'
        value={form.data.businessName}
        onChange={form.onChange} />

      <Input
        label='Business Phone'
        placeholder='Business Phone'
        className={s.phone}
        type='tel'
        name='businessPhone'
        value={form.data.businessPhone}
        onChange={form.onChange} />

      <Input
        label='Website'
        placeholder='Website'
        className={s.url}
        type='url'
        name='businessUrl'
        value={form.data.businessUrl}
        onChange={form.onChange} />
    </React.Fragment>
  )
}