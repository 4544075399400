import debounce from 'p-debounce'
import React, { createContext, useState } from 'react'

/**
 * @typedef {object} AdminContext
 * @property {UserGroup[]} selectedUserGroups
 * @property {function():userGroupId[]} getUserGroupIds
 * @property {function(UserGroup[]):void} setSelectedUserGroups
 * @property {function():void} resetSelectedUserGroups
 */
const AdminContext = createContext({})

function AdminProvider({children}) {
  const [selectedUserGroups, setSelectedUserGroups] = useState([])

  const value = {
    selectedUserGroups, 
    setSelectedUserGroups: debounce(setSelectedUserGroups, 600),
    getUserGroupIds: () => selectedUserGroups.map(s => s._id),
    resetSelectedUserGroups: () => setSelectedUserGroups([])
  }

  return <AdminContext.Provider value={value}> {children} </AdminContext.Provider>
}

/**
 * @returns { AdminContext }
 */
function useAdminContext() {
  const context = React.useContext(AdminContext)
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return /**@type{AdminContext} */( context )
}

export {
  AdminProvider,
  useAdminContext,
}
