import { queries } from "api"
import { shoFetch } from "api/fetch"
import { useMutation, useQuery } from "react-query"
import { toast } from 'react-toastify'

/**
 * @param {requestId} requestId 
 */
export function useRequest(requestId) {
  const query = queries.getRequestById(requestId)
  const { data, ...response } = useQuery(query.key, () => shoFetch(query))
  return { request: /** @type {UserRequest} */ (data?.request), ...response }
}

/** @returns {import("react-query").UseMutationResult<User, queryError, AcceptInviteRequestInput, any>} */
export function useAcceptInviteRequest() {
  return useMutation(
    async (acceptInviteRequestInput) => shoFetch(queries.acceptInviteRequest(acceptInviteRequestInput)),
    { mutationKey: 'acceptInviteRequest',
    onSuccess: () => { toast.success('Invite Accepted') } }
  )
}

/** @returns {import("react-query").UseMutationResult<User, queryError, RejectInviteRequestInput, any>} */
export function useRejectInviteRequest() {
  return useMutation(
    async (rejectInviteRequest) => shoFetch(queries.rejectInviteRequest(rejectInviteRequest)),
    { mutationKey: 'rejectInviteRequest',
    onSuccess: () => { toast.success('Invite Rejected') } }
  )
}


/** @returns {import("react-query").UseMutationResult<any, queryError, RequestPasswordResetInput, any>} */
export function useRequestPasswordReset() {
  return useMutation(
    async (passwordResetRequest) => shoFetch(queries.requestPasswordReset(passwordResetRequest)),
    { mutationKey: 'requestPasswordReset',
    onSuccess: (_, input) => { toast.success(`Reset email sent to ${input.email}`) } }
  )
}

/** @returns {import("react-query").UseMutationResult<any, queryError, ProcessPasswordResetRequestInput, any>} */
export function useProcessPasswordResetRequest() {
  return useMutation(
    async (passwordResetRequest) => shoFetch(queries.processPasswordResetRequest(passwordResetRequest)),
    { mutationKey: 'processPasswordResetRequest', 
    onSuccess: () => { toast.success('Password reset') } }
  )
}