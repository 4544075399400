import NavBar from 'modules/common/components/Navbar'
import { useConfigContext } from 'modules/common/context/configContext'
import styleSynthesizer from 'modules/common/styles/styles'
import CreateServiceEntry from 'modules/user/container/ServiceEntry/CreateServiceEntry'
import Sponsors from 'modules/user/container/Sponsors'
import UserGroupHeader from 'modules/user/container/UserGroupHeader'
import UserGroupNavBar from 'modules/user/container/UserGroupNavBar'
import { useOrg } from 'api'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styles from './userGroupPage.module.scss'
const s = styleSynthesizer(styles, 'UserGroupPage')

export default function UserGroupPage({ children: nestedPages, userGroupId }) {
  const { org } = useOrg({ userGroupId })
  const {updateOrgConfig } = useConfigContext()
  useEffect(() => updateOrgConfig(org), [org])

  return (
    <div className={s('page')}>
      <Helmet>
        <title>Dashboard - Service Hours Online</title>
      </Helmet>

      <NavBar />
      <UserGroupHeader userGroupId={userGroupId} />
      <CreateServiceEntry userGroupId={userGroupId} />
      <UserGroupNavBar userGroupId={userGroupId} />
      <main className={s('nestedPages')}>{nestedPages}</main>
      <Sponsors userGroupId={userGroupId} />
    </div>
  )
}
