import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import s from './useListSelector.module.scss'

/**
 * @template T
 * @typedef  useListSelector
 * @property {function(T, boolean):void} selectItem - select a single item programmatically
 * @property {function(React.MouseEvent, T):void} onSelect - method called on for the onclick event
 * @property {function(T[]):void} onSelectAll - selects all of the items passed in
 * @property {function():void} unSelectAll - removes all selected elements
 * @property {function(T):boolean} isSelected - returns boolean if the passed in object is selected
 * @property {function(T[]):boolean} isAllSelected - returns boolean all the items in the list are selected
 * @property {function():T[]} getSelected - the selected objects
 * @property {function():String[]} getSelectedIds - the selected keys based on the key 
 * @property {number} count - number of selected
 */

/**
 * @template K
 * @param {K[]} [ initial=[] ] - Initial set of selected objects
 * @param {string} [ key="_id" ] - ID - of the key
 * @param {'MULTI' | 'SINGLE' | 'MIXED'} [ selectMode='MIXED' ] MIXED - multi select on shift, control, or if mobile.  SINGLE - always single select, MULTI - Always multi select
 * @returns {useListSelector<K>}
 */
export default function (initial = [], key = '_id', selectMode = 'MIXED') {
  const [ count, setCount ] = useState(initial.length)

  const [ selected, setSelected ] = useState(
    initial.reduce((items, item) => {
      items[ item[ key ] ] = item
      return items
    }, {}))


  /**
   * @param {React.MouseEvent} e 
   * @param {K} item 
   */
  function onSelect(e, item) {
    let tSelected = { ...selected }

    const removed = removeItemFromListIfExists(item, tSelected)

    if (selectMode === 'SINGLE' ||
      (selectMode === 'MIXED' && !(window.matchMedia('(pointer: coarse)').matches || e.ctrlKey || e.shiftKey))) {
      tSelected = {}
    }

    if (!removed) addItemToList(item, tSelected)

    setSelected(tSelected)
    setCount(Object.values(tSelected).length)
  }

  function selectItem(item, multiple = true) {
    let tSelected = {}
    if (multiple) tSelected = { ...selected }

    addItemToList(item, tSelected)
    setSelected(tSelected)
    setCount(Object.values(tSelected).length)
  }


  function onSelectAll(items) {
    let tSelected = { ...selected }

    items.forEach(i => {
      const removed = removeItemFromListIfExists(i, tSelected)
      if (!removed) addItemToList(i, tSelected)
    })

    setSelected(tSelected)
    setCount(Object.values(tSelected).length)
  }

  function removeItemFromListIfExists(item, selected) {
    if (!(item[ key ] in selected)) return false
    delete selected[ item[ key ] ]
    setCount(count - 1)
    return true
  }

  function addItemToList(item, selected) {
    selected[ item[ key ] ] = item
  }

  function unSelectAll() {
    setSelected({})
    setCount(0)
  }

  function getSelected() {
    return Object.values(selected)
  }

  function getSelectedIds() {
    return Object.keys(selected)
  }



  function isSelected(item) {
    return (item[ key ] in selected)
  }

  function isAllSelected(items) {
    return (count === items.length)
  }




  return {
    onSelect,
    isSelected,
    isAllSelected,
    selectItem,
    onSelectAll,
    unSelectAll,
    getSelected,
    getSelectedIds,
    count
  }

}

export function SelectButton({ selector, className = '', item }) {
  const selected = selector.isSelected(item)
  return (
    <button className={`${s.selectButton} ${className} ${selected ? s.selectedButton : ''}`} >
      {selected && <FontAwesomeIcon icon={faCheck} />}
    </button>
  )
}

export function SelectAllButton({ selector, className = '', items, children = null }) {
  const selected = selector.isAllSelected(items)
  return (
    <div className={s.selectAllContainer} onClick={() => selector.onSelectAll(items)}>
      <button className={`${s.selectButton} ${className} ${selected ? s.selectedButton : ''}`} >
        {selected && <FontAwesomeIcon icon={faCheck} />}
      </button>
      {children}
    </div>
  )
}


/** @typedef {Object.<string, number>} selectedKeys  - key is the object key, and the value is the position in the selected list*/