import { faTools } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AdminUserGroupNavBar from 'modules/admin/containers/AdminUserGroupNavBar'
import OrgUserGroupsNavigation from 'modules/admin/containers/OrgUserGroupsNavigation'
import { useAdminContext } from 'modules/admin/context/adminContext'
import { Loading } from 'modules/common/components'
import ComponentError from 'modules/common/components/ComponentError'
import NavBar from 'modules/common/components/Navbar'
import { useConfigContext } from 'modules/common/context/configContext'
import styleSynthesizer from 'modules/common/styles/styles'
import { Link, Router } from '@reach/router'
import { useLoggedInUser, useOrgInfo } from 'api'
import { useNavigation } from 'navigation'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import OrgMembersPage from '../OrgMembersPage'
import OrgMetaDataDashboard from '../OrgMetaDataDashboard/OrgMetaDataDashboard'
import OrgServicesPage from '../OrgServicesPage'
import OrgTargetsPage from '../OrgTargetsPage'
import OrgUserGroupsPage from '../OrgUserGroupsPage'
import styles from './orgPage.module.scss'

const s = styleSynthesizer(styles, 'OrgPage')

export default function OrgPage({ orgId }) {
  const { isLoading, user } = useLoggedInUser()
  const { selectedUserGroups } = useAdminContext()

  const { org: dbOrg } = useOrgInfo(orgId)
  const { updateOrgConfig } = useConfigContext()
  useEffect(() => { updateOrgConfig(dbOrg) }, [ dbOrg ])

  const nav = useNavigation()

  if (isLoading) return <Loading />

  const org = user.managingOrgs.find(mo => mo.org._id === orgId)?.org

  return (
    <main className={s('page')}>
      <Helmet>
        <title>Org - Service Hours Online</title>
      </Helmet>

      <NavBar />

      <article className={s('orgHeader')}>
        <h2 className={s('orgTitle')}>{org.name}</h2>
        <Link className={s('settings')} to={nav.org(orgId).settings().url()}>
          <FontAwesomeIcon icon={faTools} />
        </Link>

      </article>

      <ErrorBoundary fallback={<ComponentError componentName='User Group List' />}>
        <OrgUserGroupsNavigation orgId={orgId} />
      </ErrorBoundary>


      <div className={s('nested')}>
        <AdminUserGroupNavBar orgId={orgId} />
        <ErrorBoundary fallback={<ComponentError componentName='User Group Page' />}>
          <Router>
            <OrgMetaDataDashboard
              // @ts-ignore
              path="/" selectedCount={selectedUserGroups.length} />
            <OrgTargetsPage
              // @ts-ignore
              path="/targets" />
            <OrgServicesPage
              // @ts-ignore
              path="/services" />
            <OrgMembersPage
              // @ts-ignore
              path="/members" />
            <OrgUserGroupsPage path="/summary" />
          </Router>
        </ErrorBoundary>
      </div>
    </main>
  )
}
