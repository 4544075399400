import { useCreateService, useLoggedInUser } from 'api'
import dayjs from 'dayjs'
import { useFormManagement } from 'modules/common/components/Forms'
import React, { useEffect, useRef } from 'react'
import ServiceEntry from '../ServiceForm'
import styles from './createServiceEntry.module.css'
import styleSynthesizer from 'modules/common/styles/styles'
const s = styleSynthesizer(styles, 'CreateServiceEntry')

export default function CreateServiceEntry({ classNames = {}, userGroupId }) {
  const { user } = useLoggedInUser()
  const createService = useCreateService()

  const form = useFormManagement(
    {
      userGroupId,
      serviceDate: dayjs().format('YYYY-MM-DD'),
      performedByUserId: user._id,
    },
    createService.mutate
  )

  const toFocus = useRef(null)

  useEffect(() => {
    if (createService.isSuccess) {
      createService.reset()
      form.resetState()
      toFocus.current.focus()
    }
  }, [createService.isSuccess])

  return (
    <div className={s('container')}>
      <h2 className={s('header')}>Service Entry</h2>
      <ServiceEntry
        userGroupId={userGroupId}
        form={form}
        error={createService.error}
        isBlocking={createService.isLoading}
        ref={toFocus}
      />
    </div>
  )
}
