import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import s from './inLineForm.module.css'
const ss = styleSynthesizer(s, 'InLineForm')

/**
 * @param {object} p
 * @param {boolean} p.isEnabled - boolean to know if the form should be displayed or not
 * @param {function(boolean):void} p.onClose - function executed with a false to represent the close button was clicked 
 * @param {React.ReactNode} p.children - form to render within the box 
 * @returns 
 */
function InLineForm({isEnabled, onClose, children}) {
  if(!isEnabled) return null

  return ( 
    <div className={ss('container')}>
      <button type='button' className={s.onCloseButton} onClick={() => onClose(false)}> <FA icon='times' /></button>
      <div className={s.formContainer}>
        {children}
      </div>
    </div>
  );
};


export default InLineForm;