import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

import __ from 'modules/common/components/Forms/styles/inputs.module.css'

const Input = (props, ref) => {
  const { className, label, addon, prefix, value, helpText, ...otherProps } = props
  const addonStyle = addon ? __.addon : ''

  return (
    <div className={`${className} ${__.inputContainer} input-container`}>
      <label className={`label ${__.label}`}>
        <span className='label-text'>
          {label && <FA className={`${__.caretRight} caret-right`} icon='caret-right' />}
          {label}
        </span>
        <div className={`inputWrapper ${__.inputWrapper}`}>
          {prefix}
          <input ref={ref} className={`input ${__.input} ${addonStyle}`} value={value || ''} {...otherProps} />
          {addon}
        </div>
        <p className={__.helpText}>{helpText}</p>
      </label>
    </div>
  )
}
export default React.forwardRef(Input)