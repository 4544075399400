import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'
import { NotificationMessage } from 'modules/common/components'
import { Input, useFormManagement } from 'modules/common/components/Forms'
import PopupMenu from 'modules/common/components/PopupMenu'
import { useAddMemberToUserGroup, useRemoveUserFromUserGroup } from 'api'
import { getUsersFullName } from 'api/selectors'
import React, { useState } from 'react'
import __ from './memberList.module.css'
import AddMemberForm from 'modules/admin/containers/AddMemberForm'


/**
 * @param {object} p 
 * @param {MemberRole[]} p.members 
 * @param {userGroupId} p.userGroupId 
 */
function MemberList({ members, userGroupId }) {
  const [ showAdd, setShowAdd ] = useState(false)
  const { error: addUserError, mutate: addUserToUserGroup } = useAddMemberToUserGroup()
  const { error: removeUserError, mutate: removeUserFromUserGroup } = useRemoveUserFromUserGroup()
  const { data, ...form } = useFormManagement({ email: '' }, async (data) => {
    await addUserToUserGroup({ email: data.email, userGroupId })
    setShowAdd(false)
  })

  if (!members) return null

  return (
    <div className={__.memberList}>
      <NotificationMessage error={addUserError || removeUserError} />
      {members.map(member =>
        <div className={__.memberRow}>
          <div className={__.member}>
            <p className={__.name}>{getUsersFullName(member.user)}</p>
            <p className={__.email}>{member.user.email}</p>
          </div>

          <PopupMenu >
            <li className={__.trash} onClick={() => removeUserFromUserGroup({ userId: member.user._id, userGroupId })}>
              <FA className={__.trash} icon='trash' />
              Remove
            </li>
          </PopupMenu>

        </div>
      )}
      <div className={__.addAction}>
        {showAdd
          ?
          <div className={__.addMemberForm}>
            <button type='button' className={__.onCloseButton} onClick={() => setShowAdd(false)}> <FA icon='times' /></button>
            <AddMemberForm userGroupId={userGroupId} onSuccess={() => setShowAdd(false)} />
          </div>
          : <button className={__.addButton} onClick={() => setShowAdd(true)}><FA className={__.addIcon} icon='user-plus' /></button>}
      </div>
    </div>
  )
};


export default MemberList