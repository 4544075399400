import React from 'react';

import __ from './progressBar.module.css';

/**
 * progress bar, the colors and size with the following classes (passing in your className) 
 *  - container
 *  - background-bar
 *  - foreground-bar
 * @param {object} p 
 * @param {object=} p.className - Styles to apply to the progress bar container. 
 * @param {number} p.percent - percent complete 
 * @param {string} p.label - text to display within the bar
 */
function ProgressBar({className, percent, label}) {
  const width = percent > 100 ? '100%' : `${percent}%`
  return ( 
    <div className={`${className} ${__.container}`}>
      <div className={`background-bar ${__.background}`} >
        <span className={`label ${__.label}`}>{label}</span>
        <div className={`foreground-bar ${__.foreground}`} style={{width}}></div>
      </div>
    </div>
  );
};


export default ProgressBar;