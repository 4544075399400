import { faPlusCircle, faSave, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, InLineForm, Input, NotificationMessage, useFormManagement } from 'modules/common/components'
import styleSynthesizer from 'modules/common/styles/styles'
import { useRemoveTargetType, useUpsertTargetType } from 'api'
import { sort } from 'fast-sort'
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import s from './targetsManagement.module.scss'
const ss = styleSynthesizer(s, 'TargetsManagement')

/**
 * @param {object} p 
 * @param {TargetType []} p.targets
 * @param {orgId} p.orgId
 */
export default function TargetsManagement({ targets, orgId }) {
  const upsertTargetType = useUpsertTargetType()
  const inputRef = useRef(null)

  const sortedTargets = sort(targets).asc('name')
  const [ showCreate, setShowCreate ] = useState(false)
  const { data, isDirty, onChange, onSubmit, resetState } = useFormManagement({ orgId, name: '', description: '' }, upsertTargetType.mutate)

  useEffect(() => { 
    if(upsertTargetType.isSuccess || showCreate) inputRef?.current?.focus()
    if(upsertTargetType.isSuccess) resetState() 
  }, [upsertTargetType.isSuccess, showCreate])

  return (
    <div className={ss('container')}>
      <Button className={ss('showCreate')} onClick={() => setShowCreate(true)}><FontAwesomeIcon icon={faPlusCircle} /></Button>

      <InLineForm isEnabled={showCreate} onClose={setShowCreate}>
        <NotificationMessage error={upsertTargetType.error} />
        <form className={ss('createForm')} onSubmit={onSubmit}>
          <Input
            ref={inputRef}
            autoComplete='off'
            className={s.targetName}
            label='Target Name'
            type='text'
            name='name'
            value={data.name}
            onChange={onChange} />
          <Input
            className={s.targetDescription}
            autoComplete='off'
            label='Description'
            type='text'
            name='description'
            value={data.description}
            onChange={onChange} />

          <Button className={s.addButton} type="submit" onClick={onSubmit} isDirty={isDirty} isBlocking={upsertTargetType.isLoading}> Add </Button>
        </form>
      </InLineForm>

      {sortedTargets.map(target =>
        <EditTargetForm key={target._id} target={{orgId, ...target }} />
      )}
    </div>
  )
};

/**
 * @param {object} p 
 * @param {UpsertTargetTypeInput} p.target
 */
function EditTargetForm({ target }) {
  const upsertTargetType = useUpsertTargetType()
  const removeTargetType = useRemoveTargetType()

  target.description = target.description ? target.description : ''
  const { data, isDirty, onChange, onSubmit, resetState } = useFormManagement(target, upsertTargetType.mutate)
  useEffect(() => resetState(data), [upsertTargetType.isSuccess])

  return (
    <form className={ss('form')} onSubmit={onSubmit}>
      <p className={s.updateError}>{upsertTargetType?.error?.message}</p>

      <Input
        className={s.targetName}
        autoComplete='off'
        placeholder='Target Name'
        type='text'
        name='name'
        value={data.name}
        onChange={onChange} />
      <Input
        className={s.targetDescription}
        autoComplete='off'
        placeholder='Description (optional)'
        type='text'
        name='description'
        value={data.description}
        onChange={onChange} />

      <div className={ss('buttons')}>
        {isDirty &&
          <Button className={s.submitButton} type="submit" onClick={onSubmit} isDirty={isDirty} >
            <FontAwesomeIcon icon={faSave} />
          </Button>}
        <Button className={s.deleteButton} onClick={() => removeTargetType.mutate({orgId: target.orgId, targetTypeId: target._id})} >
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      </div>
    </form>
  )
}
