
import { BarElement, CategoryScale, Chart as ChartJS, LinearScale, PointElement, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import styleSynthesizer from 'modules/common/styles/styles'
import randomColor from 'randomcolor'
import React, { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import s from './serviceByActivity.module.scss'
ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Tooltip);
const ss = styleSynthesizer(s, 'ServiceByActivity')

/**
 * 
 * @typedef {("WEEK"|"MONTH"|"YEAR")} TimeFrames
 * 
 * @param {object} p 
 * @param {ServiceTotalsByActivity []} p.serviceTotalsByActivity 
 * @returns 
 */
export default function ServiceByActivity({serviceTotalsByActivity=[]}) {

  const { labels, data, colors } = useMemo(() => {
    const labels = []
    const data = []
    const colors = []

    serviceTotalsByActivity?.forEach(activity => {
      labels.push(activity.name)
      data.push(activity.hours)
      colors.push(randomColor())
    })
    return {labels, data, colors}

  }, [serviceTotalsByActivity])


  const chartData = {
    labels,
    datasets: [{
      backgroundColor: colors,
      data,
    }]
  }
  const maxHeight = ( data.length * 3 ) + 'vh';
  const minHeight = ( data.length * 2 ) + 'vh';
  return ( 
    <div className={ss('container')} style={{ maxHeight, minHeight}}>
      <h2 className={s.header}>Service Hours by Activity</h2>
      <Bar data={chartData}
        plugins={[ChartDataLabels]}
        options={{ indexAxis: 'y', plugins: { legend: { display: false } }, maintainAspectRatio: false }}
      />
    </div>
  );
};