import React from 'react'
import s from './targetRule.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { sort } from 'fast-sort'
const ss = styleSynthesizer(s, 'TargetRule')

/**
 * @param {object} p 
 * @param {TargetRule} p.targetRule
 */
export default function TargetRule({ targetRule }) {
  const { name, activitiesClusivity, description, maxHours } = targetRule
  const message = activitiesClusivity === 'INCLUSIVE' ? 'Included Activities: ' : 'All Activities Except: '
  const activities = sort(targetRule.activities).asc('name')

  return (
    <div className={ss('container')}>
      <div className={ss('header')}>
        <p className={s.name}>{name} </p>
        <p className={s.description}>{description}</p>
      </div>
      <p className={s.maxHours}><label>Max Hours:</label> {maxHours === 0 ? 'Unlimited' : maxHours}</p>
      <p className={s.activities}><label>{message}</label> {activities.map((a, index) =>
        <span className={s.activity} key={a._id}>
          {a.name}{index < activities.length - 1 && ', '}
        </span>
      )}</p>
    </div>
  )
}