import RuleSelector from 'modules/admin/components/RuleSelector'
import { Dropdown, Input } from 'modules/common/components'
import React from 'react'

/**
 * @param {object} p 
 * @param {Org} p.org - org 
 * @param {import('modules/common/components/Forms/useFormManagement').useInputFormReturn<UpdateTargetsInput> |
 *         import('modules/common/components/Forms/useFormManagement').useInputFormReturn<CreateTargetForUserGroupsInput> } p.form
 */
export default function TargetFields({ form, org }) {
  return (
    <React.Fragment>
      <div className={'form-group'}>
        <Input
          label='Start Date'
          type='date'
          name='startDate'
          value={form.data.startDate}
          onChange={form.onChange} />

        <Input
          label='End Date'
          type='date'
          name='endDate'
          value={form.data.endDate}
          onChange={form.onChange} />

        
        <Dropdown 
          label='Target Type'
          name='targetTypeId'
          options={org.targetTypes}
          optionId='_id'
          optionName='name'
          emptyOption={true}
          value={form.data.targetTypeId}
          onChange={form.onChange} />

        {/* <Dropdown 
          label='Target Rules'
          name='targetRuleIds'
          options={org.targetRules}
          optionId='_id'
          optionName='name'
          multiple={true}
          emptyOption={true}
          value={form.data.targetRuleIds}
          onChange={form.onChange} /> */}

        {/* <label>Target Rules</label> */}
        {/* <RuleSelector targetRules={form.data?.targetRuleIds}/> */}

        <Input label='Hours'
          type='number'
          name='hours'
          step='0.25'
          autoComplete="off"
          value={form.data.hours}
          onChange={form.onChange} />

        <Input
          label='Description'
          autoComplete="off"
          type='text'
          name='description'
          value={form.data.description}
          onChange={form.onChange} />
      </div>
    </React.Fragment>
  )
}