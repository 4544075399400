import React from 'react'
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome'

import styles from './tagList.module.css'
import styleSynthesizer from 'modules/common/styles/styles'
const s = styleSynthesizer(styles, 'TagList')

/**
 * @param {object} p 
 * @param {string[]} p.tags
 * @param {function(number):void=} p.removeAction
 */
export default function TagList({tags, removeAction}) {

  return ( 
    <div className={s('container')}>
      {tags?.map((tag, indx) =>
        <p key={indx} className={s('tag')}>
          <span className={s('tagName')}>{tag}</span>
          {removeAction && <FA className={__.removeIcon} icon='times' onClick={() => removeAction(indx)}/> }
        </p>
      )}
    </div>
  )
}

