import { Button, Loading } from 'modules/common/components'
import TimeFrameFilters from 'modules/common/components/TimeFrameFilters'
import TargetList from 'modules/user/components/TargetList'
import { useTargets } from 'api'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import styles from './userGroupTargetsPage.module.scss'
import styleSynthesizer from 'modules/common/styles/styles'
import { Helmet } from 'react-helmet'
import TargetsDownload from 'modules/admin/components/TargetsDownload'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons'
const s = styleSynthesizer(styles, 'UserGroupTargets')

/**
 * @param {object} props
 * @param {userGroupId} props.userGroupId - UserGroupId
 */
function UserGroupTargetsPage({ userGroupId }) {
  const [ asOfDate, setAsOfDate ] = useState(dayjs().subtract(6, 'months').format('YYYY-MM-DD'))
  const [ untilDate, setUntilDate ] = useState()
  const { isLoading, targets } = useTargets({ userGroupId, active: false, asOfDate, untilDate })

  return (
    <React.Fragment>
      <Helmet>
        <title>Targets - Service Hours Online</title>
      </Helmet>

      <div className={s('container')}>
        <div className={s('actions')}>
          <TimeFrameFilters onFilterChange={(asOfDate, untilDate) => {
            setAsOfDate(asOfDate)
            setUntilDate(untilDate)
          }} />
          <TargetsDownload targets={targets} >
            <button className={s('exportButton')}><FontAwesomeIcon icon={faFileExcel} /> Export </button>
          </TargetsDownload>
        </div>
        {isLoading ? <Loading /> : <TargetList targets={targets} />}
      </div>
    </React.Fragment>
  )
}

export default UserGroupTargetsPage
