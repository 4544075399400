import { faPeopleCarry } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styleSynthesizer from 'modules/common/styles/styles'
import React from 'react'
import s from './loadingOverlay.module.scss'
const ss = styleSynthesizer(s, 'LoadingOverlay')

export default function LoadingOverlay({message}) {

  return ( 
    <div role='status' aria-label='loading'  className={ss('container')}>
        <FontAwesomeIcon className={s.icon} icon={faPeopleCarry} />
        <p className={s.message}>{message}</p>
    </div>
  );
};