import { faCalendar, faCheck, faPencilAlt, faPlusCircle, faSort, faSortAlphaDown, faSortAlphaDownAlt, faSortAlphaUp, faSortAlphaUpAlt, faToggleOff, faToggleOn, faTools, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import OrgUserGroups from 'modules/admin/components/OrgUserGroups'
import { useAdminContext } from 'modules/admin/context/adminContext'
import { Button, Input, Loading, PopupMenu } from 'modules/common/components'
import UserGroupsTerm from 'modules/common/components/UserGroupsTerm'
import useListSelector, { SelectAllButton } from 'modules/common/hooks/useListSelector'
import useScreenSize from 'modules/common/hooks/useScreenSize'
import styleSynthesizer from 'modules/common/styles/styles'
import { useOrgUserGroups } from 'api'
import React, { useEffect, useState } from 'react'
import AddUserGroupForm from '../AddUserGroupForm'
import UpdateUserGroupForm from '../EditUserGroupForm'
import InactivateUserGroupsConfirmation from '../InactivateConfirmation'
import TargetCreateForm from '../UserGroupsTargetManagement/TargetCreateForm'
import styles from './orgUserGroupsNavigation.module.scss'
import { sort } from 'fast-sort'
const s = styleSynthesizer(styles, 'OrgUserGroupsNavigation')

export default function UserGroupNavigation({ orgId }) {
  const sizes = useScreenSize()
  const [ showList, setShowList ] = useState(sizes.minSize.desktop)
  const { setSelectedUserGroups, selectedUserGroups } = useAdminContext()
  const { isLoading, org } = useOrgUserGroups(orgId)
  const selector = useListSelector(selectedUserGroups)
  const selected = selector.getSelected()
  const [ formToggles, setFormToggles ] = useState(determineFormToggles(''))
  const [ buttonToggles, setButtonToggles ] = useState(determineButtonToggles())

  useEffect(() => { selector.unSelectAll() }, [ orgId ])
  useEffect(() => setSelectedUserGroups(selected), [ selected.length, selected[ 0 ]?._id ])
  useEffect(() => setButtonToggles(determineButtonToggles()), [ selected.length ])

  if (isLoading) return <Loading />
  const userGroups = org.userGroups

  //TODO: move the button and form toggles to a hook, and update the targets form
  function determineButtonToggles() {
    return {
      addUserGroup: (selected.length === 0),
      editUserGroup: (selected.length === 1),
      inactivateUserGroup: (selected.length >= 1),
      addTarget: (selected.length >= 1)
    }
  }

  function handleActionClick(action) {
    setFormToggles(determineFormToggles(action))
  }

  function determineFormToggles(action) {
    return {
      addUserGroup: !!(action === 'ADD-USER-GROUP'),
      editUserGroup: !!(action === 'EDIT-USER-GROUP'),
      inactivateUserGroup: !!(action === 'INACTIVATE-USER-GROUP'),
      addTarget: !!(action === 'ADD-TARGET')
    }
  }


  return (
    <div className={s('container')}>
      <article className={s('userGroupTitle')}>
        <h3 className={s('header')}><UserGroupsTerm count={2} /></h3>
        <PopupMenu icon={faTools} iconLabel='Actions' >
          <li data-type={'title'} ><UserGroupsTerm count={2} /></li>
          <li data-disabled={!buttonToggles.addUserGroup} onClick={() => handleActionClick('ADD-USER-GROUP')}>
            <FontAwesomeIcon icon={faPlusCircle} /><span>Add </span>
          </li>
          <li data-disabled={!buttonToggles.editUserGroup} onClick={() => handleActionClick('EDIT-USER-GROUP')}>
            <FontAwesomeIcon icon={faPencilAlt} /><span>Edit </span>
          </li>
          <li data-disabled={!buttonToggles.inactivateUserGroup} onClick={() => handleActionClick('INACTIVATE-USER-GROUP')}>
            <FontAwesomeIcon icon={faUserSlash} /> <span>Inactivate </span>
          </li>
          <li data-type={'title'} >Target</li>
          <li data-disabled={!buttonToggles.addTarget} onClick={() => handleActionClick('ADD-TARGET')}>
            <FontAwesomeIcon icon={faPlusCircle} /><span>Add </span>
          </li>
        </PopupMenu>
      </article>

      <AddUserGroupForm orgId={orgId}
        show={formToggles.addUserGroup}
        onClose={() => handleActionClick('')}
        onSuccess={(userGroup) => selector.selectItem(userGroup, false) } />

      {formToggles.editUserGroup && <UpdateUserGroupForm
        orgId={orgId}
        userGroup={selected[ 0 ]}/*Button should only be active is there is one selected item*/
        onClose={() => handleActionClick('')}
        onSuccess={() => handleActionClick('')} />}

      {formToggles.addTarget && <TargetCreateForm org={org} userGroupIds={selected.map(ug => ug._id)} onClose={() => handleActionClick('')} />}

      <InactivateUserGroupsConfirmation
        org={org}
        show={formToggles.inactivateUserGroup}
        userGroups={selected}
        onClose={() => handleActionClick('')}
        onSuccess={() => {
          handleActionClick('')
          selector.unSelectAll()
        }} />

      {(sizes.maxSize.desktop && !showList) &&
        <Button className={s('viewSelected')} onClick={() => setShowList(true)}><UserGroupsTerm count={2} /></Button>
      }
      {(showList || sizes.minSize.desktop) && <UserGroups userGroups={userGroups} selector={selector} onHideList={() => setShowList(false)} />}

    </div>
  )
}

function UserGroups({ userGroups, selector, onHideList }) {
  const sizes = useScreenSize()
  const [ showInactive, setShowInactive ] = useState(false)
  const [ filter, setFilter ] = useState('')
  const [ sortBy, setSortBy ] = useState({ asc: ug => ug.name, active: 'NAME_ASC' })



  const filteredUserGroups = sort(userGroups.filter(ug => {
    let keep = true
    if (filter && JSON.stringify(ug).search(new RegExp(filter, 'i')) === -1) keep = false
    if (!showInactive && ug.inactiveTimestamp !== null) keep = false
    return keep
  })).by([ sortBy ])

  return (
    <React.Fragment>
      {(sizes.maxSize.desktop) && <Button className={s('viewSelected')} onClick={onHideList}>View Selected</Button>}

      <Input
        className={s('input')}
        placeholder='Filter'
        type='text'
        name='filter'
        value={filter}
        autoComplete='off'
        onChange={e => setFilter(e.target.value)}
      />

      <div className={s('buttonContainer')}>
        <SelectAllButton selector={selector} className={s('selectAllButton')} items={filteredUserGroups}>
          <span>Select All</span>
        </SelectAllButton>
        <button className={s('inactiveToggle')} onClick={() => setShowInactive(!showInactive)}>
          Show Inactive:
          {showInactive ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
        </button>
        <div className={s('sortContainer')}>
          <PopupMenu icon={faSort} iconLabel='Sort' >
            <li className={s('sortRow')} onClick={() => setSortBy({ asc: ng => ng.name, active: 'NAME_ASC' })}>
              <span><UserGroupsTerm /> </span>
              <FontAwesomeIcon icon={faSortAlphaDown} />
            </li>
            <li className={s('sortRow')} onClick={() => setSortBy({ desc: ng => ng.name, active: 'NAME_DESC' })}>
              <span><UserGroupsTerm /> </span>
              <FontAwesomeIcon icon={faSortAlphaDownAlt} />
            </li>
            <li className={s('sortRow')} onClick={() => setSortBy({ asc: ng => ng.createTimestamp, active: 'CREATE_ASC' })}>
              <span>Date </span>
              <FontAwesomeIcon icon={faSortAlphaDown} />
            </li>
            <li className={s('sortRow')} onClick={() => setSortBy({ desc: ng => ng.createTimestamp, active: 'CREATE_DESC' })}>
              <span>Date </span>
              <FontAwesomeIcon icon={faSortAlphaDownAlt} />
            </li>
          </PopupMenu>
        </div>
      </div>



      <div className={s('selectedCount')}>
        <span>
          {selector.count} of {userGroups.length}
        </span>
      </div>

      <div className={s('userGroupList')}>
        <OrgUserGroups userGroups={filteredUserGroups} selector={selector} />
      </div>
    </React.Fragment>
  )
}