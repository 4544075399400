import { queries, shoFetch } from 'api'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'

/** @returns {import("react-query").UseMutationResult<UpsertActivityResponse, queryError, UpsertActivityInput, any>} */
export function useUpsertActivity() {
  const queryClient = useQueryClient()

  return useMutation(
    async (activity) => {
      return shoFetch(queries.upsertActivity(activity))
    }, {

    mutationKey: 'updateActivity', 

    onSuccess: async ({response: {activity}}, {orgId, name, _id}) => {
      toast.success(`${name} ${_id ? 'updated': 'created'}`)
      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org._id === orgId && old.org.activities && activity) {
          const index = old.org.activities.findIndex(s => s._id === activity._id)
          index === -1 ? old.org.activities.push(activity) : old.org.activities[index] = activity
        }
        return old
      })
    }
  })
}

/** @returns {import("react-query").UseMutationResult<RemoveActivityResponse, queryError, RemoveActivityInput, any>} */
export function useRemoveActivity() {
  const queryClient = useQueryClient()

  return useMutation(
    async (activity) => {
      return shoFetch(queries.removeActivity(activity))
    }, {

    mutationKey: 'removeActivity', 

    onSuccess: async ({response: {orgId, activityId}} ) => {
      toast.success(`Activity removed`)
      queryClient.setQueriesData(['org'], ( old ) => {
        if(old.org?._id === orgId && old.org?.activities) {
          old.org.activities = old.org.activities.filter(t => t._id !== activityId)
        }
        return old
      })
    }
  })
}