import { queries } from "api"
import { shoFetch } from "api/fetch"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { toast } from 'react-toastify'

/**
 * @param {object} o
 * @param {userGroupId} o.userGroupId - return org information by user group id
 */
export function useOrg({ userGroupId }) {
  const query = queries.getOrgByUserGroupId(userGroupId)
  const response = useQuery(query.key, async () => {
    const data = await shoFetch(query)
    return data.userGroup
  })
  return { org: /** @type{Org} */(response.data?.org), ...response }
}

/**
 * @param {orgId} orgId - orgId for desired org
 */
export function useOrgInfo(orgId) {
  const query = queries.getOrgByOrgId(orgId)
  const response = useQuery(query.key, () => shoFetch(query))
  return { org: /** @type{Org} */(response.data?.org), ...response }
}

/**   
 * @param {orgId} orgId - orgId for desired org
 */
export function useOrgUserGroups(orgId) {
  const query = queries.getOrgWithUserGroups(orgId)
  const response = useQuery(query.key, () => shoFetch(query))
  return { org: /** @type{Org} */(response.data?.org), ...response }
}

/**
 * @param {ServiceHoursMetaDataInput} input - org name with userGroup summary
 */
export function userServiceHoursMetaData(input) {
  const query = queries.getServiceHoursMetaData(input)
  const response = useQuery(query.key, () => shoFetch(query))
  return { meta: /** @type{ServiceHoursMetaData} */(response.data?.meta), ...response }
}



/** @returns {import("react-query").UseMutationResult<Org, queryError, UpdateOrgInfoInput, any>} */
export function useUpdateOrgInfo() {
  const queryClient = useQueryClient()

  return useMutation(
    async (orgToEdit) => {
      return shoFetch(queries.updateOrgInfo(orgToEdit))
    }, {

    mutationKey: 'updateOrgInfo',

    onSuccess: async ({ response: { org } }, orgToEdit) => {
      toast.success(`${orgToEdit.name} updated`)
      queryClient.setQueriesData([ 'org' ], (old) => {
        if (old?.org?._id === org?._id) return { org: { ...old.org, ...orgToEdit } }
        return old
      })

      queryClient.setQueryData(queries.getLoggedInUser().key, (old) => {
        old.user?.managingOrgs?.forEach(mo => {
          if (mo.org._id === org._id) mo.org.name = orgToEdit.name
        })
        return old
      })
    }
  })
}
