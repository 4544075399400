import lg from 'api/logger'
import { navigateToUnauthenticated, navigateToErrorPage} from 'navigation'
import { extend } from 'dayjs'

// eslint-disable-next-line no-undef
const baseURL = process.env.REACT_APP_API_BASE_URL || ''

/**
 * @param {shoQuery} shoQuery 
 */
export async function shoFetch (shoQuery) {
  lg.debug('fetch/shoFetch', shoQuery )
  const {query, variables} = shoQuery
  
  const response = await fetch(baseURL + '/graphql', {
    method: 'POST',
    mode: 'cors', 
    credentials: 'same-origin',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({query: query.trim(), variables})
  })

  lg.debug('fetch/shoFetch:return', response )

  if(response.ok) return processOkStatus(response)
  return processBadStatus(response)
}

async function processOkStatus(response) {
  const { data, errors } = await response.json()
  lg.debug('fetch/shoFetch:processOkStatus', { data, errors } )

  const error = errors ? errors[0] : null

  if(error) {
    if(error.errorType === 'AUTHORIZATION') navigateToUnauthenticated()
    throw new ShoFetchError({message: error.message, errorType: error.errorType})
  }

  return data
}

async function processBadStatus(response) {
  const { status, statusText } = response
  const body = await response.json()
  lg.info('fetch/shoFetch:processBadStatus', {status, statusText, body})

  if(status === 403) {
    lg.info('api/index.interceptors:error', body, 'Navigation to Unauthorized')
    navigateToUnauthenticated()
    throw new Error(`${status} - ${statusText} - from fetch call`)
  } 

  navigateToErrorPage()
  lg.error('api/index.query', body, `${status} - ${statusText} - from fetch call`)
  throw new Error(`${status} - ${statusText} - from fetch call`)
}

class ShoFetchError extends Error {
  constructor({message, errorType}) {
    super(message);
    this.errorType = errorType
  }
}
