/**
 * @param {userGroupId} userGroupId
 * @returns {shoQuery}
 */
export const getUserGroup = (userGroupId) => ({
  key: ['userGroup', userGroupId],
  variables: {userGroupId},
  query: ` 
  query UserGroup($userGroupId: ID) {
    userGroup: userGroup(userGroupId: $userGroupId) {
      _id
      name
      tags
      createTimestamp
      inactiveTimestamp
      meta {
        lifeTimeHours
      }
      memberRoles {
        userGroupId
        memberRole
        user {
          _id
          firstName
          lastName
          userName
          email
        }
      }
    }
  }
  `
})

/**
 * @param {userGroupId []}  userGroupIds
 * @returns {shoQuery}
 */
export const getUserGroups = (userGroupIds) => ({
  key: ['userGroups', 'memberRoles', userGroupIds],
  variables: {userGroupIds},
  query: ` 
  query UserGroups($userGroupIds: [ ID ]) {
    userGroups: userGroups(userGroupIds: $userGroupIds) {
      _id
      name
      tags
      createTimestamp
      inactiveTimestamp
      memberRoles {
        userGroupId
        memberRole
        user {
          _id
          firstName
          lastName
          userName
          email
          registeredDate
          lastLoggedInTimestamp
        }
      }
    }
  }
  `
})



/**
 * @param {AddUserToUserGroupInput} input
 * @returns {shoQuery}
 */
export const addUserToUserGroup = (input) => ({
  key: ['add-user-to-user-group', {userGroupId: input.userGroupId}],
  variables: {input},
  query: ` 
      mutation addUserToUserGroup($input: AddUserToUserGroupInput!) {
        response: addUserToUserGroup(input: $input) {
          requestId
      }
    }
  `
})

/**
 * @param {RemoveUserFromUserGroupInput} input
 * @returns {shoQuery}
 */
export const removeUserFromUserGroup = (input) => ({
  key: ['remove-user-from-user-group', {userGroupId: input.userGroupId}],
  variables: {input},
  query: ` 
      mutation removeUserFromUserGroup($input: RemoveUserFromUserGroupInput!) {
        response: removeUserFromUserGroup(input: $input) {
          requestId
      }
    }
  `
})

/**
 * @param {CreateUserGroupInput} input
 * @returns {shoQuery}
 */
export const createUserGroup = (input) => ({
  key: ['create-user-group', {input}],
  variables: {input},
  query: ` 
      mutation createUserGroup($input: CreateUserGroupInput!) {
        response: createUserGroup(input: $input) {
          userGroup {
           _id
           name
           tags
           inactiveTimestamp
         }
       }
     }
  `
})

/**
 * @param {InactivateUserGroupInput} input
 * @returns {shoQuery}
 */
export const inactivateUserGroups = (input) => ({
  key: ['inactivate-user-groups', {input}],
  variables: {input},
  query: ` 
      mutation inactivateUserGroups($input: InactivateUserGroupsInput!) {
        response: inactivateUserGroups(input: $input) {
          userGroups {
           _id
           name
           tags
           inactiveTimestamp
         }
       }
     }
  `
})

/**
 * @param {UpdateUserGroupInput} input
 * @returns {shoQuery}
 */
export const updateUserGroup = (input) => ({
  key: ['update-user-group', {input}],
  variables: {input},
  query: ` 
      mutation updateUserGroup($input: UpdateUserGroupInput!) {
        response: updateUserGroup(input: $input) {
          userGroup {
           _id
           name
           tags
           inactiveTimestamp
         }
       }
     }
  `
})